import { Inject, Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';

@Injectable()
export class MyDateAdapter extends MomentDateAdapter {

    constructor(@Inject(MAT_DATE_LOCALE) dateLocale: string) {
        super(dateLocale);
        console.log('MyDateAdapter is being used');
    }

    override getFirstDayOfWeek(): number {
        return 1; // Set first day MONDAY
    }

    override format(date: moment.Moment, displayFormat: string | Intl.DateTimeFormatOptions): string {
        console.log('displayFormat:', displayFormat);

        if (!date.isValid()) {
            return '';
        }

        // Caso cuando el displayFormat es un objeto (Intl.DateTimeFormatOptions)
        if (typeof displayFormat === 'object') {
            console.warn('displayFormat is an Intl.DateTimeFormatOptions object:', displayFormat);
            try {
                // Usa Intl.DateTimeFormat para formatear la fecha
                const locale = this.locale || 'en-US'; // Usa el locale configurado o un valor por defecto
                const intlFormatter = new Intl.DateTimeFormat(locale, displayFormat);
                return intlFormatter.format(date.toDate()); // Convierte moment a Date para Intl
            } catch (error) {
                console.error('Error al formatear con Intl.DateTimeFormat:', error);
                return date.format('DD/MM/YYYY'); // Fallback a un formato por defecto
            }
        }

        // Caso cuando el displayFormat es un string
        if (typeof displayFormat === 'string') {
            return date.format(displayFormat || 'DD/MM/YYYY');
        }

        // Fallback para formatos desconocidos
        console.error('Unsupported displayFormat type:', typeof displayFormat, displayFormat);
        return date.format('DD/MM/YYYY'); // Formato por defecto como fallback
    }

    override parse(value: any, parseFormat: any): moment.Moment | null {
        const currentYear = moment().year();
        const currentMonth = (moment().month() + 1).toString().padStart(2, '0'); // month() devuelve 0-11

        if (typeof value === 'string') {
            let day, month, formattedValue;

            // Caso de entrada de solo día (1 o 2 dígitos)
            if (value.length === 1 || value.length === 2) {
                day = value.padStart(2, '0'); // Asegura que el día tenga dos dígitos
                formattedValue = `${day}/${currentMonth}/${currentYear}`;
                return moment(formattedValue, 'DD/MM/YYYY', true);
            }

            // Caso de entrada de día y mes (4 dígitos)
            if (value.length === 4) {
                day = value.slice(0, 2);
                month = value.slice(2, 4);
                formattedValue = `${day}/${month}/${currentYear}`;
                return moment(formattedValue, 'DD/MM/YYYY', true);
            }
        }

        return super.parse(value, parseFormat || 'DD/MM/YYYY');
    }
}
