import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { CustomValidators } from '../../../../core/validators/custom-validators';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Financier } from '../../../../shared/models/financier';
import { EntitiesFacade } from '../../../../abstraction/entities.facade';
import { VatCategory } from '../../../../shared/models/vatCategory';
import { CasesFacade } from '../../../../abstraction/cases.facade';
import { Subscription } from 'rxjs';
import { TagsFacade } from 'src/app/abstraction/tags.facade';
import { Tag } from 'src/app/shared/models/tags';
import { CasesService, PostCaseBody, TaxZone } from '../../../../core/services/cases.service';
import { Complexity } from '../../../../shared/models/complexity';
import { NomenclatorFacade } from '../../../../abstraction/nomenclator.facade';
import { CompanyType } from 'src/app/shared/models/companyType';
import { ArrangementsFacade } from 'src/app/abstraction/arrangements.facade';
import { Case } from 'src/app/shared/models/case';
import { UpdatingCase } from 'src/app/shared/models/submodels/updatingCase';
import { Diagnosis } from '../../../../shared/models/diagnosis';

@Component({
    selector: 'app-case-data',
    templateUrl: './case-data.component.html',
    styleUrls: ['./case-data.component.scss']
})
export class CaseDataComponent implements OnInit {

    // Only Popup mode
    @Input() caseIN: Case | UpdatingCase;
    @Input() isloadingUpdate: boolean = false;

    @Output() onClickCancelEditCase = new EventEmitter();
    @Output() onClickConfirmEditCase = new EventEmitter();

    loadingCase: boolean = false;
    isCaseInputParam: boolean = false;
    financierIdToEdit: number;
    taxZoneDefault: number;
    //

    isOpen = false;

    @Output() caseData = new EventEmitter<PostCaseBody>();
    @Output() formCaseDataValid = new EventEmitter<boolean>();
    caseDataAdmission: PostCaseBody;
    // btn_addObservation_disabled: boolean = false;

    //  Name constants
    public readonly FINANCIER = 'financierId';
    public readonly FINANCIER_SEARCHING = 'financierSearching';
    public readonly FINANCIER_OPERATOR = 'financierOperator';
    public readonly FINANCIER_INTERNAL_CODE = 'financierInternalCode';
    public readonly EMAIL_DATE = 'emailDate';
    public readonly START_DATE = 'startDate';
    public readonly EMAIL_FINANCIER = 'financierEmail';
    public readonly PATIENT_AFFILIATIONNUMBER = 'affiliationNumber';
    // public readonly SINISTER_NUMBER = 'sinisterNumber';
    public readonly VAT_CATEGORY = 'vatCategoryId';
    // public readonly VAT_CATEGORY_SEARCHING = 'vatCategorySearching';
    public readonly TAX_ZONE = 'taxZoneId';
    public readonly TAX_ZONE_SEARCHING = 'taxZoneSearching';
    public readonly COMPANY_TYPE = 'companyTypeId';
    public readonly COMPANY_TYPE_SEARCHING = 'companyTypeSearching';
    public readonly TYPE_CASE = 'tags';
    public readonly COMPLEXITY_CASE = 'complexityId';
    public readonly ENABLED_FINANCIER = 'enabledFinancier';
    public readonly ENABLED_PROVIDER = 'enabledProvider';

    // Diagnos
    public readonly DIAGNOSIS = 'id';
    public readonly DIAGNOSIS_SEARCHING = 'diagnosisSearching';
    public readonly OBSERVATION = 'observation';
    // public readonly DIAGNOS_NUMBER = 'diagnosNumber';
    public readonly OBSERVATIONS = 'observations';

    formObservations: FormGroup;

    // Financier
    isLoadingGettingFinanciers$: Observable<boolean>;
    _financiers: Subscription;
    financiers: Financier[];
    financierSelected: Financier;

    // IVA
    isLoadingGettingVatCategories$: Observable<boolean>;
    _vatCategories: Subscription;
    vatCategories: VatCategory[];
    isDisabledVatCategory: boolean = false;
    vatCatId: String;

    // TAX ZONES
    _taxZones: Subscription;
    taxZones$: Observable<TaxZone[]>;
    taxZones: TaxZone[];
    tagsDefault: Tag[];

    // COMPANY TYPE
    isDisabledCompanyType: boolean = false;
    companyTypeSelected: CompanyType;
    complexitySelected: Complexity = {};

    get financier() {
        return this.formCase.get(this.FINANCIER).value;
    }

    get tags() {
        return this.formCase.get(this.TYPE_CASE).value;
    }

    get taxZone() {
        return this.formCase.get(this.TAX_ZONE).value;
    }

    get companyType() {
        return this.formCase.get(this.COMPANY_TYPE).value;
    }

    get observations() {
        return this.formObservations.get('observations') as FormArray;
    }

    get vatCategoryId() {
        return this.formCase.get(this.VAT_CATEGORY).value;
    }

    formCase: FormGroup;
    observationsForm: FormGroup;
    tagList: Tag[]; _loadingTags: Subscription;
    complexityList: Complexity[]; _loadingComplexities: Subscription;
    companyTypesList: CompanyType[];

    // Valid forms
    isValidFormCase:boolean=false;
    isValidFormObservations:boolean=false;

    diagnosesOnSelect: [Diagnosis[]];
    actualDiagnoses: string[];
    index: number = 0;

    constructor(
        private formBuilder: FormBuilder,
        public router: Router,
        private entitiesFacade: EntitiesFacade,
        private casesFacade: CasesFacade,
        public tagsFacade: TagsFacade,
        private nomenclatorFacade: NomenclatorFacade,
        private arrangementsFacade: ArrangementsFacade,
        ) {
            this.taxZones$ = this.casesFacade.getTaxZones$(); // Loaded on admission-creation.component
    }

    getDiagnosesByName = (value: string) => this.casesFacade.getDiagnosesFiltered$(value);

    ngOnInit(): void {

        if ( !!this.caseIN ) {
            this.loadingCase = true
            this.isCaseInputParam = true;
        }

        this.formCase = this.createForm( !!this.caseIN ? this.caseIN : null );

        // Emit changes formCase
        this.formCase.valueChanges.subscribe(formCase => {

            if (this.formCase.status === 'VALID') {

                // Tags to Object
                let newTags: Tag[]=[];
                if (!!this.tags)
                    this.tags.map( ( t ) => newTags.push( {id:t} ) ); // spread

                const { financierSearching, vatCategorySearching, taxZoneSearching, companyTypeSearching, complexityId, ...newCaseDataAdmission } = formCase
                this.caseDataAdmission = { ...this.caseDataAdmission, ...newCaseDataAdmission, complexities:this.complexitySelected, tags:newTags };
                this.caseData.emit(this.caseDataAdmission);
                this.isValidFormCase = true;
            } else {
                this.isValidFormCase = false;
            }
            this.emitValidsForms()
        })

        // Emit changes formObservations
        this.formObservations.valueChanges.subscribe(formObs => {

            if (this.formObservations.status === 'VALID' && !!this.caseDataAdmission) {

                if(this.formObservations.controls['observations']['controls'][0].value.id != null && this.formObservations.controls['observations']['controls'].length != 0){

                    this.caseDataAdmission.diagnoses = this.formObservations.controls['observations']['controls'].map( subForm => ({ id: parseInt(subForm.controls.id.value), observation: subForm.controls.observation.value }));

                } else {

                    this.caseDataAdmission.diagnoses = [];
                }

                this.caseData.emit(this.caseDataAdmission);
                this.isValidFormObservations = true;
            } else {
                this.isValidFormObservations = false;
            }
            this.emitValidsForms()
        })
        this.suscribeToData();
    }

    calculateNameFinancier( element: Financier ) {
		return element ? element.name : null;
	}

	calculateIdFinancier( element: Financier ) {
		return element ? element.id : null;
	}

    emitValidsForms() {
        if ( this.isValidFormCase && this.isValidFormObservations )
            this.formCaseDataValid.emit(true);
        else
            this.formCaseDataValid.emit(false);
    }

    suscribeToData() {

        // Financiers
        this._financiers = this.entitiesFacade.getFinanciers$().subscribe(financiers => {
            if (!!financiers) {
                this.financiers = financiers;
                // const auxIdFinancier: number = this.casesService.isCase(this.caseIN) ? this.caseIN.financier.id : this.caseIN.financierId;
                this.financierIdToEdit = !!this.caseIN?.financier ? this.caseIN.financier.id : null;
                // this.financierIdToEdit = auxIdFinancier;

                this.onSelectFinancier( this.financierIdToEdit );

                if (!!this.caseIN?.companyType)
                    this.onSelectCompanyType( this.caseIN.companyType.id );

                // const auxIdVatCategory: string = this.casesService.isCase(this.caseIN) ? this.caseIN.vatCategory.id.toString() : this.caseIN.vatCategoryId.toString();
                if (!!this.caseIN?.vatCategory) {
                    this.vatCatId = this.caseIN.vatCategory.id.toString();
                }
                // this.vatCatId = auxIdVatCategory;

                this.loadingCase = false;
            }
            else {
                this.entitiesFacade.loadFinanciers();
            }
        });

        // TAX ZONES
        this._taxZones = this.casesFacade.getTaxZones$().subscribe( taxZones => {

            if (!!taxZones) {
                this.taxZones = taxZones
                this.taxZoneDefault = !!this.caseIN?.taxZone ? this.caseIN.taxZone.id : null
            } else {
                this.casesFacade.loadTaxZones( {all: true} );
            }
        })

        this._loadingTags = this.tagsFacade.loadTags({ active: true }).subscribe((tagsReturned: Tag[]) => {
            this.tagList = tagsReturned;
            this.tagsDefault = this.caseIN.caseTags ? this.tagList.filter( elem => this.caseIN.caseTags.find( caseTag => elem.id == caseTag.tagId )) : [];
        });

        this._loadingComplexities = this.nomenclatorFacade.loadComplexities().subscribe((complexitiesReturned: Complexity[]) => {
            this.complexityList = complexitiesReturned;

            if (!!this.caseIN?.complexityId) {
                this.onSelectComplexity( this.caseIN.complexityId )
            }
        });
    }

    keyPressNumbers(event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        // Only Numbers 0-9
        if (charCode < 48 || charCode > 57) {
            // It's allowed "-" and "/" too
            if ( charCode != 45 && charCode != 47) {
                event.preventDefault();
                return false;
            }
            return true;
        } else {
            return true;
        }
    }

    createForm( acase?: Case | UpdatingCase ): FormGroup {

        this.formObservations = this.formBuilder.group({
            [this.OBSERVATIONS]: this.formBuilder.array( this.createObservationsFormArray( acase ) )
        })

        const form = this.formBuilder.group({
            [this.FINANCIER]: [{ value:!!acase ? acase?.financier.id : '',disabled:!!acase}, [CustomValidators.required('Financiador requerido')]],
            [this.FINANCIER_SEARCHING]: [''],
            [this.FINANCIER_OPERATOR]: [!!acase?.financierOperator ? acase.financierOperator : ''],
            [this.EMAIL_DATE]: [!!acase?.emailDate ? acase.emailDate : ''], // [ CustomValidators.required('Fecha de email requerida') ]
            [this.START_DATE]: [!!acase?.startDate ? acase.startDate : ''],
            [this.EMAIL_FINANCIER]: [!!acase?.financierEmail ? acase.financierEmail : ''], // [CustomValidators.email('Email inválido'), CustomValidators.maxLength(255, 'El máximo de carácteres son 255')]

            [this.PATIENT_AFFILIATIONNUMBER]: [!!acase ? acase.patient.affiliationNumber : ''], // [ CustomValidators.required('Nº de afiliado requerido') ]
            [this.FINANCIER_INTERNAL_CODE]: [!!acase ? acase.financierInternalCode : ''], // [ CustomValidators.required('CIF requerido') ]

            [this.COMPANY_TYPE]: [''],
            [this.COMPANY_TYPE_SEARCHING]: [''],
            [this.VAT_CATEGORY]: [''],
            [this.TAX_ZONE]: [''],
            [this.TAX_ZONE_SEARCHING]: [''],

            [this.TYPE_CASE]: [''],
            [this.COMPLEXITY_CASE]: [''],
            [this.ENABLED_FINANCIER]: [ !!acase?.caseParametrization ? acase.caseParametrization.enabledFinancier : true ],
            [this.ENABLED_PROVIDER]: [ !!acase?.caseParametrization ? acase.caseParametrization.enabledProvider : true ],
        });

        if(!this.caseIN) {
            this.formObservations.disable(); // Enable when financier is selected coming from Case (NO requisitions)
        } else {
            // this.isValidFormObservations = true // Observations previous was entered
        }

        console.log(this.observations)
        return form;
    }

    createObservationsFormArray( acase?: Case | UpdatingCase ) {

        this.index = 0;
        let diagnosesFormArray;
        this.diagnosesOnSelect = [[]];
        this.actualDiagnoses = [null];

        if (!!acase?.diagnoses && acase.diagnoses.length != 0) {

            diagnosesFormArray = acase.diagnoses.map(( diagnosis ) => {
                let diagnosesForm:FormGroup;
                this.diagnosesOnSelect[this.index] = [diagnosis];
                this.actualDiagnoses[this.index] = diagnosis.id.toString();
                this.index++;

                if (!!diagnosis) {
                    diagnosesForm = this.newObservation( diagnosis )
                } else {
                    diagnosesForm = this.newObservation()
                }
                return diagnosesForm;
          })
        } else {
          diagnosesFormArray = [this.newObservation()];
        }
        return diagnosesFormArray;
    }

    newObservation( diagnosis?: Diagnosis ): FormGroup {
        return this.formBuilder.group({
            [this.DIAGNOSIS]: [{ value: !!diagnosis ? diagnosis.id : '', disabled: !!diagnosis }], //[CustomValidators.required('Diagnóstico requerido')]],
            [this.DIAGNOSIS_SEARCHING]: [''],
            [this.OBSERVATION]: [{ value: !!diagnosis ? diagnosis.observation : '', disabled: !!diagnosis }],
        })
    }

    addObservation() {
        this.observations.push(this.newObservation());
    }

    removeObservation(i: number) {
        if (this.observations['controls'].length == 1) {
            this.observations['controls'][i].reset();
            this.observations['controls'][i].enable();
            this.isValidFormObservations = false;
            // this.formObservations.enable();
        } else {
            this.observations.removeAt(i);
        }
        this.actualDiagnoses.splice(i, 1);
        this.diagnosesOnSelect.splice(i, 1);
    }

    calculateNameVatCategory(element: VatCategory) {
        return element ? element.description : null;
    }

    calculateIdVatCategory(element: VatCategory) {
        return element ? element.id : null;
    }

    calculateNameTaxZone(element: TaxZone) {
        return element ? element.description : null;
    }

    calculateIdTaxZone(element: TaxZone) {
        return element ? element.id : null;
    }

    calculateNameCompanyType(element: CompanyType) {
        return element ? element.description : null;
    }

    calculateIdCompanyType(element: CompanyType) {
        return element ? element.id : null;
    }

    complexitiesHelp() {
        this.isOpen = this.isOpen ? false : true;
    }

    onSelectComplexity( id: number ) {
        this.complexitySelected = this.complexityList.find(complexity => complexity.id == id);
        this.formCase.updateValueAndValidity({emitEvent:true});
    }

    resetFinancierSelection() {
        this.formCase.get(this.FINANCIER_SEARCHING).reset();
        this.formCase.get(this.COMPANY_TYPE).reset();
        this.formCase.get(this.VAT_CATEGORY).reset();
    }

    onSelectFinancier(id: number) {
        this.resetFinancierSelection();
        if (!!id) this.arrangementsFacade.loadArrangements({financierId: id});
        this.companyTypesList = [];
        this.financierSelected = this.financiers.find( f => f.id == id );
        this.companyTypesList = this.financierSelected?.companyTypes ? this.financierSelected.companyTypes : null;
        this.isDisabledCompanyType = this.companyTypesList ? false : true;

        if (!this.companyTypesList) {
            this.isDisabledVatCategory = false;
            this.vatCategories = this.financierSelected?.vatCategories;
            this.formCase.get(this.VAT_CATEGORY).enable();
        } else {
            this.vatCategories = [];
        }

        // formObservations enabled from Dashboard (requistions) else the control enable/disable is other
        if (!!id && !this.caseIN)
            this.formObservations.enable();
    }

    onSelectCompanyType(id: number) {

        this.companyTypeSelected = this.companyTypesList?.find( c => c.id == id );

        if (!!this.companyTypeSelected) {
            this.vatCategories[0] = this.companyTypeSelected.vatCategory;
            this.vatCatId = this.vatCategories[0].id.toString();

            this.formCase.get(this.VAT_CATEGORY).disable();
        } else {
            this.vatCategories = [];
        }
        this.isDisabledVatCategory = true;
    }

    clickCancelEditCase() {
        this.onClickCancelEditCase.emit();
    }

    clickConfirmEditCase() {
        this.onClickConfirmEditCase.emit();
    }

    ngOnDestroy(): void {
        this._financiers.unsubscribe();

        if (!!this._loadingTags) {
            this._loadingTags.unsubscribe();
        }
        if (!!this._loadingComplexities) {
            this._loadingComplexities.unsubscribe();
        }
        if (!!this._taxZones) {
            this._taxZones.unsubscribe();
        }
    }

    calculateName(diagnose: Diagnosis) {
        return !!diagnose ? diagnose.name : '';
      }

    getObservationsFormGroup(index: number): FormGroup {
        return this.observations.at(index) as FormGroup;
    }
}
