<form class="flex-100" [formGroup]="filtersForm" style="width:350px;">
    <div class="flex-row  my-1" style="border-bottom:1px solid lightgrey;">
        <span class="flex-80 subtitle-filters" style="line-height: inherit; ">Filtros Prestadores
        </span>
        <mat-icon class="flex-1-1-auto" [color]="'primary'" style="line-height: 0.8" (click)="setProvidersFiltersVisible()">
            {{ providersFiltersVisible ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
        </mat-icon>
    </div>
    @if(this.loadingProvidersFilters){
    <mat-progress-bar [mode]="'indeterminate'" [value]="100"></mat-progress-bar>
    }
    <div class="flex-column" [@detailExpand]="providersFiltersVisible ? 'expanded' : 'collapsed'">
        <div class="flex-column" style="border-bottom:1px solid lightgrey;">

            <app-checkbox-input [form]="filtersForm" [label]="'Incluir domicilio de prestadores'"
                [name]="this.INCLUDE_PROVIDERS" [disabled]="!this.enableFilters">
            </app-checkbox-input>

            <!-- <app-checkbox-input [form]="filtersForm" [label]="'Incluir zonas de trabajo de prestadores'"
                [name]="this.INCLUDE_PROVIDERS" [disabled]="!this.enableFilters">
            </app-checkbox-input> -->

            <!-- <app-checkbox-input [form]="filtersForm" [label]="'Incluir prestadores habilitados'"
                [name]="this.INCLUDE_PROVIDERS" [disabled]="!this.enableFilters">
            </app-checkbox-input>

            <app-checkbox-input [form]="filtersForm" [label]="'Incluir potenciales prestadores'"
                [name]="this.INCLUDE_PROVIDERS" [disabled]="!this.enableFilters">
            </app-checkbox-input> -->
        </div>
    </div>

    <div class="flex-row  my-3" style="border-bottom:1px solid lightgrey;">
        <span class="flex-80 subtitle-filters" style="line-height: inherit; ">Filtros Pacientes
        </span>
        <mat-icon class="flex-1-1-auto" [color]="'primary'" style="line-height: 0.8" (click)="setPatientsFiltersVisible()">
            {{ patientsFiltersVisible ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
        </mat-icon>
    </div>
    @if(this.loadingPatientsFilters){
    <mat-progress-bar [mode]="'indeterminate'" [value]="100"></mat-progress-bar>
    }
    <div class="flex-column" [@detailExpand]="patientsFiltersVisible ? 'expanded' : 'collapsed'">
        <div class="flex-column" style="border-bottom:1px solid lightgrey;">

            <!-- <app-checkbox-input [form]="filtersForm" [label]="'Incluir pacientes con la misma especialidad'"
                [name]="this.INCLUDE_INACTIVE_PATIENTS" [disabled]="!this.enableFilters">
            </app-checkbox-input>
            <div>
                <div class="pls-9">
                    <span class="flex mbs-1 subtitle-filters">Con agenda:</span>
                    <app-select-input [elements]="fromDateList" [name]="this.FROMDATE" [form]="this.filtersForm"
                        [placeholder]="this.fromDateList[2].description" [calculateId]="calculateIdFromDate"
                        [calculateName]="calculateNameFromDate" [defaultValue]="this.fromDateList[2].value"
                        (selected)="onFilterFromDate($event)" [disabled]="!enableFilters">
                    </app-select-input>
                </div> -->
                <app-checkbox-input [form]="filtersForm" [label]="'Incluir pacientes activos'"
                    [name]="this.INCLUDE_ACTIVE_PATIENTS" [disabled]="!this.enableFilters">
                </app-checkbox-input>
                <app-checkbox-input [form]="filtersForm" [label]="'Incluir pacientes inactivos'"
                    [name]="this.INCLUDE_INACTIVE_PATIENTS" [disabled]="!this.enableFilters">
                </app-checkbox-input>
            </div>
        </div>
    <!-- </div> -->
</form>
