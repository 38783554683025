@if (!!width() && height() ){
    <google-map [height]="'100vh'" [width]='width()' #map [options]="options">
        @for (marker of localMarkers; track marker) {
            @if(marker.visible){
        <!-- With svgIcon -->
        @if(!!marker?.icon) {
        <map-advanced-marker #markerElem="mapAdvancedMarker" [title]="!!marker?.title ? marker.title : null "
            [position]="!!marker.lat && marker.visible ? { lat: marker?.lat, lng: marker?.lng } : null" [content]="marker.svgIcon"
            (mapClick)="simulateClick(marker.id)">
        </map-advanced-marker>
        } @else {
        <!-- Without svgIcon, icon default -->
        <map-advanced-marker #markerElem="mapAdvancedMarker" [title]="!!marker?.title ? marker.title : null "
            [position]="!!marker.lat ? { lat: marker?.lat, lng: marker?.lng } : null"
            (mapClick)="simulateClick(marker.id)">
        </map-advanced-marker>
        }
    }
        }
        <!-- En el componente del mapa -->
        <map-info-window>
            <ng-container *ngIf="selectedMarker">
                <ng-container
                    *ngTemplateOutlet="getInfoWindowTemplate(selectedMarker); context: { $implicit: selectedMarker }"></ng-container>
            </ng-container>
        </map-info-window>
    </google-map>
}

@if((mapsService.getMarkers$() | async)) {
<!-- This if force automatic refresh -->
}
<!-- Provider -->
<ng-template #templateProvider let-marker>
    <div class="card-marker overflowH" style="padding: 20px !important;">
        <div
            style=" border-bottom:1px solid lightgrey; text-align: center; width: max-content; width: 100% !important; padding: 5px !important;">
            <!-- NAME -->
            <span style="font-weight: 500; color: darkblue; font-size: 16px;">{{!!marker?.name ? marker.name : 'Sin nombre'}}</span>
        </div>
        <div class="flex flex-column align-center-center card-width text-center pts-1 gap-0-7em">
            <!-- DNI -->
            <span style="font-weight: 500; padding-top: 10px !important;">DNI {{marker?.dni}}</span>
            <!-- SPECIALTY -->
            <span class="pts-1" style="color: darkgrey;font-weight: 500;">{{specialtyName()}}</span>
            <!-- MEDICAL LICENSE -->
            <div class="container pts-1" style="margin-bottom: -10px;">
                <ng-container *ngFor="let specialty of marker?.specialties">
                    @if(specialty.id == specialtyId()) {
                    <div class="flex-row" *ngFor="let medicalLicense of specialty.medicalLicenses">
                        <span style="color: darkgrey;font-weight: 500;">{{ ('• ' + (medicalLicense?.type?.name || '') + ' Nº ' + (medicalLicense?.value || '')) | titlecase }}</span>
                        <button (click)="copyToClipboard(medicalLicense?.value);$event.stopPropagation()"
                            style="background-color: transparent; border: none; cursor: pointer;">
                            <mat-icon class="mat-font-large-large-px lh">content_copy</mat-icon>
                        </button>
                    </div>
                }
                </ng-container>
            </div>
            <!-- VERIFY LINK -->
            <a class="no-style-link" style="color: darkblue; font-size: 12px;" href="https://sisa.msal.gov.ar/sisa/#sisa" target="_blank">
                <u>VERIFICAR MATRÍCULA (SISA)</u>
            </a>
            <!-- ADDRESS -->
            <span class="pts-1" style="font-weight: 500;">
               {{!!marker?.address ? marker.address : 'Sin dirección'}}
            </span>
            <!-- PHONE NUMBER -->
            <div class="flex-row align-center-center gap-0-5em  pts-1">
                <mat-icon [style.width.px]="16" [style.height.px]="16" svgIcon="whatsappLight"></mat-icon>
                <a class="no-style-link" [href]="'https://api.whatsapp.com/send/?phone=' + marker.phone + '&text&type=phone_number&app_absent=0'" target="_blank">
                      {{!!marker.phone ? marker.phone :'Sin teléfono'}}
                    </a>
            </div>
            <!-- EMAIL -->
            <!-- <div class="mail" > -->
                <div class="flex-row gap-0-5em align-center-center mail-container ">
                    <mat-icon [style.width.px]="18" [style.height.px]="18" svgIcon="mailLight"></mat-icon>
                    <a class="no-style-link text-ellipsis pls-0-7 " href="mailto:{{marker?.email}}" target="_blank">
                        {{!!marker?.email ? marker.email : 'Sin email'}}
                </a>
                </div>
            <!-- </div> -->

        </div>
    </div>
</ng-template>

<!-- Patient -->
<ng-template #templatePatient let-marker>
    <div class="card-marker">
        <div
            style=" border-bottom:1px solid lightgrey; text-align: center; width: max-content; width: 100% !important;">
            <!-- NAME -->
            <span (click)="goToCase(marker?.caseId)" style="font-weight: 500; color: darkblue; ">{{!!marker?.name ? marker.name : 'Sin nombre'}}</span>
        </div>
        <div class="flex flex-column align-center-center card-width text-center pts-1">
         <!-- ADDRESS -->
         <span class="pts-1" style="font-weight: 500;">
            {{!!marker?.address ? marker.address : 'Sin dirección'}}
         </span>
  <!-- PHONE NUMBER -->
  <div class="flex-row align-center-center gap-0-5em  pts-1">
    <mat-icon [style.width.px]="16" [style.height.px]="16" svgIcon="whatsappLight"></mat-icon>
    <a class="no-style-link" [href]="'https://api.whatsapp.com/send/?phone=' + marker.phone + '&text&type=phone_number&app_absent=0'" target="_blank">
          {{!!marker.phone ? marker.phone :'Sin teléfono'}}
        </a>
</div>
<!-- EMAIL -->
<!-- <div class="mail" > -->
    <div class="flex-row gap-0-5em align-center-center mail-container ">
        <mat-icon [style.width.px]="18" [style.height.px]="18" svgIcon="mailLight"></mat-icon>
        <a class="no-style-link text-ellipsis pls-0-7 " href="mailto:{{marker?.email}}" target="_blank">
            {{!!marker?.email ? marker.email : 'Sin email'}}
    </a>
    </div>
<!-- </div> -->
        </div>
    </div>
</ng-template>

<!-- Case -->
<ng-template #templateCase let-marker>
    <div class="card-marker">
        <div
            style=" border-bottom:1px solid lightgrey; text-align: center; width: max-content; width: 100% !important;">
            <!-- NAME -->
            <span style="font-weight: 500; color: darkblue; ">{{!!marker?.name ? marker.name : 'Sin nombre'}}</span>
        </div>
        <div class="flex flex-column align-center-center card-width text-center pts-1">
          <!-- ADDRESS -->
          <span class="pts-1" style="font-weight: 500;">
            {{!!marker?.address ? marker.address : 'Sin dirección'}}
         </span>
           <!-- PHONE NUMBER -->
           <div class="flex-row align-center-center gap-0-5em  pts-1">
            <mat-icon [style.width.px]="16" [style.height.px]="16" svgIcon="whatsappLight"></mat-icon>
            <a class="no-style-link" [href]="'https://api.whatsapp.com/send/?phone=' + marker.phone + '&text&type=phone_number&app_absent=0'" target="_blank">
                  {{!!marker.phone ? marker.phone :'Sin teléfono'}}
                </a>
        </div>
        <!-- EMAIL -->
        <!-- <div class="mail" > -->
            <div class="flex-row gap-0-5em align-center-center mail-container ">
                <mat-icon [style.width.px]="18" [style.height.px]="18" svgIcon="mailLight"></mat-icon>
                <a class="no-style-link text-ellipsis pls-0-7 " href="mailto:{{marker?.email}}" target="_blank">
                    {{!!marker?.email ? marker.email : 'Sin email'}}
            </a>
            </div>
        <!-- </div> -->
        </div>
    </div>
</ng-template>
