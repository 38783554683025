import { Injectable } from '@angular/core';
import { MapsSate } from "../core/states/maps.state";
import { MarkerGoogleMaps } from "../shared/models/maps/markerGoogleMaps";

@Injectable({
	providedIn: 'root',
})
export class MapsFacade {

    constructor(
        private mapsState: MapsSate
    ) { }

    addMarker(marker: MarkerGoogleMaps) {
        this.mapsState.addMarker(marker);
    }

    setMarkers(markers: MarkerGoogleMaps[]) {
        this.mapsState.setMarkers(markers);
    }

    mergeMarkers(markers: MarkerGoogleMaps[]) {
        // Usamos un bucle for en lugar de forEach
        for (let i = markers.length - 1; i >= 0; i--) {
          if (!markers[i]) {
            // Eliminamos el marcador nulo o undefined directamente del arreglo
            markers.splice(i, 1);
          }
        }
        this.mapsState.mergeMarkers(markers);
      }

      setVisibleMarker(markerId:number){
        this.mapsState.setVisibleMarker(markerId)
      }
      setVisibleAllMarkers(){
        this.mapsState.setVisibleAllMarkers()
      }


    resetMarkers(){
        this.mapsState.resetMarkers();
    }
}
