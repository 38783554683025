import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormElementComponent } from '../form-element/form-element.component';

@Component({
    selector: 'app-checkbox-input',
    templateUrl: './checkbox-input.component.html',
    styleUrls: ['./checkbox-input.component.scss'],
})
export class CheckboxInputComponent extends FormElementComponent {
    @Input() labelPosition!: 'before' | 'after';
    @Output() change = new EventEmitter();
    @Input() override disabled!: boolean;
    @Input() customError: boolean = false;
    @Input() customErrorMessage: string = '';

    constructor() {
        super();
    }

    onChange(matCheckbox: any) {
        this.change.emit(matCheckbox.checked)
    }

    getError() {
        const control = this.form.get(this.name);
        if (control && control.errors) {
            return this.objectValues(control.errors)[0];
        }
        return null;
    }
}
