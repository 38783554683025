import { CommonModule } from '@angular/common';
import { Component, effect, EventEmitter, Input, input, OnInit, Output, QueryList, SimpleChanges, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { GoogleMap, GoogleMapsModule, MapAdvancedMarker, MapInfoWindow } from '@angular/google-maps';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication.service';
import { ROUTES } from 'src/app/core/enums/routes';
import { TypeMapMarker } from 'src/app/core/enums/typeMapMarker';
import { AlertService } from 'src/app/core/services/alert.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { MaterialModule } from 'src/app/material/material.module';
import { MarkerGoogleMaps } from 'src/app/shared/models/maps/markerGoogleMaps';
import { Specialty } from 'src/app/shared/models/specialty';
import { CapitalizeFirstPipe } from 'src/app/shared/pipes/capitalize-first.pipe';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { MapsService } from 'src/app/shared/services/maps.service';

export interface locateMarker {
    marker:MarkerGoogleMaps,
    timeStamp: Date
}

@Component({
    selector: 'app-map',
    standalone: true,
    imports: [GoogleMapsModule, MaterialModule, CommonModule, PipesModule],
    templateUrl: './map.component.html',
    styleUrl: './map.component.scss',
})

//CENTER:
//ON INIT EL OPERADOR COMPARTE LA UBICACIÓN  ? SETCENTER : NULL
//EL CENTER ES ESTABLECIDO POR LA DIRECCIÓN DEL CASO(FLUJO1) O LAS DIRECCIONES INGRESADAS EN EL BUSCADOR(FLUJO2)

export class MapComponent implements OnInit {

    @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
    @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;
    @ViewChild('templatePatient', { static: true }) templatePatient: TemplateRef<any>;
    @ViewChild('templateProvider', { static: true }) templateProvider: TemplateRef<any>;
    @ViewChild('templateCase', { static: true }) templateCase: TemplateRef<any>;
    @ViewChildren('markerElem') markerElements!: QueryList<MapAdvancedMarker>;

    // INPUT PARAMS
    // center = input<MarkerGoogleMaps>();
    _localMarkers: MarkerGoogleMaps[];
    title = input<string>();
    specialtyName = input<string>();
    specialtyId = input<number>();
    // inputMarkersSignal = input<MarkerGoogleMaps[]>([]);
    showProviders = input<boolean>();
    markerSelected = input<locateMarker>();
    private _localCenter: MarkerGoogleMaps;
    markersSignal: MarkerGoogleMaps[];
    width = input<number>();
    height = input<number>();
    centerFlag: boolean = false;
    specialtySelected = input<Specialty>();
    @Output() actualCenter = new EventEmitter<MarkerGoogleMaps>(null);

    styles: google.maps.MapTypeStyle[] = [
        {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
        },
        {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [{ visibility: 'off' }],
        },
    ];

    options: google.maps.MapOptions = {
        mapId: 'initMap',
        zoom: 8,
        controlSize: 30,
        center: { lat: -34.6037, lng: -58.3816 }, // Coordenadas de Buenos Aires, Argentina        // zoomControlOptions: {
        zoomControl: true,
        zoomControlOptions: {
            position: google.maps.ControlPosition.LEFT_TOP,
        },
        // mapTypeControl: true,
        // mapTypeControlOptions: {
        //     style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        //     position: google.maps.ControlPosition.LEFT_TOP,
        // },
        streetViewControl: true,
        streetViewControlOptions: {
            position: google.maps.ControlPosition.LEFT_TOP,
        },
        // styles: this.styles,
    };

    cardInfoEnabled: TypeMapMarker[] = [
        TypeMapMarker.PATIENT_ACTIVE,
        TypeMapMarker.PATIENT_INACTIVE,
        TypeMapMarker.PROVIDER
    ]
    infoContent = 'Hola infoContent';
    markerContent: { [key: string]: SVGElement } = {};
    selectedMarker: MarkerGoogleMaps;
    _markers: Subscription;

    get localCenter() {
        return this._localCenter
    }
    set localCenter(value: MarkerGoogleMaps) {
        this._localCenter = value
        this.actualCenter.emit(this.localCenter)
    }

    get localMarkers(){
        return this._localMarkers
    }

    set localMarkers(value){
       let previousMarkers = value;
       let newMarkers = []
       previousMarkers.forEach(m=>{
        if(m.visible){
            newMarkers.push(m)
        }
       })
        this._localMarkers = newMarkers

    }

    constructor(
        public mapsService: MapsService,
        public alertService: AlertService,
        public generalService: GeneralService,
        private router: Router,
        private authService : AuthenticationService
    ) {
        effect(() => {
            if (!!this.markerSelected()){
                this.simulateClick(this.markerSelected().marker.id)
            }
        });
    }

    ngOnInit(): void {
        this._markers = this.mapsService.getMarkers$().subscribe((markers) => {
            if (!!markers) {
                const markersClean = markers.filter(item => {
                    if (item.type == 'GENERAL' || item.type == 'CASE') {
                        this.centerFlag = true;
                        this.localCenter = { lat: item.lat, lng: item.lng, type: item.type }
                        this.updateMapOptions(this.localCenter)
                    }
                    // Eliminar elementos que sean null o undefined
                    return item !== null && item !== undefined;
                });
                this.localMarkers = markersClean
            }
        });
        if (navigator.geolocation && !this.centerFlag) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const userLocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    if (this.map && this.map.googleMap) {
                        this.map.googleMap.setCenter(userLocation);
                        this.animateZoom(13)
                        // this.onCenter = userLocation
                        this.localCenter = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                            type: TypeMapMarker.GENERAL
                        }
                    }
                },
                error => {
                    console.error('Error getting user location', error);
                    // Si hay un error o no se concede el permiso, el mapa permanecerá centrado en Argentina
                }
            );
        }
    }

    // ngAfterViewInit(): void {
    //     console.log('ngAfterViewInit()');
    //     this.updateMapOptions()
    // }

    private updateMapOptions(value?): void {
        if (value && this.map) {
            // if (value.type = 'CASE') { this.notCase = false }
            const userLocation = {
                lat: value?.lat,
                lng: value?.lng
            };
            this.map.googleMap.setCenter(userLocation);
        }
    }

    animateZoom(targetZoom: number) {
        if (this.map) {
            const currentZoom = this.map.googleMap.getZoom();
            if (currentZoom !== targetZoom) {
                const zoomInterval = setInterval(() => {
                    const newZoom = currentZoom < targetZoom ? this.map!.getZoom()! + 1 : this.map!.getZoom()! - 1;
                    this.map.googleMap.setZoom(newZoom);

                    if (newZoom === targetZoom) {
                        clearInterval(zoomInterval);
                    }
                }, 300); // Velocidad del zoom
            }
        }
    }

    copyToClipboard(value) {
        navigator.clipboard.writeText(value)
            .then(() => {
                this.alertService.openInfo('Texto copiado al portapapeles');
                console.log('Texto copiado al portapapeles');
            })
            .catch(err => {
                console.error('Error al copiar:', err);
            });
    }



    openInfo(markerElem: MapAdvancedMarker, markerData: MarkerGoogleMaps): void {
        console.log('Entró a info', markerElem, markerData);

        if (this.cardInfoEnabled.includes(markerData.type)) {
            if (this.selectedMarker === markerData) {
                // Si el marcador ya está abierto, lo cerramos
                this.info.close();
                this.selectedMarker = null;
            } else {
                // Abre el InfoWindow
                this.selectedMarker = markerData;
                this.info.open(markerElem);
            }
        }
    }

    getInfoWindowTemplate(marker: MarkerGoogleMaps): TemplateRef<any> {
        switch (marker.type) {

            case TypeMapMarker.CASE:
                return this.templateCase;

            case TypeMapMarker.PATIENT_ACTIVE:
                return this.templatePatient;

            case TypeMapMarker.PATIENT_INACTIVE:
                return this.templatePatient;

            case TypeMapMarker.PROVIDER:
                return this.templateProvider;

            default:
                break;
        }
    }

    simulateClick(markerId: number): void {
        this.info.close()
        this.selectedMarker = null;
        if (!this.markerElements || this.markerElements.length === 0) {
            console.error('Marker elements not ready yet.');
            return;
        }

        const markerElem = this.markerElements.toArray().find((elem, index) => {
            const markerData = this.localMarkers[index];
            return markerData && markerData.id === markerId;
        });

        if (markerElem) {
            const markerData = this.localMarkers.find(m => m.id === markerId);
            if (markerData) {
                this.openInfo(markerElem, markerData);
            }
        } else {
            console.error('No se encontró el marcador con el ID:', markerId);
        }
    }


    verifyUserTypeAdmin() {
        return this.authService.isAdmin();
    }

    goToCase(caseId: number): void {
        const isAdmin = this.verifyUserTypeAdmin();

        const route = isAdmin ? ROUTES.PROVISIONS_CONTROL : ROUTES.CASES;
        const title = isAdmin ? 'Control prestacional' : 'Listado de casos';

        this.generalService.setGoBack({ route, title });

        // Construye la URL completa
        const url = this.router.serializeUrl(
            this.router.createUrlTree([route, caseId])
        );

        // Abre la URL en una nueva pestaña
        window.open(url, '_blank');
    }

    ngOnDestroy(): void {
        this.localCenter = null;
        this.markersSignal = [];
        this.selectedMarker = null;
    }
}
