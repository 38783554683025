import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    effect,
    input,
    viewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { NomenclatorFacade } from 'src/app/abstraction/nomenclator.facade';
import { LocationPOI } from 'src/app/shared/models/maps/locationPOI';
import { MarkerGoogleMaps } from 'src/app/shared/models/maps/markerGoogleMaps';
import { Specialty } from 'src/app/shared/models/specialty';
import { MapsService } from 'src/app/shared/services/maps.service';
import { Provider } from 'src/app/shared/models/provider';
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';
import { ProvisionsFacade } from 'src/app/abstraction/provisions.facade';
import { AgreementsBySpecialtyQPS } from 'src/app/core/services/provisions.service';
import { Patient } from 'src/app/shared/models/patient';
import { MapsFacade } from 'src/app/abstraction/maps.facade';
import { TypeMapMarker } from 'src/app/core/enums/typeMapMarker';
import { Agreement } from 'src/app/shared/models/agreement';
import * as moment from 'moment';
import { filterFromdateList } from 'src/app/shared/models/filter';
import { Case } from 'src/app/shared/models/case';
import { Provision } from 'src/app/shared/models/provision';
import { ProvidersQPS } from 'src/app/core/services/providers.service';
import { Practice } from 'src/app/shared/models/practice';
import { Logger } from 'sass';
import { GeneralService } from 'src/app/core/services/general.service';
import { onCheckMarker, MarkersListComponent } from '../../components/markers-list/markers-list.component';
import { locateMarker } from '../../components/map/map.component';

@Component({
    selector: 'app-specialties-providers-pois',
    templateUrl: './specialties-providers-pois.component.html',
    styleUrl: './specialties-providers-pois.component.scss',
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ display: 'none' })),
            state('expanded', style({ display: 'visible' })),
            transition(
                'expanded <=> collapsed',
                animate('230ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            ),
            transition(
                'expanded <=> void',
                animate('230ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            ),
        ]),
    ],

})
export class SpecialtiesProvidersPoisComponent implements OnInit {
    public readonly SPECIALTIES = 'specialties';
    public readonly SPECIALTIES_ID = 'id';
    public readonly SPECIALTIES_SEARCHING = 'specialtiesSearching';
    public readonly FROMDATE = 'fromDate';
    public readonly INCLUDE_PROVIDERS = 'includeProviders';
    public readonly INCLUDE_ACTIVE_PATIENTS = 'includeActivePatients';
    public readonly INCLUDE_INACTIVE_PATIENTS = 'includeInactivePatients';
    public readonly MEDICAL_LICENSES = 'medicalLicenses';
    public readonly MEDICAL_LICENSE_VALUE = 'value';
    public readonly MEDICAL_LICENSE_VALIDITY = 'validity';
    public readonly MEDICAL_LICENSE_TYPE = 'type';
    public readonly SELECT_PROVIDERS = 'selectProviders';
    private _center: MarkerGoogleMaps;
    private _addressSelected: MarkerGoogleMaps;

    isLoadingGettingProviders$: Observable<boolean>;
    isLoadingGetAgreementsBySpecialtyId$: Observable<boolean>;
    _markers: Subscription;
    providersForm: FormGroup;
    localMarkers: MarkerGoogleMaps[];
    poi: LocationPOI;
    index: number = 0;
    agreementsBySpecialty: Agreement[];
    providers: Provider[];
    providersPois: MarkerGoogleMaps[];
    patients: Patient[];
    practice: Practice;
    specialtyName: string;
    patientsActive: Patient[];
    patientsInactive: Patient[];
    actualProviderSpecialties: string[] = [null];
    specialtiesOnSelect: [Specialty[]];
    specialtySelected: number = null;
    lastSpecialctySelected: number | null = null;
    dataVisible: boolean = true;
    filterVisible: boolean = true;
    flow: string = null;
    checkPatientsActive: boolean = false;
    checkPatientsInactive: boolean = false;
    checkProviders: boolean = true;
    refresh: boolean = false;
    loadingFilters = false;
    cd: ChangeDetectorRef;
    provisionToWork: Provision;
    enableFilters: boolean = false;
    mapWidth: number;
    mapHeight: number;
    providersListVisible: boolean = true;
    showAllProvidersList: boolean = true;
    markerSelectedFromProviderList: locateMarker;
    actualCenter:MarkerGoogleMaps;

    get center() {
        return this._center;
    }

    set center(value) {
        this._center = value;
    }

    get addressSelected() {
        return this._addressSelected;
    }

    set addressSelected(value) {
        this._addressSelected = value;
        this.center = value;
    }

    fromDateFilters: filterFromdateList[] = [
        {
            id: 1,
            description: 'Último mes',
            value: moment().subtract(1, 'months').startOf('month'),
        },
        {
            id: 2,
            description: 'Ûltimos 2 meses',
            value: moment().subtract(2, 'months').startOf('month'),
        },
        {
            id: 3,
            description: 'Ûltimos 3 meses',
            value: moment().subtract(3, 'months').startOf('month'),
        },
        {
            id: 6,
            description: 'Ûltimos 6 meses',
            value: moment().subtract(6, 'months').startOf('month'),
        },
        {
            id: 9,
            description: 'Ûltimos 9 meses',
            value: moment().subtract(9, 'months').startOf('month'),
        },
        {
            id: 12,
            description: 'Ûltimos 12 meses',
            value: moment().subtract(12, 'months').startOf('month'),
        },
    ];
    supply: boolean = false;
    isAddModeAgreement: boolean;
    providersQPS: ProvidersQPS = {
        active: true,
        isSupply: false,
        isFilteredByRegion: false,
        specialtyId: this.specialtySelected
    };

    typesToRemovePois = [
        TypeMapMarker.PATIENT_ACTIVE,
        TypeMapMarker.PATIENT_INACTIVE,
        TypeMapMarker.PROVIDER,
    ];
    @ViewChild('mapDiv') mapDiv: ElementRef;
    @Input() isExpanded: boolean = false;
    @Output('onChangeExpand') onChangeExpandEvent = new EventEmitter<boolean>();
    @Input() inputMarkers: MarkerGoogleMaps[];
    specialtyId = input<number>();
    screenCalling = input<string>();
    patient = input<Patient>();
    provision = input<Provision>();
    specialty = input<Specialty>();
    fromDateSelected: Date = this.fromDateFilters[2].value.toDate();
    patientAdress: string;

    constructor(
        private generalService: GeneralService,
        public entitiesFacade: EntitiesFacade,
        public mapsService: MapsService,
        private mapsFacade: MapsFacade,
        private provisionsFacade: ProvisionsFacade,
        private formBuilder: FormBuilder,
        private nomenclatorFacade: NomenclatorFacade
    ) {
        effect(() => {
            this.flow = this.screenCalling();
            //----CUANDO EL FLUJO ES POR PROVISIONS-DETAIL----//
            if (!!this.screenCalling()) {
                if (!!this.specialty()) {
                    this.specialtySelected = this.specialty()?.id
                    this.onEnableFilters();
                    this.providersQPS = { ...this.providersQPS, specialtyId: this.specialtySelected };
                    this.loadProviders();
                    this.getAgreementsBySpecialty();
                }
                this.practice = this.provision()?.practice;
                this.specialtyName = this.specialty()?.name;
                const patientToMarkers = []
                const patientMarker = this.mapsService.castPatientFromCaseToMarkerGoogleMaps(this.patient(), TypeMapMarker.CASE);
                patientToMarkers.push(patientMarker);
                this.poi = this.mapsService.castAddressPatientToAddressMap(this.patient());
                this.mapsFacade.mergeMarkers(patientToMarkers);
                // this.center = patientMarker;
            }
            //-------------------//
        });
    }

    ngOnInit(): void {
        this.generalService.sendMessage("Buscar Prestadores");
        this.providersForm = this.createProvidersForm();

        // this.isLoadingGettingProviders$ = this.entitiesFacade. isLoadingGettingProviders$();
        this.isLoadingGetAgreementsBySpecialtyId$ = this.provisionsFacade.isLoadingGetAgreementsBySpecialtyId$();
        //Guardamos los agreements para filtrar pacientes localmente
        this._markers = this.mapsService.getMarkers$().subscribe((markers) => {
            if (!!markers) {
                this.localMarkers = [...markers];
            }
        });
    }

    ngAfterViewInit(): void {
        // Acceder a las dimensiones del div una vez que se ha renderizado.
        this.mapWidth = this.mapDiv.nativeElement.offsetWidth;
        this.mapHeight = this.mapDiv.nativeElement.offsetHeight;
    }


    onPressMarkerLocation(event: onCheckMarker) {
        const marker = this.localMarkers.find(m => m.id === event.id && m.type === event.type);
        const markerWithChange = {
            marker: marker,
            timeStamp : new Date()
        }
        if (!!marker) {
            this.markerSelectedFromProviderList = markerWithChange;
        } else {
            console.warn(`No se encontró un marcador con id ${event.id} y tipo ${event.type}`);
        }
    }


    onTabChanged(e) {
        console.log(e);
        setTimeout(() => {
            this.refresh = !this.refresh;
            this.cd.detectChanges();
        }, 100);
    }

    onEnableFilters() {
        this.enableFilters = true;
    }

    getSpecialtiesByName = (value: string) =>
        this.nomenclatorFacade.loadSpecialties({ name_like: value });

    calculateNameSpecialty(element) {
        return element ? element.name : null;
    }
    calculateIdSpecialty(element) {
        return element ? element.id : null;
    }

    onClickConfirmProvidersForm() {
        console.log('submit providersForm');
    }

    setDataVisible() {
        this.dataVisible = !this.dataVisible;
    }

    createProvidersForm() {
        return this.formBuilder.group({
            arrangement: new FormControl({ disabled: false }),
            [this.SPECIALTIES_ID]: [''],
            [this.SPECIALTIES_SEARCHING]: [''],
            [this.SELECT_PROVIDERS]: [true]
        });
    }

    removeDuplicatedEntities = (entitieList) => {
        const result = entitieList.reduce((acc, current) => {
            if (!acc.some((item) => item.id === current.id)) {
                acc.push(current);
            }
            return acc;
        }, []);
        return result;
    };

    //FILTRAMOS PACIENTES ACTIVOS LOCALMENTE
    getPatientsActiveBySpecialty() {
        const activePatientsBySpecialty = [];
        const activePatientsToPois = [];

        // Obtener los acuerdos activos por especialidad
        const activeAgreements = this.agreementsBySpecialty
          .flatMap(agreements => agreements.casee)
          .filter(element => element.active === true);

        // Asignar casoId a cada acuerdo activo
        activeAgreements.forEach(a => {
          a.patient.caseId = a.id;
        });

        // Obtener pacientes activos por especialidad
        activePatientsBySpecialty.push(
          activeAgreements.flatMap(agreement => agreement.patient)
        );

        const activePatientsToReduce = activePatientsBySpecialty.flat();
        //Eliminamos patients repetidos
        const uniqueActivePatients = this.removeDuplicatedEntities(
            activePatientsToReduce
        );
        activePatientsToPois.push(...uniqueActivePatients);
        const activePatientsToMarker = [];
        activePatientsToPois.forEach((patient: Patient) => {
            const castActivePatientsToPois =
                this.mapsService.castPatientToMarkerGoogleMaps(
                    patient,
                    TypeMapMarker.PATIENT_ACTIVE
                );
            activePatientsToMarker.push(castActivePatientsToPois);
        });
        this.patientsActive = activePatientsToMarker;
        this.mapsFacade.mergeMarkers(activePatientsToMarker);
    }
    //FILTRAMOS PACIENTES INACTIVOS LOCALMENTE
    getPatientsInactiveBySpecialty() {
        const inactivePatientsBySpecialty = [];
        let inactivePatientsToPois = [];

        // Obtener los acuerdos inactivos por especialidad
        const inactiveAgreements = this.agreementsBySpecialty
          .flatMap(agreements => agreements.casee)
          .filter(element => element.active === false);

        // Asignar casoId a cada acuerdo inactivo
        inactiveAgreements.forEach(a => {
          a.patient.caseId = a.id;
        });

        // Obtener pacientes inactivos por especialidad
        inactivePatientsBySpecialty.push(
          inactiveAgreements.flatMap(agreement => agreement.patient)
        );

        // Flattar el resultado final
        inactivePatientsToPois = inactivePatientsBySpecialty.flat();

        //Eliminamos patients repetidos
        const uniqueActivePatients = this.removeDuplicatedEntities(
            inactivePatientsToPois
        );
        inactivePatientsToPois.push(...uniqueActivePatients);
        const inactivePatientsToMarker = [];
        inactivePatientsToPois.forEach((patient: Patient) => {
            const castActivePatientsToPois =
                this.mapsService.castPatientToMarkerGoogleMaps(
                    patient,
                    TypeMapMarker.PATIENT_INACTIVE
                );
            inactivePatientsToMarker.push(castActivePatientsToPois);
        });
        this.patientsInactive = inactivePatientsToMarker;
        this.mapsFacade.mergeMarkers(inactivePatientsToMarker);
    }

    //TRAEMOS LOS AGREEMENTS Y LOS GUARDAMOS LOCALMENTE PARA PODER FILTRAR PACIENTES
    getAgreementsBySpecialty() {
        this.agreementsBySpecialty = [];
        this.patientsActive = [];
        this.patientsInactive = [];
        let qps: AgreementsBySpecialtyQPS = {
            specialtyId: this.specialtySelected,
            ...(this.fromDateSelected && { fromDate: this.fromDateSelected }),
        };
        this.provisionsFacade
            .loadAgreementBySpecialtyId(qps)
            .subscribe((agreements) => {
                this.agreementsBySpecialty = agreements;
                if (agreements) {
                    this.checkPatientsActive ? this.getPatientsActiveBySpecialty() : null
                    this.checkPatientsInactive ? this.getPatientsInactiveBySpecialty() : null
                }
            });
    }

    //CADA VEZ QUE ELEGIMOS ESPECIALIDAD MANUALMENTE
    onSelectSpecialty(specialty) {
        if (!!specialty?.id) {
            this.providersPois = null;
            this.specialtyName = specialty?.name;
            this.mapsService.deleteMarkersByTypes([TypeMapMarker.PATIENT_INACTIVE, TypeMapMarker.PATIENT_ACTIVE, TypeMapMarker.PROVIDER])
            this.specialtySelected = specialty?.id;
            this.providersQPS = { ...this.providersQPS, specialtyId: this.specialtySelected };
            this.loadProviders();
            this.getAgreementsBySpecialty();
            this.onEnableFilters();
            // this.setShowAllProvidersList();
        }
    }

    //SI TENEMOS GUARDADOS LOS PROVIDERS LOS VUELVE A SETEAR EN EL MAPA SINÓ LOS PIDE Y LOS DEJA GUARDADOS
    loadProviders = () => {
        this.entitiesFacade.loadProviders(this.providersQPS).subscribe(providers => {
            this.providers = providers;
            // Load providers in google-maps
            const providersFilteredById = providers.filter(p => p.specialties.some(s => s.id === this.specialtySelected));
            const locations = providersFilteredById.flatMap((provider) => {
                if (!!provider.address.location) {
                    return this.mapsService.castProviderToMarkerGoogleMaps(provider);
                }
            });
            this.providersPois = locations;
            this.mapsFacade.mergeMarkers(locations);
        })
    }

    //ACCIONES POR ESTADO DE FILTROS
    onChangeProviderFilter(event) {
        event == true ?
            this.mapsService.deleteMarkersByType(TypeMapMarker.PROVIDER)
            :
            this.loadProviders();
    }
    onChangePatientActiveFilter(event) {
        event == false ?
            [
                this.mapsService.deleteMarkersByType(TypeMapMarker.PATIENT_ACTIVE),
                this.checkPatientsActive = false
            ]
            :
            [
                this.getPatientsActiveBySpecialty(),
                this.checkPatientsActive = true
            ]
    }
    onChangePatientInactiveFilter(event) {
        event == false ?
            [
                this.mapsService.deleteMarkersByType(TypeMapMarker.PATIENT_INACTIVE),
                this.checkPatientsInactive = true
            ]
            :
            [
                this.getPatientsInactiveBySpecialty(),
                this.checkPatientsInactive = false
            ]
    }
    onChangeFromDateFilter(event) {
        this.mapsService.deleteMarkersByTypes([TypeMapMarker.PATIENT_INACTIVE, TypeMapMarker.PATIENT_ACTIVE])
        this.agreementsBySpecialty = null;
        this.fromDateSelected = event;
        this.getAgreementsBySpecialty();
    }

    private calculateMapDimensions(): void {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        // Map size from screen size
        // Set.width(screenWidth);
        // this.height()screenHeight;
    }

    setProvidersListVisible() {
        this.providersListVisible = !this.providersListVisible
    }

    setShowAllProvidersList() {
        this.mapsFacade.setVisibleAllMarkers()
        this.showAllProvidersList = !this.showAllProvidersList
    }

    getMapCenter(event:MarkerGoogleMaps){
        this.actualCenter = event
    }


    ngOnDestroy() {
        this.mapsFacade.setMarkers(null);
        !!this._markers ? this._markers.unsubscribe() : null;
        this.center = null;
        this.localMarkers = null;
        this.screenCalling = null;
    }
}
