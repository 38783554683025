import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, input, effect, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FormsModule } from "../../../../shared/components/forms/forms.module";
import * as moment from 'moment';
import { filterFromdateList } from 'src/app/shared/models/filter';
import { ProvidersQPS } from 'src/app/core/services/providers.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MaterialModule } from 'src/app/material/material.module';

@Component({
    selector: 'app-filter-by-agreements',
    standalone: true,
    imports: [FormsModule, MaterialModule],
    templateUrl: './filter-by-agreements.component.html',
    styleUrl: './filter-by-agreements.component.scss',
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ display: 'none' })),
            state('expanded', style({ display: 'visible' })),
            transition('expanded <=> collapsed', animate('230ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            transition('expanded <=> void', animate('230ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class FilterByAgreementsComponent implements OnInit {
    public readonly INCLUDE_PROVIDERS = 'includeProviders'
    public readonly INCLUDE_ACTIVE_PATIENTS = 'includeActivePatients';
    public readonly INCLUDE_INACTIVE_PATIENTS = 'includeInactivePatients';
    public readonly FROMDATE = 'fromDate';

    filtersForm: FormGroup;
    filterVisible: boolean = true;
    // checksFiltersEnabled: boolean = false;
    loadingProvidersFilters = false;
    loadingPatientsFilters = false;
    screenCalling = input<string>();
    providersCheck: boolean = false;
    patientsCheck: boolean = false;
    patientsFiltersVisible: boolean = true;
    providersFiltersVisible: boolean = true;

    @Input() isExpanded: boolean = false;
    @Output('onChangeExpand') onChangeExpandEvent = new EventEmitter<boolean>();
    @Output() fromDateToQps = new EventEmitter<Date>();
    @Output() providerFilter = new EventEmitter<boolean>();
    @Output() patientActiveFilter = new EventEmitter<boolean>();
    @Output() patientInactiveFilter = new EventEmitter<boolean>();
    @Input() enableFilters: boolean = false;


    providerQPS_Default: ProvidersQPS = {
        active: true,
        isSupply: false,
        isFilteredByRegion: false,
    };
    fromDateList: filterFromdateList[] = [
        {
            id: 1,
            description: 'Último mes',
            value: moment().subtract(1, 'months').startOf('month')
        },
        {
            id: 2,
            description: 'Ûltimos 2 meses',
            value: moment().subtract(2, 'months').startOf('month')
        },
        {
            id: 3,
            description: 'Ûltimos 3 meses',
            value: moment().subtract(3, 'months').startOf('month')
        },
        {
            id: 6,
            description: 'Ûltimos 6 meses',
            value: moment().subtract(6, 'months').startOf('month')
        },
        {
            id: 9,
            description: 'Ûltimos 9 meses',
            value: moment().subtract(9, 'months').startOf('month')
        },
        {
            id: 12,
            description: 'Ûltimos 12 meses',
            value: moment().subtract(12, 'months').startOf('month')
        },
    ]
    fromDateSelected: Date = this.fromDateList[2].value.toDate();


    constructor(
        private formBuilder: FormBuilder,
    ) {
        effect(() => {
            // console.log('value', this.enableFilters);
        });
    }

    setFilterVisible() {
        this.filterVisible = !this.filterVisible;
    }

    createFiltersForm() {
        return this.formBuilder.group({
            arrangement: new FormControl({ disabled: false }),
            [this.INCLUDE_ACTIVE_PATIENTS]: [{ disabled: !this.enableFilters, value: '' }],
            [this.INCLUDE_INACTIVE_PATIENTS]: [{ disabled: !this.enableFilters, value: '' }],
            [this.INCLUDE_PROVIDERS]: [{ disabled: !this.enableFilters, value: 'true' }],
            [this.FROMDATE]: [{ disabled: !this.enableFilters, value: '' }],
        });
    }

    calculateIdFromDate(state) {
        return state != null ? state.id : null
    }

    calculateNameFromDate(state) {
        return state != null ? state.description : null
    }

    updateFromDate(value: Date) {
        this.fromDateSelected = value;
        this.fromDateToQps.emit(this.fromDateSelected);
    }

    onFilterFromDate(event: any): void {
        console.log('Fecha seleccionada:', event);
        const filtered = this.fromDateList.find(f => f.id === parseFloat(event));
        if (!!filtered) {
            this.updateFromDate(filtered.value.toDate());
        } else {
            console.error('No se encontró un filtro válido');
        }
    }

    loadingProvidersFilterSpinner() {
        this.loadingProvidersFilters = true;
        setTimeout(() => {
            this.loadingProvidersFilters = false;
        }, 500);
    }

    loadingPatientsFilterSpinner() {
        this.loadingPatientsFilters = true;
        setTimeout(() => {
            this.loadingPatientsFilters = false;
        }, 500);
    }

    get fromDateSelect() {
        return this.filtersForm.get(this.FROMDATE).value;
    }
    set fromDateSelect(value) {
        console.log(value);
    }

    ngOnInit(): void {
        this.filtersForm = this.createFiltersForm();
        //// Suscribirse a los cambios de los checkboxes
        ///Habilitar filtros
        //Incluir pacientes activos
        this.filtersForm.get('includeActivePatients').valueChanges.subscribe(value => {
            if (value) {
                this.patientActiveFilter.emit(true);
            } else {
                this.patientActiveFilter.emit(false);
            }
            this.loadingPatientsFilterSpinner();
        })
        //incluir pacientes inactivos
        this.filtersForm.get('includeInactivePatients').valueChanges.subscribe(value => {
            if (value) {
                this.patientInactiveFilter.emit(true)
            } else {
                this.patientInactiveFilter.emit(false)
            }
            this.loadingPatientsFilterSpinner();
        });
        //incluir domicilio de prestadores
        this.filtersForm.get('includeProviders').valueChanges.subscribe(value => {
            if (!value) {
                this.providerFilter.emit(true)
            } else {
                this.providerFilter.emit(false)
            }
            this.loadingProvidersFilterSpinner();
        });
    }


    ngOnDestroy() {

    }

    setPatientsFiltersVisible() {
        this.patientsFiltersVisible = !this.patientsFiltersVisible
    }

    setProvidersFiltersVisible() {
        this.providersFiltersVisible = !this.providersFiltersVisible
    }


}
