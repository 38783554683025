<div #notesTemplate>
    <form [formGroup]="evolutionForm" (submit)="addEvolution()">
        <div class="evolutions-side-bar flex-column">
            <!-- HEADER - Button close -->
            <div style="top: 0; width: 100%; position: relative;" >
                <mat-toolbar #notesContainer class="mat-toolbar-right flex-row align-space-between-center">
                    <div class="flex-row align-start-center text-primary">
                        <mat-icon style="margin-right: 1rem;">text_snippet</mat-icon>
                        <span class="mt-2" style="font-size: 18px;">Notas</span>
                    </div>
                    <button id="evolutionsSideBarComponent_btnCloseSideBar" mat-icon-button (click)="this.close()"
                        [color]="'primary'">
                        <mat-icon class="mls-2">highlight_off</mat-icon>
                    </button>
                </mat-toolbar>
                <!-- Notes -->
                <div class="example-loading-shade"
                    *ngIf="(this.isLoadingGettingEvolutions || this.isLoadingUpdatingEvolution)">
                    <app-spinner class="spinner"></app-spinner>
                </div>

                <!-- FILTER ---------Evolution type-->
                <div style="padding-bottom:0.5%;">
                    <app-select-input [name]="this.EVOLUTION_TYPE" [form]="this.evolutionForm"
                        [placeholder]="'Tipo de nota...'" [elements]="this.evolutionCategories"
                        [calculateId]="this.calculateCategoryOfEvolutionId"
                        [calculateName]="this.calculateCategoryOfEvolutionName" (selected)="onSelectCategory($event)"
                        [noOptionText]="'-- Sin filtrar --'">
                    </app-select-input>
                    <mat-divider></mat-divider>
                </div>
            </div>


            <!-- --NOTE AREA  -->
            <div #myScroll class="flex-column" style="overflow-y: scroll; height: 300px !important;">
                <div *ngFor="let evolution of dataEvolutions"  >
                    <div   [ngClass]="this.user.id === evolution.operatorId ? 'myself' : 'other'"
                        style="display: flex; " >
                        <!-- CARD -->
                        <div class="flex-100">
                            <!-- USER-->
                            <div class="flex-row"
                                [ngClass]="this.user.id === evolution.operatorId ? 'start-none' : 'none-none'"
                                style="height:30px !important">
                                <p class="subtitle-3 text-primary-darker"
                                    style="margin-bottom: 0px !important; text-overflow: ellipsis !important; white-space: nowrap; overflow: hidden;">
                                    {{ evolution.operator.name }}</p>
                                <p
                                    style="text-overflow: ellipsis !important; white-space: nowrap; padding-left: 1%; font-weight: 400 !important;font-size: 10px !important;line-height: 1.9;">
                                    - {{ evolution.category.category == 'NOTA_' ? 'NOTA' : evolution.category.category }}</p>
                                <p *ngIf="evolution.type == critical"
                                    style=" text-overflow: ellipsis !important; white-space: nowrap; padding-left: 1%; font-weight: 400 !important; color: red; font-size: 10px !important; line-height: 1.9;">
                                    - CRÍTICA </p>
                            </div>
                            <!-- TEXTO -->
                            <mat-card
                                [ngClass]="this.user.id === evolution.operatorId ? 'bg-primary' : 'bg-primary-100'"
                                style="border-radius: 10px; width: 40vh;">
                                <mat-card-content class="mat-font-small-11px"
                                    [ngStyle]="{'color': this.user.id === evolution.operatorId ? 'white' : 'black' }"
                                    style="overflow-wrap: anywhere; font-weight: 400;"
                                    [innerHTML]="evolution.text | safeHtml"> </mat-card-content>
                            </mat-card>
                            <!-- TEXTO SI HAY ATENCIÓN -->
                            <div *ngIf="evolution.attentionId" class="flex-column"
                                [ngClass]="this.user.id === evolution.operatorId ? 'start-none' : 'none-none'">
                                <div>
                                    <p class="mat-font-xsmall-px pl-2"
                                        style="margin-bottom: 0px !important; max-width: 100%; text-overflow: ellipsis !important; white-space: nowrap; overflow: hidden;">
                                        Atención del día: {{ evolution.activityDate | date:'dd/MM/yyyy HH:mm'}}</p>
                                </div>
                                <div>
                                    <p class="mat-font-xsmall-px pl-2"
                                        style="margin-bottom: 0px !important; line-height: 0"
                                        [ngStyle]="{'text-align': this.user.id === evolution.operatorId ? 'start' : 'start' }">
                                        Prestador: {{ evolution.provider.name }} {{ evolution.provider.surname }}
                                    </p>
                                </div>
                            </div>
                            <!-- ACCIONES -->
                            <div class="flex-row align-space-between-center gap-1em"
                                style="max-width: fit-content;">
                                <!-- Financier -->
                                <div class="flex-row card-items">
                                    <button class="flex-row flex-1-1-auto" style="max-width: fit-content;"
                                        mat-button matTooltip="{{this.case.financier.name}}"
                                        (click)="this.onclickEyeFinancier( evolution )"
                                        id="evolutionsSideBarComponent_btnFinancierVisibility"
                                        [ngClass]="this.user.id === evolution.operatorId ? 'text-primary' : 'text-primary-lighter-100'">
                                        <mat-icon
                                            [ngClass]="this.user.id === evolution.operatorId ? 'text-primary' : 'text-primary-lighter-100'"
                                            *ngIf="evolution.visibleFinancier"
                                            style="font-size: x-small;  line-height: 1.8;">visibility</mat-icon>
                                        <mat-icon
                                            [ngClass]="this.user.id === evolution.operatorId ? 'text-primary' : 'text-primary-lighter-100'"
                                            *ngIf="!evolution.visibleFinancier"
                                            style="font-size:x-small;;line-height: 1.8">visibility_off</mat-icon>
                                        <span class="mat-font-xsmall-px" style=" text-overflow: ellipsis;">{{
                                            this.case.financier.name }}</span>
                                    </button>
                                    <!-- Provider -->
                                    <button class="flerx-row flex-1-1-auto align-start-start"
                                        style="max-width: fit-content;" mat-button matTooltip="Prestadores"
                                        (click)="this.onclickEyeProvider( evolution )"
                                        id="evolutionsSideBarComponent_btnProviderVisibility"
                                        [ngClass]="this.user.id === evolution.operatorId ? 'text-primary' : 'text-primary-lighter-100'">
                                        <mat-icon
                                            [ngClass]="this.user.id === evolution.operatorId ? 'text-primary' : 'text-primary-lighter-100'"
                                            *ngIf="evolution.visibleProvider"
                                            style="font-size: x-small; line-height: 1.8">visibility</mat-icon>
                                        <mat-icon
                                            [ngClass]="this.user.id === evolution.operatorId ? 'text-primary' : 'text-primary-lighter-100'"
                                            *ngIf="!evolution.visibleProvider"
                                            style="font-size: x-small; line-height: 1.8">visibility_off</mat-icon>
                                        <span class="mat-font-xsmall-px">Prestadores</span>
                                    </button>
                                </div>
                                <div class="flex-row card-items">
                                    <p class="flex-row align-end-end mat-font-xsmall-px"
                                        style="line-height:2.5; max-width: fit-content;"
                                        [ngStyle]="{'text-align': this.user.id === evolution.operatorId ? 'end' : 'start' }">
                                        {{ evolution.date | date:'dd/MM/yy HH:mm' }}</p>
                                </div>
                                <!-- Fecha y Hs -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <mat-divider class="pb-4"></mat-divider>
            <!-- FOOTER - Input Note -->
            <div class="flex-column" style="width: 100%; padding-bottom: 1%;">
                <!-- <ng-container *ngIf="this.isLoadingCreatingEvolution"> -->
                    <!-- <app-spinner></app-spinner> -->
                <!-- </ng-container>
                <ng-container *ngIf="!this.isLoadingCreatingEvolution"> -->

                    <div class="px-5">
                        <span class="subtitle-3">Tipo de nota *</span>
                        <div class="prs-6">
                            <app-select-input class [name]="this.NOTE_TYPE" [form]="this.evolutionForm"
                                [placeholder]="'Seleccionar...'" [elements]="this.evolutionCategories"
                                [calculateId]="this.calculateCategoryOfEvolutionId"
                                [calculateName]="this.calculateCategoryOfEvolutionName">
                            </app-select-input>
                        </div>
                    </div>

                    <div class="flex-row">
                        <app-text-area-input [name]="this.NOTE" [form]="this.evolutionForm"
                            [placeholder]="'Ingrese nueva nota'" [resize]="false" class="flex-81"
                            style="padding-left: 5%;"></app-text-area-input>
                        <button [disabled]="!evolutionForm.valid"  id="evolutionsSideBarComponent_btnAddEvolution" type="submit"
                            matTooltip="Presione para crear la nota" style="align-self: center;" mat-icon-button
                            class="flex-10">
                            <mat-icon class="text-primary">send</mat-icon>
                        </button>
                        <!-- <mat-error *ngIf="evolutionForm.get(NOTE)?.hasError('required')">Este campo es obligatorio</mat-error> -->
                    </div>
                    <div class="flex-row align-start-start"
                        style="z-index: 1; position: relative;top: -10px; height: fit-content; ">
                        <app-checkbox-input class="flex-25" style="padding-left: 5%;" [form]="this.evolutionForm"
                            [label]="'Critica'" [labelPosition]="'before'"
                            [name]="this.IS_CRITICAL"></app-checkbox-input>
                        <mat-icon class="flex-75"
                            style="cursor: pointer;line-height: 1.6;z-index: 3 !important; overflow:visible"
                            [color]="'accent'" cdkOverlayOrigin
                            #originOverlay="cdkOverlayOrigin">help_outline</mat-icon>
                    </div>

                <!-- </ng-container> -->
            </div>
            <!-- -- -->
        </div>
    </form>
</div>
