<div class="flex-row align-center-center">
    <mat-icon class="mrs-2" [ngClass]="leftIconClass" *ngIf="leftIcon">
        {{ leftIcon }}
    </mat-icon>
    <mat-icon class="mrs-2" style="min-height: 20px; min-width: 20px;" [ngClass]="leftIconClass" *ngIf="leftIconSvg"
        [svgIcon]="leftIconSvg"></mat-icon>
    <mat-form-field class="flex-100" [formGroup]="form" appearance="outline">
        <mat-label *ngIf="this.label">
            <mat-icon *ngIf="this.icon">{{ icon }}</mat-icon>
            <b>{{ label }}</b>
        </mat-label>
        <input [readonly]="(readonly) ? readonly : null" [matDatepicker]="picker" matInput [formControlName]="name"
            id="{{name}}" name="{{name}}" placeholder="{{placeholder}}" [attr.disabled]="disabled ? disabled : null"
            (dateChange)="this.onClickDateChange($event)" (dateInput)="addEvent( $event )"
            [min]="this.min ? this.min : null" [max]="this.max ? this.max : null"
            (click)="this.togglePicker ? picker.open() : ''" (close)='onClose($event)'>
        <mat-datepicker-toggle [disabled]="disabled ? disabled : null" matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker [startAt]="startDate"></mat-datepicker>
        <button tabIndex="-1" [disabled]="!this.form.get(this.name)?.value || this.form.get(this.name)?.disabled"
            type="button" mat-button *ngIf="this.clearable" matSuffix mat-icon-button aria-label="Clear"
            (click)="this.form.get(this.name)?.reset()">
            <mat-icon>close</mat-icon>
        </button>
        <!-- <mat-error
            *ngIf="this.form.get( this.name )?.errors && (this.form.get(this.name)?.dirty || this.form.get( this.name )?.touched)">
            <b *ngIf="this.selDate != ''">{{ this.selDate }}</b>
            <b *ngIf="this.selDate === ''">{{ getError() }}</b>
        </mat-error> -->
        @if(hasError('required')) {
            <mat-error>
                {{!!this.getError() ? this.getError() : 'Este campo es obligatorio' }}
            </mat-error>
        }
        @if(hasError('matDatepickerMin') && form.get(name)?.value) {
            <mat-error>
                {{ !!this.getError() ? this.getError() : 'La fecha seleccionada es anterior a la mínima permitida' }}
            </mat-error>
        }
        @if(hasError('matDatepickerMax')) {
            <mat-error>
                {{!!this.getError() ? this.getError() : 'La fecha seleccionada es posterior a la máxima permitida' }}
            </mat-error>
        }
        @if(hasError('matDatepickerParse')) {
            <mat-error>
                {{!!this.getError() ? this.getError() : 'Fecha inválida' }}
            </mat-error>
        }
        @if(!this.form.get( this.name )?.errors && this.form.errors && this.errorMatcher && this.errorMatcher.errorNameMatcher && (this.form.get( this.name )?.dirty || this.form.get( this.name )?.touched)) {
            <mat-error>
                {{ this.form.errors[this.errorMatcher.errorNameMatcher] }}
            </mat-error>
        }
        @if(this.hint) {
            <mat-hint>{{this.hint}}</mat-hint>
        }
    </mat-form-field>
</div>
