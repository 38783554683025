import { Component, OnInit, Input } from '@angular/core';
import { FormElementComponent } from '../form-element/form-element.component';

@Component({
  selector: 'app-text-area-input',
  templateUrl: './text-area-input.component.html',
  styleUrls: ['./text-area-input.component.scss'],
})
export class TextAreaInputComponent extends FormElementComponent implements OnInit {
  @Input() maxLength!: number;
  @Input() clearable = false;
  @Input() height: number | string = 'auto';
  @Input() resize = false;
  defaultHeight: string = '105px !important';

  constructor() {
    super();
  }


  getError() {
    const control = this.form.get(this.name);
    if (control && control.errors) {
      return this.objectValues(control.errors)[0];
    }
    return null;
  }

  get computedHeight(): string {
    if (this.height === 'auto') {
      return this.defaultHeight;
    }
    if (typeof this.height === 'number') {
      return `${this.height}px`;
    }
    return this.height;
  }
}
