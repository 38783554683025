import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild, HostListener, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { EvolutionsFacade } from '../../../../abstraction/evolutions.facade';
import { Evolution } from '../../../../shared/models/evolution';
import { CasesFacade } from '../../../../abstraction/cases.facade';
import { Case } from '../../../../shared/models/case';
import { User } from '../../../../shared/models/user';
import { AuthenticationService } from '../../../../core/authentication.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CustomValidators } from '../../../../core/validators/custom-validators';
import { map, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { CategoryOfEvolution } from 'src/app/shared/models/categoryOfEvolution';
import { CategoriesOfEvolutionsQPS } from 'src/app/core/services/categories-of-evolutions.service';
import { CategoriesofEvolutionsFacade } from 'src/app/abstraction/categories-of-evolutions.facade';
import { isPast, set } from 'date-fns';
import { EvolutionsType } from 'src/app/core/enums/temporalEvolutionType';
import { PostEvolutionBody } from 'src/app/core/services/evolutions.service';


@Component({
    selector: 'app-evolutions-side-bar',
    templateUrl: './evolutions-side-bar.component.html',
    styleUrls: ['./evolutions-side-bar.component.scss']
})
export class EvolutionsSideBarComponent implements OnInit {

    public readonly NOTE = 'note';
    public readonly NOTE_TYPE = 'note_type';
    public readonly IS_CRITICAL = 'critical';
    public readonly EVOLUTION_TYPE = 'evolution_type';
    public readonly EVOLUTION_TEXT = 'evolution_text';
    evolutionForm: FormGroup;
    evolutionCategories: CategoryOfEvolution[];
    isOpening = false;
    noteTypeError: boolean = false;
    categorySelected: string = null;
    typeSelected: number = null;
    private globalClickUnlistener: () => void;

    @ViewChild('myScroll') private myScrollContainer: ElementRef;
    @ViewChild('notesTemplate', { static: true, read: true }) public notesTemplate: ElementRef


    _case: Subscription; case: Case;
    _evolutionsTypeNote: Subscription;
    public dataEvolutions: Evolution[];
    private allEvolutions: Evolution[];
    _user: Subscription; user: User;
    _evolutionCategories: Subscription;
    inputNote: string;
    // TODO: Only Critical works at moment
    critical = EvolutionsType.CRITICAL;

    _isLoadingGettingEvolutions: Subscription;
    _isLoadingCreatingEvolution: Subscription;
    _isLoadingUpdatingEvolution: Subscription;
    isLoadingGettingEvolutions: boolean;
    isLoadingCreatingEvolution: boolean;
    isLoadingUpdatingEvolution: boolean;

    get evolutionType() {
        return this.evolutionForm.get(this.EVOLUTION_TYPE).value;
    }
    get evolutionText() {
        return this.evolutionForm.get(this.EVOLUTION_TEXT).value;
    }

    get isCritical() {
        return this.evolutionForm.get(this.IS_CRITICAL).value;
    }

    get note() {
        return this.evolutionForm.get(this.NOTE).value;
    }

    get noteType() {
        return this.evolutionForm.get(this.NOTE_TYPE).value
    }


    constructor(
        private formBuilder: FormBuilder,
        private evolutionsFacade: EvolutionsFacade,
        private casesFacade: CasesFacade,
        private authenticationService: AuthenticationService,
        private cd: ChangeDetectorRef,
        private categoryOfEvolutionFacade: CategoriesofEvolutionsFacade,
        private elementRef: ElementRef,
        private elRef: ElementRef, private renderer: Renderer2
    ) {
        // this.globalClickUnlistener = this.renderer.listen('document', 'click', (event: MouseEvent) => {
        //     const clickedInside = this.elRef.nativeElement.contains(event.target);
        //     if (clickedInside) {
        //       console.log('Click dentro del componente');
        //     } else {
        //       console.log('Click fuera del componente');
        //     }
        //   });
    }


    ngOnInit(): void {

        this._isLoadingGettingEvolutions = this.evolutionsFacade.isLoadingGetEvolutions$().subscribe(loading => {
            this.isLoadingGettingEvolutions = loading;
        });

        this._isLoadingCreatingEvolution = this.evolutionsFacade.isLoadingCreatingEvolution$().subscribe(loading => {
            this.isLoadingCreatingEvolution = loading;
        });

        this._isLoadingUpdatingEvolution = this.evolutionsFacade.isLoadingUpdatingEvolution$().subscribe(loading => this.isLoadingUpdatingEvolution = loading);

        // User
        this._user = this.authenticationService.getUser$().subscribe(u => this.user = u)

        // Get case
        this._case = this.casesFacade.getCase$().subscribe(res => {
            this.case = res;

            // console.log("Case seleccionado ", this.case);


            if (!!this.case) {
                // Evolutions from state
                this._evolutionsTypeNote = this.evolutionsFacade.getEvolutionsTypeNote$()
                    .subscribe((evo: Evolution[]) => {
                        this.allEvolutions = evo;
                        if (!!this.categorySelected) {
                            const array = [];
                            evo.forEach(i => {
                                if (i.category.category == this.categorySelected) {
                                    array.push(i)
                                }
                                this.dataEvolutions = array
                            })
                        } else {
                            this.dataEvolutions = evo;
                            this.sortEvolutionsByDate();
                            this.cd.markForCheck();
                        }

                    })
            }
        });
        // this.scrollToBottom();

        const qps: CategoriesOfEvolutionsQPS = {
            active: 1
        }
        this._evolutionCategories = this.categoryOfEvolutionFacade.loadCategoriesOfEvolutions(qps).subscribe(
            categories => {
                console.log('viendo categorias', categories);
                const notesCategories = categories?.filter(i => i.category?.includes('NOTA_'));
                this.evolutionCategories = this.sortEvolutionsCategories(notesCategories)
            }
        );

        this.evolutionForm = this.createEvolutionForm();

        // Escuchar cambios en EVOLUTION_TYPE y actualizar NOTE_TYPE
        this.evolutionForm.get(this.EVOLUTION_TYPE)?.valueChanges.subscribe((selectedValue: number) => {
            if(!!selectedValue){
                this.evolutionForm.get(this.NOTE_TYPE)?.setValue(selectedValue, { emitEvent: false });
            }else{
                this.evolutionForm.get(this.NOTE_TYPE)?.setValue(undefined, { emitEvent: false });
                this.dataEvolutions = this.allEvolutions
            }

        });
    }

    onFilterNotes(value: string) {
        const array = [];
        this.allEvolutions.forEach(i => {
            if (i.category.category == value) {
                array.push(i)
            }
            this.dataEvolutions = array
        })
    }

    calculateCategoryOfEvolutionId(element: CategoryOfEvolution) {
        return element ? element.id : null;
    }

    calculateCategoryOfEvolutionName(element: CategoryOfEvolution) {
        return element ? element.description : null;
    }

    sortEvolutionsCategories(object) {
        return object.sort((a, b) => {
            return a.description.localeCompare(b.description);
        });
    }


    onSelectCategory(id: number) {
        let categorySelected: CategoryOfEvolution = this.evolutionCategories.find(category => category.id == id);
        if (categorySelected) {

            this.categorySelected = categorySelected.category;
            this.onFilterNotes(categorySelected.category)
        }
    }

    onSelectCategoryToPost(id: number) {
        this.noteTypeError = false;
    }

    createEvolutionForm(): FormGroup {
        return this.formBuilder.group({
            [this.EVOLUTION_TEXT]: [''],
            [this.EVOLUTION_TYPE]: [null],
            [this.NOTE]: ['', [CustomValidators.required('Ingrese un texto')]],
            [this.NOTE_TYPE]: [null, [CustomValidators.required('Seleccione el tipo de nota')]],
            [this.IS_CRITICAL]: [''],
        });
    }


    sortEvolutionsByDate() {
        this.dataEvolutions?.sort((a, b) => {
            if (a && a.date) {
                const aFromDate = moment(a.date);
                const bFromDate = moment(b.date);
                return aFromDate.isSame(bFromDate) ? 0 : (aFromDate.isBefore(bFromDate) ? 1 : -1);
            }
            return -1;
        }).reverse();
    }

    onclickEyeProvider(evo: Evolution) {

        evo.visibleProvider = !evo.visibleProvider;
        this.evolutionsFacade.patchEvolution(evo).toPromise()
            .then((_) => {
                // Nothing
            })
            .catch((err) => {
                console.log('error', err);
            });
    }


    onclickEyeFinancier(evo: Evolution) {

        evo.visibleFinancier = !evo.visibleFinancier;
        this.evolutionsFacade.patchEvolution(evo).toPromise()
            .then((_) => {
                // Nothing
            })
            .catch((err) => {
                console.log('error', err);
            });
    }


    close() {
        this.evolutionsFacade.setEvolutionsSideBarShow(false);
    }

    // TODO: ver types
    addEvolution() {
        this.noteTypeError = false;
        const newEvolution: PostEvolutionBody = {
            date: new Date(),
            caseId: this.case.id,
            type: !!this.isCritical ? EvolutionsType.CRITICAL : EvolutionsType.NORMAL,
            categoryId: this.noteType,
            text: this.note,
            mediaUrls: [],
        }

        this.evolutionsFacade.postEvolution(newEvolution).subscribe((evo) => {
            // Nothing to do
            // this.evolutionForm.reset();
            setTimeout(() => {
                this.scrollToBottom();
            }, 0);
        });

        this.evolutionForm.get(this.NOTE)?.reset();
        // this.evolutionForm.get(this.NOTE_TYPE)?.reset(null);

    }

    ngAfterContentInit(): void {
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.
        setTimeout(() => {
            this.scrollToBottom();
        }, 0);
    }

    scrollToBottom(): void {
        try {
            this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        } catch (err) {
            console.log(err);
        }
    }

    ngOnDestroy(): void {

        if (this.globalClickUnlistener) {
            this.globalClickUnlistener();
        }

        this.isOpening = false;

        console.log("DESTROY evolutions-side-bar-components");

        if (!!this._case) {
            this._case.unsubscribe();
        }

        if (!!this._evolutionsTypeNote) {
            this._evolutionsTypeNote.unsubscribe();
        }

        if (!!this._user) {
            this._user.unsubscribe();
        }
    }
}
