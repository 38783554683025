<div class="flex-row align-center-center">
    <mat-icon class="mrs-2" [ngClass]="leftIconClass" *ngIf="leftIcon">
        {{ leftIcon }}
    </mat-icon>
    <mat-icon class="mrs-2" style="min-height: 20px; min-width: 20px;" [ngClass]="leftIconClass"
        *ngIf="leftIconSvg"
        [svgIcon]="leftIconSvg">
    </mat-icon>

    <div class="flex-row" [formGroup]="form" class="flex-100">
        <div class="flex-100 align-start-center">

            <!-- Checkbox principal -->
            <mat-checkbox
                (change)="onChange($event)"
                [formControlName]="name"
                [disabled]="disabled"
                [labelPosition]="labelPosition"
            >
                {{ placeholder || label }}
            </mat-checkbox>

            <!-- mat-error: se muestra si hay error nativo
                 O si customError está en true -->
            <mat-error
                *ngIf="
                  (form.get(name)?.errors && (form.get(name)?.dirty || form.get(name)?.touched))
                  || customError
                "
            >
                <b>
                  <!-- Si customError está en true, mostramos el mensaje personalizado.
                       De lo contrario, se muestra el error nativo. -->
                  {{ customError ? customErrorMessage : getError() }}
                </b>
            </mat-error>

        </div>
    </div>
</div>
