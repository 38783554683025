<form [formGroup]="selectForm">
    <table multiTemplateDataRows class="flex-100" mat-table [dataSource]="this.dataSourceMarkers">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="selected">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let data" style="max-width: 10px; ">
                <app-checkbox-input [form]="selectForm" [name]="this.CHECK" (click)="onPressCheck(data.id)">
            </app-checkbox-input>
            </td>
        </ng-container>

        <!-- state column -->
        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let data" style="width: 30px; align-items: start; text-align: left">
                @if(data.active == true){
                    <mat-icon matTooltip="Active" [style.width.px]="15" [style.height.px]="13" svgIcon="ellipseGreen"></mat-icon>
                }
                @if(data.active == false){
                    <mat-icon matTooltip="Inactive" [style.width.px]="15" [style.height.px]="13" svgIcon="ellipseRed"></mat-icon>
                }
                @if(data?.potential === true){
                    <mat-icon matTooltip="Inactive" [style.width.px]="15" [style.height.px]="13" svgIcon="ellipseGrey"></mat-icon>
                }
            </td>
        </ng-container>

        <!-- marker name column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let data">
                <div>
                    <span  style="display: flex; text-align: start;">{{ data.name + ' ' + data.surname }}</span>
                </div>
            </td>
        </ng-container>

        <!-- practice name Column -->
        <ng-container matColumnDef="practice">
            <th mat-header-cell *matHeaderCellDef >Práctica</th>
            <td mat-cell *matCellDef="let data">
                <div class="flex-column align-center-center">
                    <span>{{specialty()}}</span>
                </div>
            </td>
        </ng-container>

        <!-- Actions Menu -->
        <!-- <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let data">
                <div class="inner-cell">
                    <button mat-icon-button (click)="null" matTooltip="Acciones">
                        <mat-icon class="cursor-pointer text-secondary">more_vert</mat-icon>
                    </button>
                </div>
            </td>
        </ng-container> -->

        <!-- location column -->
        <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef>Ubicación</th>
            <td mat-cell *matCellDef="let data">
                <div class="inner-cell">
                    <button mat-icon-button >
                        <mat-icon (click)="onClickLocation(data.id, data.type)" class="cursor-pointer" style="color: hsl(226, 100%, 29%);">place</mat-icon>
                                    </button>
                </div>
            </td>
        </ng-container>

        <!-- Header and Rows -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="hide-header"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="bg-on-hover example-element-row"></tr>
    </table>
    </form>
