import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
// import { ExportAsConfig, ExportAsService } from 'ngx-export-as';
import { Subscription, Observable, of } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication.service';
import { TasksStates } from 'src/app/core/enums/tasksStates';
import { EvolutionsType } from 'src/app/core/enums/temporalEvolutionType';
import { ExcelService } from 'src/app/core/services/excel.service';
import { FileUtilitiesService } from 'src/app/core/services/fileUtilities.service';
import { CustomValidators } from 'src/app/core/validators/custom-validators';
import { DialogEndTaskComponent } from 'src/app/modules/tasks/components/dialog-end-task/dialog-end-task.component';
import { Attention } from 'src/app/shared/models/attention';
import { Evolution } from 'src/app/shared/models/evolution';
import { EvolutionExcel } from 'src/app/shared/models/evolutionExcel';
import { StateTask } from 'src/app/shared/models/stateTask';
import { Task } from 'src/app/shared/models/task';
import { User } from 'src/app/shared/models/user';
import Swal from 'sweetalert2';
import { EntitiesFacade } from 'src/app/abstraction/entities.facade';
import { Case } from 'src/app/shared/models/case';
import { AttentionComposedWithEvolutions, EvolutionsFacade } from 'src/app/abstraction/evolutions.facade';
import { PostEvolutionBody } from '../../../../core/services/evolutions.service';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { CategoryOfEvolution } from 'src/app/shared/models/categoryOfEvolution';
import { AlertService } from 'src/app/core/services/alert.service';
import mime from 'mime';
import { CategoriesofEvolutionsFacade } from 'src/app/abstraction/categories-of-evolutions.facade';
import { MatSelectChange } from '@angular/material/select';
import { EvolutionPdfQPS } from 'src/app/abstraction/cases.facade';
import { CasesService } from '../../../../core/services/cases.service';
import { CaseDateFilters, CasesFacade } from '../../../../abstraction/cases.facade';
import { DialogImageDetailComponent } from '../../../../shared/components/dialog-image-detail/dialog-image-detail.component';
import { DateService } from 'src/app/core/services/date.service';
import { AttentionsQPS } from 'src/app/core/services/attentions.service';
import { ProvisionsFacade } from 'src/app/abstraction/provisions.facade';
import { ProvisionAgreementFilters } from 'src/app/shared/models/ProvisionAgreementFilters';
import { FilePondComponent } from 'ngx-filepond';

enum LogoOptions {
    NO_LOGO = 1,
    COMPANY_LOGO = 2,
    CUSTOM_LOGO = 3,
    ADOMICILIO_LOGO = 4,
}

enum OutputOptions {
    UNIQUE_FILE = '1',
    BY_PRACTICE = '2',
    BY_PROVIDER_PRACTICE = '3',
}

enum OPTIONS_TO_EXPORT {
    PDF = 'pdf',
    ZIP = 'zip',
    CSV = 'csv',
}
@Component({
    selector: 'app-evolutions',
    templateUrl: './evolutions.component.html',
    styleUrls: ['./evolutions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger(
            'enterAnimation', [
            transition(
                ':enter',
                [
                    style({ height: 0, opacity: 0 }),
                    animate('0.5s ease-out',
                        style({ height: 215, opacity: 1 }))
                ]
            ),
            transition(
                ':leave',
                [
                    style({ height: 215, opacity: 1 }),
                    animate('0.7s ease-in',
                        style({ height: 0, opacity: 0 }))
                ]
            )
        ])
    ],
})

export class EvolutionsComponent implements OnInit {

    @Input() data: { name: string; surname: string; typeDocument: string, document: string; cif: number; };
    @Input() case: Case;

    // @Input() categoriesSelected;
    categoriesSelected = [];

    attentionsWithoutDayRepeat: AttentionComposedWithEvolutions[];
    evolutions: Evolution[];
    evolutionsSubscribed: Evolution[];
    EVOLUTIONS_TYPE = EvolutionsType;
    onSubmitExportFunction;
    evolutionsPdf: AttentionComposedWithEvolutions[];
    loadingConfirmEndTask;
    indexPanelOpened = -1;
    fragment: string;
    case$: Subscription;
    userSubscription: Subscription;
    user: User;
    showOptions = false;
    practices = [];
    OPTIONS_TO_EXPORT = OPTIONS_TO_EXPORT;
    objectValues = Object.values;
    _evolutionByCase: Subscription;
    public LOGO_OPTIONS = LogoOptions;
    public OUTPUT_OPTIONS = OutputOptions;
    //    exportAsPDFConfig: ExportAsConfig = { FIXME: FER
    //       type: 'pdf', // the type you want to download
    //       elementIdOrContent: 'evolutionsContainer', // the id of html/table element
    //       options: {
    //          pdfCallbackFn: this.pdfCallbackFn, // to add header and footer
    //          margins: {
    //             top: '20'
    //           }
    //       }
    //    };
    evolutionsContainerId: string;
    evolutions$: Observable<AttentionComposedWithEvolutions[]>; _evolutions: Subscription;
    attentions$: Observable<Attention[]>; _attentions: Subscription;
    caseDateFilters$: Observable<CaseDateFilters>; _caseDateFilters: Subscription;
    caseDateFilters: CaseDateFilters;
    attentions: AttentionComposedWithEvolutions[];
    attentionsPreviousFilter: AttentionComposedWithEvolutions[];
    attentionsSubscribed: AttentionComposedWithEvolutions[];
    @ViewChildren('evolutionContainer') components: QueryList<ElementRef>;

    isLoadingGettingEvolutions$: Observable<boolean>;

    filtersOnProvisions$: Observable<ProvisionAgreementFilters>;
    _filtersOnProvisions: Subscription;
    filterOnProvisions: ProvisionAgreementFilters;


    //FORM
    public readonly DATE_FROM = 'fromDate';
    public readonly DATE_TO = 'toDate';
    public readonly LOGO = 'logo';
    public readonly LOAD_FILE = 'loadFile';
    public readonly OUTPUT_TYPE = 'exportType';
    public readonly VISIBLE_PROVIDER = 'visibleProvider';
    public readonly VISIBLE_FINANCIER = 'visibleFinancier';

    exportForm: FormGroup;
    filterForm: FormGroup;

    elementsLogo = [
        {
            id: LogoOptions.NO_LOGO,
            name: 'Sin logo',
        },
        {
            id: LogoOptions.COMPANY_LOGO,
            name: 'Logo de la empresa',
        },
        {
            id: LogoOptions.CUSTOM_LOGO,
            name: 'Logo personalizado',
        }
    ];

    elementsOutput = [
        {
            id: OutputOptions.UNIQUE_FILE,
            name: 'Único archivo',
        },
        {
            id: OutputOptions.BY_PRACTICE,
            name: 'Separado por práctica',
        },
        {
            id: OutputOptions.BY_PROVIDER_PRACTICE,
            name: 'Separado por práctica y prestador',
        }
    ];
    cont: number = 0;

    // Evolution Create Dialog
    @ViewChild('addEvolutionForCase', { static: true }) addEvolutionForCase: TemplateRef<any>;
    modifyEvolutionForAttention: TemplateRef<DialogEndTaskComponent>;
    _dialogRef;
    updatingEvolution: boolean;
    evolutionToUpdate: Evolution;

    @ViewChild('myPond') myPond: FilePondComponent;
    photoEvolutionB64: any;
    loadingAddFile: boolean = false;
    evolutionForm: FormGroup;
    showVisibilityCheckbox: boolean;
    public fileExported: Subscription;
    _isLoadingEvolutionsPdf: Subscription;
    pdfSpinner$: Observable<boolean>;

    get evolutionText() {
        return this.evolutionForm.get(this.EVOLUTION_TEXT);
    }
    get evolutionType() {
        return this.evolutionForm.get(this.EVOLUTION_TYPE);
    }
    set evolutionText(value) {
        this.evolutionForm.get(this.EVOLUTION_TEXT).setValue(value);
    }
    set evolutionType(value) {
        this.evolutionForm.get(this.EVOLUTION_TYPE).setValue(value);
    }

    public readonly EVOLUTION_TEXT = 'evolution_text';
    public readonly EVOLUTION_TYPE = 'evolution_type';

    loadingConfirm = false;
    financierVisibility: boolean;
    providersVisibility: boolean;
    listEvolutionTypes: CategoryOfEvolution[];

    private defaultTextCharged: string;
    private changeTextDescription: boolean;

    pondOptions = {
        class: 'my-filepond',
        multiple: false,
        labelIdle: 'Arrastre el archivo o haga click <u>aquí</u>',
        labelFileTypeNotAllowed: 'Tipo de archivo inválido',
        fileValidateTypeLabelExpectedTypes: 'Formatos aceptados: jpg, jpeg, png, pdf',
        acceptedFileTypes: ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'],
        allowImagePreview: true,
        imagePreviewMaxHeight: 150
    };


    get dateFromForm() {
        return this.filterForm.get(this.DATE_FROM).value;
    }
    get dateToForm() {
        return this.filterForm.get(this.DATE_TO).value;
    }
    get logoForm() {
        return this.exportForm.get(this.LOGO).value;
    }
    get loadFileForm() {
        return this.exportForm.get(this.LOAD_FILE).value;
    }
    set dateFromForm(date: Date) {
        date != null ? this.filterForm.get('fromDate').setValue(date) : this.filterForm.get('fromDate').reset();
    }
    set dateToForm(date: Date) {
        date != null ? this.filterForm.get('toDate').setValue(date) : this.filterForm.get('toDate').reset();
    }
    set optionLogoForm(optionLogoId: number) {
        optionLogoId != null ? this.exportForm.controls[this.LOGO].setValue(optionLogoId.toString()) : this.exportForm.controls[this.LOGO].reset()
    }

    emptyAttentions: boolean = true;

    constructor(
        public fileUtilitiesService: FileUtilitiesService,
        public entitiesFacade: EntitiesFacade,
        private cd: ChangeDetectorRef,
        public dialog: MatDialog,
        private sanitizer: DomSanitizer,
        public router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        //   private exportAsService: ExportAsService,
        private excelService: ExcelService,
        private authService: AuthenticationService,
        private evolutionsFacade: EvolutionsFacade,
        private alertService: AlertService,
        private categoriesOfEvolutionFacade: CategoriesofEvolutionsFacade,
        private casesFacade: CasesFacade,
        private casesService: CasesService,
        private dateService: DateService,
        private provisionsFacade: ProvisionsFacade,
    ) {
        this.isLoadingGettingEvolutions$ = this.evolutionsFacade.isLoadingGetEvolutions$();
    }

    ngOnInit() {
        this.userSubscription = this.authService.getUser$().subscribe((user) => (this.user = user));
        this.filterForm = this.createFilterForm();
        this.subscribeToData();
        this.route.fragment.subscribe(fragment => { this.fragment = fragment });
        this.pdfSpinner$ = this.casesFacade.isLoadingGetEvolutionsPdf$();

    }

    ngAfterViewInit(): void {
        try {
            document.querySelector('#' + this.fragment).scrollIntoView();
        } catch (e) { }
        console.log(this.components.toArray());
    }

    ngOnDestroy(): void {
        if (!!this._evolutions) {
            this._evolutions.unsubscribe();
        }
        if (!!this._attentions) {
            this._attentions.unsubscribe();
        }
        this._caseDateFilters ? this._caseDateFilters.unsubscribe() : null;
        this._filtersOnProvisions ? this._filtersOnProvisions.unsubscribe() : null;

        this.evolutionsFacade.setAttentionsWithEvolutions(null);

    }

    subscribeToData() {
        this._caseDateFilters = this.casesFacade.getCaseDateFilters$().subscribe(filters => {
            this.caseDateFilters = filters;
            let attentionQPS: AttentionsQPS = { casesIds: [this.case.id] };
            attentionQPS.expand = 'financier,practice,provider';
            if (!!this.caseDateFilters) {
                attentionQPS.fromDate = this.caseDateFilters.historyFromDate;
                attentionQPS.toDate = this.caseDateFilters.historyToDate;
            }
            attentionQPS.page = 0;
            attentionQPS.size = 2000;

            this.evolutionsFacade.loadAttentionsWithEvolutionsByQPS(attentionQPS, this.categoriesSelected);
        })


        this.evolutions$ = this.evolutionsFacade.getAttentionsWithEvolutions$();
        this._evolutions = this.evolutions$.subscribe(evolutions => {
            this.attentions = evolutions;
            if (!!this.attentions) {
                this.attentions = this.attentions.filter(att => !!att.evolutions && att.evolutions.length > 0);
                this.attentionsPreviousFilter = evolutions;
                this.loadAttentionsAndEvolution();
                this.filterEvolutions();
                this.cd.detectChanges();
            }
        })

        this.categoriesOfEvolutionFacade.loadCategoriesOfEvolutions({ active: 1 }).subscribe();

        this.categoriesOfEvolutionFacade.getCategoriesOfEvolutions$().subscribe(
            evolutionCategories => this.listEvolutionTypes = evolutionCategories?.filter(evoCat => !evoCat.category?.includes('NOTA_'))
        );

        this.filtersOnProvisions$ = this.provisionsFacade.getFiltersOnProvisions$();
        this._filtersOnProvisions = this.filtersOnProvisions$.subscribe(filters => {
            this.filterOnProvisions = filters;
            if (!!this.attentions) {
                this.filterEvolutions()
            }
        })
    }

    filterEvolutions() {
        let attentionsLoaded;
        this.attentionsPreviousFilter = !!this.attentionsPreviousFilter ? this.attentionsPreviousFilter : [...this.attentions];
        if (!!this.filterOnProvisions && (this.filterOnProvisions?.agreements?.length > 0 || this.filterOnProvisions?.provisionFees?.length > 0)) {
            if ((!!this.filterOnProvisions?.agreements && this.filterOnProvisions?.agreements?.length > 0) && (!!this.filterOnProvisions.provisionFees == null || this.filterOnProvisions.provisionFees.length == 0)) {
                attentionsLoaded = this.attentionsPreviousFilter?.filter(att => this.filterOnProvisions?.agreements?.some(ag => ag.id == att.agreementId));
            }

            if ((!!this.filterOnProvisions?.provisionFees && this.filterOnProvisions?.provisionFees?.length > 0) && (!!this.filterOnProvisions?.agreements == null || this.filterOnProvisions?.agreements?.length == 0)) {
                attentionsLoaded = this.attentionsPreviousFilter.filter(att => this.filterOnProvisions.provisionFees.some(provFee =>
                    provFee.id == att.provisionFeeId
                    ||
                    (!!att.provisionFeeIds ? att.provisionFeeIds.includes(provFee.id) : false))); // Check if provFeeId included for filter (checkbox)
            }

            if ((!!this.filterOnProvisions.agreements && this.filterOnProvisions.agreements.length > 0) && (!!this.filterOnProvisions.provisionFees && this.filterOnProvisions.provisionFees.length > 0)) {
                attentionsLoaded = this.attentionsPreviousFilter.filter(att => this.filterOnProvisions.provisionFees.some(provFee =>
                    provFee.id == att.provisionFeeId
                    ||
                    (!!att.provisionFeeIds ? att.provisionFeeIds.includes(provFee.id) : false)) && this.filterOnProvisions.agreements.find(ag => ag.id == att.agreementId)); // Check if provFeeId included for filter (checkbox)
            }

            this.attentions = attentionsLoaded;
        } else {
            if (!!this.filterOnProvisions && this.filterOnProvisions?.orders?.length > 0) {
                this.attentions = this.attentionsPreviousFilter.filter(att => this.filterOnProvisions.orders?.some(or =>
                     or?.agreement?.id == att?.agreementId
                     ||
                    (!!att.provisionFeeIds ? or.items.some( item =>att.provisionFeeIds.includes(item.provisionFeeId)) : false) // Check if provFeeId included for filter (checkbox)
                ))
            } else {
                this.attentions = this.attentionsPreviousFilter
            }
        }
        if (!!this.categoriesSelected && this.categoriesSelected.length > 0) {
            this.attentions = this.attentions.map(att => {
                let evolutionsFiltered = att?.evolutions?.filter(ev => this.categoriesSelected?.includes(`${ev?.category?.id}`))
                return { ...att, evolutions: evolutionsFiltered }
            })
        }
        this.attentions = this.attentions?.filter(att => !!att.evolutions && att.evolutions.length > 0);
        this.loadAttentionsAndEvolution();
        this.cd.detectChanges();

    }

    setAttentionForNav() {

        this.attentionsWithoutDayRepeat = [...new Set(this.attentions)]
        for (var i = this.attentionsWithoutDayRepeat.length - 1; i >= 1; i--) {
            if (moment(this.attentionsWithoutDayRepeat[i].fromDate).isSame(this.attentionsWithoutDayRepeat[i - 1].fromDate, 'day')) {
                this.attentionsWithoutDayRepeat.splice(i, 1);
            }
        }
    }

    sortEvolutionsByAttentionDate() {
        this.attentions.sort((a, b) => {
            if (a && a.fromDate) {
                const aFromDate = moment(a.fromDate);
                const bFromDate = moment(b.fromDate);
                return aFromDate.isSame(bFromDate) ? 0 : (aFromDate.isBefore(bFromDate) ? 1 : -1);
            }
            return -1;
        });
    }

    mapAttentionEvolution() {
        this.attentions = this.attentionsSubscribed?.map(attention => {
            let evolution: Evolution[] = this.evolutionsSubscribed?.filter((evol) => attention.id && evol.attentionId === attention.id)
            attention.evolutions = evolution;
            return attention;
        }).filter(att => !!att.evolutions && att.evolutions.length > 0);

        let arrayAttentionsId = this.attentions?.map(attentions => attentions.id);

        this.evolutionsSubscribed?.filter(evolution => (!evolution.attentionId)).forEach(evol => {
            if (!this.attentions) {
                this.attentions = [];
            }
            let attention: AttentionComposedWithEvolutions = {
                fromDate: evol.date,
                caseId: evol.caseId,
                evolutions: [evol]
            }
            this.attentions.push(attention);
        });

        /* Finding evolutions for consent attentions - Must shows too */
        this.evolutionsSubscribed?.filter(evolution => !!evolution.attentionId && !arrayAttentionsId?.includes(evolution.attentionId)).forEach(evol => {
            if (!this.attentions) {
                this.attentions = [];
            }
            let attention: AttentionComposedWithEvolutions = {
                fromDate: evol.date,
                caseId: evol.caseId,
                evolutions: [evol],
                id: evol.attentionId
            }
            this.attentions.push(attention);
        });

        if (this.attentions.length > 0) {
            this.emptyAttentions = false;
        }
    }

    setCountPractices(practiceId) {
        if (this.practices.length == 0) {
            this.practices.push({ id: practiceId, count: 1 });
        } else {
            let practiceIndex = this.practices.findIndex(practice => practice.id == practiceId);
            if (practiceIndex > -1) {
                this.practices[practiceIndex].count += 1;
            } else {
                this.practices.push({ id: practiceId, count: 1 });
            }
        }
    }

    mapAttentionsPractices() {
        this.attentions.filter(attention => !!attention.id).forEach(attention => {
            if (attention?.state?.id != 6) {
                if (attention?.practiceId) {
                    this.setCountPractices(attention.practiceId);
                }
            }
        });
        this.filterPractices();
    }

    mapAttentionsPracticesToExport(attentions) {
        attentions.filter(attention => !!attention.id).forEach(attention => {
            if (attention?.state?.id != 6) {
                if (attention?.practiceId) {
                    this.setCountPractices(attention.practiceId);
                }
            }
        });
        this.filterPractices();
    }

    filterPractices() {
        this.practices.forEach(practice => {
            this.attentions.filter(attention => !!attention.id).forEach(att => {
                if (att?.practice?.id == practice.id) {
                    practice['name'] = att.practice.name;
                }
            })
        });
    }

    sanitizeUrl(url) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    openDialogImage(_image): void {
        const dialogRef = this.dialog.open(DialogImageDetailComponent, {
            width: '50%',
            data: { image: _image },
        });

        dialogRef.afterClosed().subscribe((result) => {
        });
    }

    openDialogModifyEvolution(evolution: Evolution, attention: Attention) {
        evolution.attention = attention;
        evolution.attentionId = attention.id;
        evolution.financier = attention.financier;
        let stateTask: StateTask = {
            id: TasksStates.FINISHED,
        }
        let task: Task = {
            state: stateTask,
            evolution,
        }
        this.updatingEvolution = true;
        this.evolutionToUpdate = evolution
        let dialogRef = undefined;
        this.evolutionForm = this.createEvolutionForm();
        if (!!this.updatingEvolution && this.evolutionToUpdate) {
            this.financierVisibility = this.evolutionToUpdate.visibleFinancier;
            this.providersVisibility = this.evolutionToUpdate.visibleProvider;
            if (this.evolutionToUpdate.mediaUrls) {
                this.photoEvolutionB64 = this.evolutionToUpdate.mediaUrls[0];
            }
            if (this.evolutionToUpdate.category) {
                this.evolutionType.setValue(this.evolutionToUpdate.category.id);
            }
        }
        if (attention.id) {
            /* this.openDialog(
               'Dar de alta un formulario',
               this.modifyEvolutionForAttention,
               { maxHeight: '95vh', minWidth: '65%' },
               (result) => {
                  if (result) {
                     this.onFinishTask(result);
                  }
               }
            ); */
            dialogRef = this.dialog.open(DialogEndTaskComponent, {
                width: '65%',
                maxHeight: '95vh',
                data: {
                    task,
                    modifyEvolution: true,
                },
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.onFinishTask(result);
                }
            });
        } else {
            this.openDialog(
                'Evolucionar',
                this.addEvolutionForCase,
                { maxHeight: '95vh', minWidth: '65%' },
                () => { }
            );
        }
    }

    onFinishTask(data: {
        task: Task;
        visibility: {
            financier: boolean;
            providers: boolean;
        };
        attentionState?: number;
        evolutionOperator?: PostEvolutionBody;
    }) {
        // 1- Patch a /user/evolutions/:id modificando la visibilidad
        // 2- Si attentionState!=null -> Patch a /user/attentions/:id modificando el estado
        // 3- Si todo termina bien -> Patch a /user/tasks/:id modificando el estado de la tarea a finalizada
        // const newTask: Task = {
        //    ...data.task,
        //    evolution: {
        //       ...data.task.evolution,
        //       visibleFinancier: data.visibility.financier,
        //       visibleProvider: data.visibility.providers,
        //    },
        // };

        const evolution: Evolution = {
            id: data.task.evolution.id,
            visibleProvider: data.visibility.providers,
            visibleFinancier: data.visibility.financier,
        };

        const swalWithCustomizeButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btnSwalConfirm',
                cancelButton: 'btnSwalCancel'
            },
            buttonsStyling: true
        })

        this.evolutionsFacade.patchEvolution(evolution).toPromise()
            .then((evolution) => {
            })
            .then(_ => {
                if (data.evolutionOperator) {
                    this.evolutionsFacade.postEvolution(data.evolutionOperator, false)
                }
            })
            .then(_ => this.loadingConfirmEndTask = false)
            .then(_ => {
                swalWithCustomizeButtons.fire({
                    title: `Evolución gestionada correctamente`,
                    icon: 'success'
                });
            })
            .catch(err => this.loadingConfirmEndTask = false)
    }

    onClickOptionToExport(option: string) {
        this.showVisibilityCheckbox = this.showVisibilityOptions(option);
        this.exportForm = this.createExportForm();
        this.onSubmitExportFunction = this.onSubmitExport(option);
        switch (option) {
            case OPTIONS_TO_EXPORT.CSV:
                this.onSubmitExportFunction();
                break;
            case OPTIONS_TO_EXPORT.PDF:
                this.showOptions = true
                break;
            case OPTIONS_TO_EXPORT.ZIP:
                this.showOptions = true
                break;

            default:
                console.error('INCORRECT OPTION TO EXPORT');
                break;
        }

    }

    createFilterForm(): FormGroup {
        return this.formBuilder.group({
            [this.DATE_FROM]: ['', [CustomValidators.required('Fecha requerida')]],
            [this.DATE_TO]: ['', [CustomValidators.required('Fecha requerida')]],
        })
    }

    createExportForm(): FormGroup {
        return this.formBuilder.group({
            // [this.DATE_FROM]: ['', [CustomValidators.required('Fecha requerida')]],
            // [this.DATE_TO]: ['', [CustomValidators.required('Fecha requerida')]],
            [this.LOGO]: ['', [CustomValidators.required('Estado requerido')]],
            [this.LOAD_FILE]: [''],
            [this.OUTPUT_TYPE]: [OutputOptions.UNIQUE_FILE],
            [this.VISIBLE_FINANCIER]: [false],
            [this.VISIBLE_PROVIDER]: [false],
        }, {
            validators: [
                CustomValidators.CVFromToDate('La fecha de finalización debe ser superior a la de inicio', 'errorDate', this.DATE_FROM, this.DATE_TO),
                CustomValidators.requiredDependingOnControlValue('El logo es requerido', 'errorDepending', this.LOAD_FILE, this.LOGO, LogoOptions.CUSTOM_LOGO)
            ]
        });
    }

    calculateIdlogo(element) {
        return element ? element.id : null;
    }

    calculateNameLogo(element) {
        return element ? element.name : null;
    }

    calculateIdOutput(element) {
        return element ? element.id : null;
    }

    calculateNameOutput(element) {
        return element ? element.name : null;
    }

    onSubmitExport(option: string) {
        return () => {
            switch (option) {
                case OPTIONS_TO_EXPORT.PDF: {
                    this.exportAsPdf();
                    break;
                }
                case OPTIONS_TO_EXPORT.ZIP: {
                    this.evolutionsPdf = this.filterAndSortAttentionsToExport();
                    this.exportAsZip()
                    break;
                }
                case OPTIONS_TO_EXPORT.CSV: {
                    this.exportAsXLSX();
                    break;
                }

                default:
                    break;
            }
        }
        // Obtener las imagenes de las evoluciones
    }

    exportAsPdf() {
        let evolutionsPdfQPS: EvolutionPdfQPS = {
            caseId: this.case.id,
            financierId: this.case.financier.id,
        }
        let output = this.exportForm.get(this.OUTPUT_TYPE).value;
        switch (output) {
            case this.OUTPUT_OPTIONS.UNIQUE_FILE:
                evolutionsPdfQPS.groupByPractice = false;
                evolutionsPdfQPS.groupByPracticeAndProvider = false;
                break;
            case this.OUTPUT_OPTIONS.BY_PRACTICE:
                evolutionsPdfQPS.groupByPractice = true;
                evolutionsPdfQPS.groupByPracticeAndProvider = false;
                break;
            case this.OUTPUT_OPTIONS.BY_PROVIDER_PRACTICE:
                evolutionsPdfQPS.groupByPractice = false;
                evolutionsPdfQPS.groupByPracticeAndProvider = true;
                break;
        }
        let logo = this.exportForm.get(this.LOGO).value;
        switch (Number(logo)) {
            case this.LOGO_OPTIONS.NO_LOGO:
                evolutionsPdfQPS.isWithTenantLogo = false;
                evolutionsPdfQPS.image = null;
                break;
            case this.LOGO_OPTIONS.CUSTOM_LOGO:
                evolutionsPdfQPS.isWithTenantLogo = false;
                evolutionsPdfQPS.image = this.loadFileForm;
                break;
            case this.LOGO_OPTIONS.COMPANY_LOGO:
                evolutionsPdfQPS.isWithTenantLogo = true;
                evolutionsPdfQPS.image = null;
                break;
        }
        evolutionsPdfQPS.fromDate = moment(this.caseDateFilters.historyFromDate).format('YYYY-MM-DD');
        evolutionsPdfQPS.toDate = moment(this.caseDateFilters.historyToDate).format('YYYY-MM-DD');
        evolutionsPdfQPS.isHideFromFinancier = this.exportForm.get(this.VISIBLE_FINANCIER).value;
        evolutionsPdfQPS.isHideFromProvider = this.exportForm.get(this.VISIBLE_PROVIDER).value;

        if (!!this.filterOnProvisions) {
            if (!!this.filterOnProvisions.agreements && this.filterOnProvisions.agreements.length > 0) {
                evolutionsPdfQPS.agreementsIds = this.filterOnProvisions.agreements.map(agr => agr.id);
            }

            if (!!this.filterOnProvisions.provisionFees && this.filterOnProvisions.provisionFees.length > 0) {
                evolutionsPdfQPS.provisionFeeIds = this.filterOnProvisions.provisionFees.map(provFee => provFee.id);
            }
        }

        if (!!this.categoriesSelected && this.categoriesSelected.length > 0) {
            evolutionsPdfQPS.categoriesIds = this.categoriesSelected;
        }

        evolutionsPdfQPS.onlyCover = false;

        this.casesFacade.loadgetEvolutionsPDF(evolutionsPdfQPS).subscribe((files => {
            for (let file of files) {
                this.downloadPdf(file, 'evoluciones_adomicilio_' + new Date().toISOString());
            }
        }))

    }

    downloadPdf(base64String, fileName) {
        const source = base64String;
        const link = document.createElement("a");
        link.href = source;
        link.download = `${fileName}.pdf`
        link.click();
    }

    showVisibilityOptions(option: string) {
        return ((option == OPTIONS_TO_EXPORT.PDF) || (option == OPTIONS_TO_EXPORT.ZIP));
    }

    filterAndSortAttentions(): AttentionComposedWithEvolutions[] {

        this.filterEvolutions();
        let attentionsToFilter = this.attentions;

        this.practices = [];
        this.mapAttentionsPracticesToExport(attentionsToFilter);
        console.log("Evolutions filtered: ", attentionsToFilter);
        return attentionsToFilter;
    }

    private filterAndSortAttentionsToExport(): AttentionComposedWithEvolutions[] {
        /**
         * Spread operator makes a shallow copy of data, so if i wanna change a property of the copy of a attention then the change
         * will be reflected on the original array. This problem has solved with the next map of original array with spread operator
         * for each one element.
         */
        let finalAttentionToExport: AttentionComposedWithEvolutions[] = this.attentions.filter(attention => (!!attention.id)).map(a => { return { ...a } });
        if (!this.exportForm.get(this.VISIBLE_PROVIDER).value) {
            finalAttentionToExport.forEach(att => {
                att.evolutions = [...att.evolutions.filter(evol => !!evol.visibleProvider)];
            })
        }
        if (!this.exportForm.get(this.VISIBLE_FINANCIER).value) {
            finalAttentionToExport.forEach(att => {
                att.evolutions = [...att.evolutions.filter(evol => !!evol.visibleFinancier)];
            })
        }
        finalAttentionToExport = [...finalAttentionToExport.filter(att => !!att.evolutions && att.evolutions.length > 0)];

        this.practices = [];
        this.mapAttentionsPracticesToExport(finalAttentionToExport);
        return finalAttentionToExport;
    }

    exportAsZip() {
        let files: { ext: string, data: string, title: string, date: string }[] = [];
        // TODO: Renombrar evolutionsPdf a attentionsPdf
        this.evolutionsPdf.forEach((attention: Attention, indexAttention) => attention.evolutions.forEach((evolution: Evolution, indexEvolution) => {
            // TODO: hardcodeado con [0] -> No aceptará multiples archivos
            if (!!evolution.media && !!evolution.media.mediaUrls) {
                evolution.media.mediaUrls.forEach(mediaUrl => {
                    const file = mediaUrl;
                    if (!!file) {
                        const ext = this.fileUtilitiesService.getExtension(file);
                        const data = this.fileUtilitiesService.getContent(file);
                        // Busco archivos con la misma fecha de evolución (para poner el numerado luego de la fecha)
                        const sameFiles = files.filter(file => file.date == moment(evolution.date).format('DD-MM-YYYY'))
                        const title = `${moment(evolution.date).format('DD-MM-YYYY')} - ${sameFiles.length + 1}`;
                        this.evolutionsPdf[indexAttention].evolutions[indexEvolution]['fileNamePdf'] = title;
                        files.push({ ext, data, title, date: moment(evolution.date).format('DD-MM-YYYY') });
                    }
                });
            }
        }))
        //   this.exportAsService.get(this.exportAsPDFConfig).subscribe(content => {
        //      const cont = this.fileUtilitiesService.getContent(content);
        //      this.fileUtilitiesService.downloadZip({ data: cont, ext: 'pdf' }, files, 'evoluciones_adomicilio_', 'Evoluciones')
        //   }); FIXME: FER
    }

    exportAsXLSX(): void {
        this.showOptions = false;
        const evolutionsExcel = this.evolutionsToExcelFormat(this.attentions, this.data.name, this.data.surname, this.data.document, this.data.cif);
        //   this.excelService.exportAsExcelFile(evolutionsExcel, 'evoluciones'); FIXME: FER
    }

    pdfCallbackFn(pdf: any) {
        // example to add page number as footer to every page of pdf
        const noOfPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= noOfPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10)
            pdf.text('Página ' + i + ' de ' + noOfPages, pdf.internal.pageSize.getWidth() - 35, pdf.internal.pageSize.getHeight() - 10);
        }
    }

    evolutionsToExcelFormat(attentions: AttentionComposedWithEvolutions[], patientName: string, patientSurname: string, patientDocument: string, cif: number): EvolutionExcel[] {
        let evolutionsExcel: EvolutionExcel[] = [];
        attentions.forEach(attention => {
            evolutionsExcel = evolutionsExcel.concat(attention.evolutions.map(
                evolution => {
                    let dateEvolution = moment(evolution.date).format("D/M/YYYY");
                    let dateAttention = moment(attention.fromDate).format("D/M/YYYY");
                    return {
                        FechaDeAtención: dateAttention,
                        Id: evolution.id,
                        CIF: cif ? cif : null,
                        FechaDeEvolución: dateEvolution,
                        Nombre: patientName,
                        Apellido: patientSurname,
                        Documento: patientDocument,
                        Texto: evolution.text,
                        Tipo: evolution.category.description,
                    }
                }
            ));

        });
        return evolutionsExcel;

    }

    getDateNow() {
        return new Date();
    }

    getProviderString(evolution: Evolution) {
        let providerString = 'Sin datos';
        /*       if (!!evolution.operator && !!evolution.operator.name) {
                 providerString = evolution.operator.name + (this.entitiesFacade.getProvidersFullName(evolution.provider) ? ` <span class="text-secondary">(en nombre de ${this.entitiesFacade.getProvidersFullName(evolution.provider)})</span>` : '');
              } else {
                 if (!!evolution.provider && !!this.entitiesFacade.getProvidersFullName(evolution.provider) && !!evolution.provider.specialties) { */
        let medicalLicencesToShow = '';
        let medicalLicences = [];
        evolution.provider.specialties.forEach(specialty => specialty.medicalLicenses.forEach(ml => medicalLicences.push(ml.value)));
        let medicalLicencesWithoutDuplicates = [...new Set(medicalLicences)];
        medicalLicencesWithoutDuplicates.forEach(medicalLicence => {
            medicalLicencesToShow += `${medicalLicence} `;
        })
        providerString = this.entitiesFacade.getProvidersFullName(evolution.provider) + (medicalLicencesToShow.length > 0 ? ` • <span class="text-secondary">Mat.: ${medicalLicencesToShow} </span>` : '');
        //}
        //}
        return providerString;
    }

    openCreateEvolution() {
        this.updatingEvolution = false;
        this.evolutionToUpdate = null;
        this.evolutionForm = this.createEvolutionForm();
        this.openDialog(
            'Evolucionar',
            this.addEvolutionForCase,
            { maxHeight: '95vh', minWidth: '65%' },
            () => { }
        );
    }

    createEvolutionForm(): FormGroup {
        if (this.updatingEvolution) {
            return this.formBuilder.group({
                [this.EVOLUTION_TEXT]: [(this.evolutionToUpdate.text) ? this.evolutionToUpdate.text : '', [CustomValidators.required('Texto requerido'), CustomValidators.minLength(10, 'La evolución debe tener al menos 10 caracteres')]],
                [this.EVOLUTION_TYPE]: [(this.evolutionToUpdate.category) ? this.evolutionToUpdate.category.id : '', [CustomValidators.required('Tipo requerido')]],
            });
        } else {
            return this.formBuilder.group({
                [this.EVOLUTION_TEXT]: ['', [CustomValidators.required('Texto requerido'), CustomValidators.minLength(10, 'La evolución debe tener al menos 10 caracteres')]],
                [this.EVOLUTION_TYPE]: ['', [CustomValidators.required('Tipo requerido')]],
            });
        }
    }

    openDialog(title: string, template, style: { minWidth: string, maxHeight: string }, afterClosed?): void {
        this._dialogRef = this.dialog.open(DialogComponent, {
            disableClose: true,
            minWidth: style.minWidth,
            maxHeight: style.maxHeight,
            data: { template, title },
        });
        this._dialogRef.afterClosed().subscribe(() => afterClosed());
    }

    getDate(evolution: Evolution): Date {
        if (evolution.attentionId) {
            let attention: Attention = this.getAttentionById(evolution.attentionId);
            return (attention?.fromDate) ? attention?.fromDate : undefined;
        } else {
            return (evolution.date) ? evolution.date : undefined;
        }
    }

    getAttentionById(id: number): Attention {
        return this.attentions.find(att => att.id == id);
    }

    loadAttentionsAndEvolution() {
        this.sortEvolutionsByAttentionDate();
        this.setAttentionForNav();
        this.mapAttentionsPractices();
    }

    onCancelClick() {
        this._dialogRef.close();
    }

    onConfirmClick() {
        this.loadingConfirm = true;
        let postEvolutionObject: PostEvolutionBody;
        if (!!this.updatingEvolution) {
            postEvolutionObject = {
                caseId: this.case.id,
                text: this.evolutionText.value,
                type: EvolutionsType.NORMAL,
                categoryId: this.evolutionType.value,
                date: new Date(),
                mediaUrls: [this.photoEvolutionB64],
                visibleFinancier: this.financierVisibility,
                visibleProvider: this.providersVisibility,
                referencedEvolution: this.evolutionToUpdate.id
            };
        } else {
            postEvolutionObject = {
                caseId: this.case.id,
                text: this.evolutionText.value,
                type: EvolutionsType.NORMAL,
                categoryId: this.evolutionType.value,
                date: new Date(),
                mediaUrls: [this.photoEvolutionB64],
                visibleFinancier: this.financierVisibility,
                visibleProvider: this.providersVisibility,
            };
        }
        console.log(JSON.stringify(postEvolutionObject));
        this.postEvolution(postEvolutionObject);
    }

    postEvolution(evolution) {
        this.evolutionsFacade.postEvolution(evolution, true).subscribe(
            (evolucion) => {
                this.loadingConfirm = false;
                this._dialogRef.close(evolucion);
                this.filterEvolutions();
            },
            (error) => {
                this.loadingConfirm = false;
                this.alertService.openError(error.message)
            }
        );
    }

    pondHandleInit() {
        console.log('Inicio la cargaaa');
    }

    pondHandleAddFile(event: any) {
        if (!event.error) {
            const b64 = event.file.getFileEncodeBase64String();
            const extension = event.file.fileExtension;
            const mimeType = mime.getType(extension);;
            this.photoEvolutionB64 = `data:${mimeType};base64,${b64}`;
        } else {
            this.photoEvolutionB64 = null;
        }
        this.loadingAddFile = false;
    }

    pondHandleRemoveFile(event) {
        this.photoEvolutionB64 = null;
    }

    pondHandleProcessFile(event) {
        this.loadingAddFile = true;
    }

    calculateId(element: CategoryOfEvolution) {
        return element ? element.id : null;
    }

    calculateName(element: CategoryOfEvolution) {
        return element ? element.description : null;
    }

    onSelectCategory(id: number) {
        let categorySelected: CategoryOfEvolution = this.listEvolutionTypes.find(category => category.id == id);
        if (categorySelected) {
            this.financierVisibility = (categorySelected.visibleFinancier && categorySelected.visibleFinancier == 1) ? true : false;
            this.providersVisibility = (categorySelected.visibleProvider && categorySelected.visibleProvider == 1) ? true : false;
            if (!this.changeTextDescription) {
                if (categorySelected.defaultText && this.defaultTextCharged !== categorySelected.defaultText) {
                    this.evolutionText.setValue(categorySelected.defaultText);
                    this.defaultTextCharged = categorySelected.defaultText;
                } else {
                    this.evolutionText.setValue("");
                }
            }
        }
    }

    onChangeDescription(event) {
        let categorySelected: CategoryOfEvolution = this.listEvolutionTypes.find(category => category.id == this.evolutionType.value);
        if (categorySelected && event.target.value != '') {
            this.changeTextDescription = (categorySelected.defaultText != event.target.value);
        } else {
            if (event.target.value == '') {
                this.changeTextDescription = false;
            } else {
                this.changeTextDescription = (!categorySelected);
            }
        }
    }

    selectOption(option: MatSelectChange) {
        this.categoriesSelected = [...option.value];
    }

    removeFilter() {
        this.categoriesSelected = [];
        this.attentions = !!this.attentionsPreviousFilter ? [...this.attentionsPreviousFilter] : this.attentions;
        this.dateToForm = null;
        this.dateFromForm = null;
        this.filterEvolutions();
        this.setAttentionForNav();
    }

    onClickFilter() {
        this.attentionsPreviousFilter = !this.attentionsPreviousFilter ? [...this.attentions] : this.attentionsPreviousFilter;
        if (!!this.dateFromForm || !!this.categoriesSelected) {
            this.attentions = [...this.attentionsPreviousFilter];
            if (!!this.dateFromForm && !this.dateToForm) {
                this.dateToForm = moment().toDate();
            }
            this.attentions = this.filterAndSortAttentions();
            this.setAttentionForNav();
        }
    }

    getLabelDate() {
        return this.dateService.getSameFormatDateToDateRange(this.caseDateFilters.historyFromDate, this.caseDateFilters.historyToDate);
    }
}
