<div class="flex-column">
    <span class="headline-1 pls-4 mx-2 mbs-5 border-left text-primary-darker-title">
        {{ titleComponent | uppercase }}
    </span>
    <div class="flex-column">
        <div class="flex-column border-filters pb-2">
            <!-- Filters -->
            <form class="flex-100 flex-column mts-3" *ngIf="!(this.isLoadingGettingFinanciers) && !!this.filterForm && !!this.financiers" [formGroup]="filterForm">

                <!-- Top filters -->
                <div class="flex-row align-space-evenly-center">
                    <div class="flex-column flex-20">
                        <span class="subtitle-4 text-primary-darker">
                            <b>Financiador</b>
                        </span>
                        <app-select-searching-input [name]="this.FINANCIER" [nameSearching]="this.FINANCIER_SEARCHING"
                            [form]="this.filterForm" [placeholder]="'Seleccionar'" [elements]="(this.financiers)"
                            [calculateId]="this.calculateIdFinancier" [calculateName]="this.calculateNameFinancier"
                            [placeholderNoValue]="'Sin resultados'" [placeholderSearching]="'Buscar...'"
                            (selected)="onSelectFinancier($event)" [noOptionText]="'-- Todos --'"
                            [defaultValue]="this.metaDataCases?.financierId ? this.metaDataCases?.financierId:null">
                        </app-select-searching-input>
                    </div>
                    <div class="flex-column flex-20">
                        <span class="subtitle-4 text-primary-darker">
                            <b>Tipo de empresa</b>
                        </span>
                        <app-select-searching-input [name]="this.COMPANY_TYPE"
                            [nameSearching]="this.COMPANY_TYPE_SEARCHING" [form]="this.filterForm" [defaultValue]="null"
                            [placeholder]="'Seleccionar'" [elements]="(this.companyTypesList)"
                            [calculateId]="this.calculateIdCompanyType" [calculateName]="this.calculateNameCompanyType"
                            [placeholderNoValue]="'Sin resultados'" [placeholderSearching]="'Buscar...'"
                            [disabled]="isDisabledCompanyType" [noOptionText]="'-- Todos --'">
                        </app-select-searching-input>
                    </div>
                    <div class="flex-column flex-20">
                        <span class="subtitle-4 text-primary-darker">
                            <b>Operador</b>
                        </span>
                        <!-- [defaultValue]="!!this.metaDataCases && this.metaDataCases.operatorId ? this.metaDataCases.operatorId : this.user.id" -->
                        <app-select-searching-input [name]="this.OPERATOR" [nameSearching]="this.OPERATOR_SEARCHING"
                            [form]="this.filterForm"
                            [placeholder]="'Seleccionar'" [elements]="this.operators"
                            [defaultValue]="!!this.operator ? this.operator : null"
                            [calculateId]="this.calculateIdOperator" [calculateName]="this.calculateNameOperator"
                            [placeholderNoValue]="'Sin resultados'" [placeholderSearching]="'Buscar...'"
                            [noOptionText]="'-- Todos --'">
                        </app-select-searching-input>
                    </div>
                    <div class="flex-column flex-20">
                        <span class="subtitle-4 text-primary-darker">
                            <b>Hasta</b> ( {{this.getPeriod()}} )
                        </span>
                        <div (keydown)="handleKeyDown($event)">
                            <app-date-picker #datePicker [name]="DATE_TO" [form]="filterForm">
                            </app-date-picker>
                          </div>
                    </div>
                </div>

                <!-- <div class="flex-row flex-wrap align-center-start flex-20" style="font-size: 12px !important">
                </div> -->

                <!-- Filter buttons -->
                <div class="flex-row align-center-center gap-5em">
                    <div class="end-center">
                        <button mat-stroked-button [color]="'primary'" (click)="this.resetFilters()">BORRAR FILTROS</button>
                    </div>
                    <div class="align-start-center">
                        <button mat-raised-button [color]="'primary'" (click)="onFilterV2FromButton(true, 'v2')">FILTRAR</button>
                    </div>
                    <!-- <div class="align-start-center">
                        <button mat-raised-button [color]="'primary'" *ngIf="!environment.production || (environment.production && this.user.id == 100000633)" (click)="onFilterV2FromButton(true, 'v2')">FILTRAR v2</button>
                    </div> -->
                </div>
            </form>
        </div>

        <!-- Center cards -->
        <div class="flex-row align-stretch-stretch border-indicators"> <!-- Comment: style="height: 36vh;" -->
            <!-- First card -->
            <div class="flex-column align-center-center border-right" style="flex: 0.5"
                (click)="!this.isLoadingProvisionalControlIndicators ? this.onClickCardIndicator(this.casesStates[1].name) : null"
                [ngStyle]="{'cursor': this.isLoadingProvisionalControlIndicators ? 'default' : 'pointer'}">
                <span class="mat-display-1 number-font big-number mb-0 mxs-2 label-2"
                    *ngIf="!this.isLoadingProvisionalControlIndicators; else loadingIndicators">
                    {{ counterTotalIndicator }}
                </span>
                <span class="text-primary-darker subtitle-1">TOTAL CASOS</span>
                <span class="text-primary subtitle-1">ABIERTOS</span>
            </div>
            <!-- Second card -->
            <div class="flex-column align-space-between-stretch bg-primary-lighter border-right px-16" style="flex: 1; border-radius: 0 0 0 6px;">
                <div class="flex-column align-space-between-stretch" style="height: 100%;">
                    <span class="subtitle flex align-center-start">Casos abiertos y cerrados con actividad del {{ this.getPeriod() }}</span>
                    <div class="flex align-center-stretch" style="flex: 1 1 100%;box-sizing: border-box;place-content: center;align-items: center;flex-direction: row;max-height: 45%;" *ngFor="let indicator of this.caseIndicators">
                        <mat-card class="indicators-totals" style="white-space: nowrap; cursor: pointer;"
                            (click)="!this.isLoadingProvisionalControlIndicators ? this.onClickCardIndicator(indicator.indicatorState) : null"
                            [ngStyle]="{'cursor': this.isLoadingProvisionalControlIndicators ? 'default' : 'pointer'}">
                            <mat-card-content style="height: 100%;">
                                <div class="flex-row align-start-center" style="height: 100% !important;
                                flex-direction: row;
                                box-sizing: border-box;
                                display: flex;
                                place-content: center;
                                align-items: stretch; width: 100%;">
                                    <span class="mat-display-1 number-font medium-number mb-0 mxs-2"
                                        *ngIf="!this.isLoadingProvisionalControlIndicators; else loadingIndicators"
                                        [ngClass]="indicator.color">
                                        {{ indicator.counter }}
                                    </span>
                                    <div class="flex-column align-center-start">
                                        <span class="text-primary-darker subtitle-2">{{indicator.name}}</span>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>

                <ng-template #loadingIndicators>
                    <mat-spinner class="mr-3" color="primary" diameter="25">
                    </mat-spinner>
                </ng-template>
            </div>
            <!-- Third card -->
            <div class="flex-column align-space-between-stretch border-right px-16" style="flex: 1;">
                <span class="subtitle flex align-center-center">Prácticas con actividad del {{ this.getPeriod() }}</span>
                <div class="flex-column align-space-between-none">
                    <!-- Sub-card 1 -->
                    <div class="align-center-center">
                        <mat-card class="indicators-totals my-2"
                            (click)="!this.isLoadingProvisionFeesIndicators ? loadProvisionFees(provisionalControlProvisionFeeStates.ALL) : null"
                            [ngStyle]="{'cursor': this.isLoadingProvisionalControlIndicators ? 'default' : 'pointer'}">
                            <mat-card-content>
                                <div class="flex-row align-start-center">
                                    <span class="mat-display-1 number-font medium-number mb-0 mxs-2"
                                        *ngIf="!this.isLoadingProvisionFeesIndicators; else loadingIndicators">
                                        {{ counterProvisionFeesTotals }}
                                    </span>
                                    <span class="text-primary-darker subtitle-1">Total prácticas</span>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <!-- Sub-card 2 -->
                    <div class="align-center-center">
                        <mat-card class="shadow-lg my-2" style="white-space: nowrap; cursor: pointer"
                            (click)="!this.isLoadingProvisionFeesIndicators ? this.loadProvisionFees(provisionalControlProvisionFeeStates.NOT_AUTHORIZED) : null"
                            [ngStyle]="{'cursor': this.isLoadingProvisionalControlIndicators ? 'default' : 'pointer'}">
                            <mat-card-content>
                                <div class="flex-row align-start-center">
                                    <span class="mat-display-1 number-font small-number mb-0 label-1 mxs-2"
                                        style="flex: 0 1 20%"
                                        *ngIf="!this.isLoadingProvisionFeesIndicators; else loadingIndicators">
                                        {{ counterProvisionFeesNotAuthorized }}
                                    </span>
                                    <span class="text-primary-darker">No autorizadas</span>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <!-- Sub-card 3 -->
                    <div class="align-center-center">
                        <mat-card class="shadow-lg my-2" style="white-space: nowrap; cursor: pointer"
                            (click)="!this.isLoadingProvisionFeesIndicators ? this.loadProvisionFees(provisionalControlProvisionFeeStates.WITHOUT_SCHEDULE) : null"
                            [ngStyle]="{'cursor': this.isLoadingProvisionalControlIndicators ? 'default' : 'pointer'}">
                            <mat-card-content>
                                <div class="flex-row align-start-center">
                                    <span class="mat-display-1 number-font small-number mb-0 label-1 mxs-2"
                                        style="flex: 0 1 20%"
                                        *ngIf="!this.isLoadingProvisionFeesIndicators; else loadingIndicators">
                                        {{ counterProvisionFeesWithoutSchedule }}
                                    </span>
                                    <span class="text-primary-darker">Sin agenda</span>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <!-- Sub-card 4 -->
                    <div class="align-center-center">
                        <mat-card class="shadow-lg my-2" style="white-space: nowrap; cursor: pointer"
                            (click)="!this.isLoadingProvisionFeesIndicators ? this.loadProvisionFees(provisionalControlProvisionFeeStates.BUDGETED) : null"
                            [ngStyle]="{'cursor': this.isLoadingProvisionalControlIndicators ? 'default' : 'pointer'}">
                            <mat-card-content>
                                <div class="flex-row align-start-center">
                                    <span class="mat-display-1 number-font small-number mb-0 label-1 mxs-2"
                                        style="flex: 0 1 20%"
                                        *ngIf="!this.isLoadingProvisionalControlIndicators; else loadingIndicators">
                                        {{ counterProvisionFeesBudgeted }}
                                    </span>
                                    <span class="text-primary-darker">Presupuestadas</span>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>
            <!-- Fourth card -->
            <div class="flex-column align-space-between-stretch px-16" style="flex: 1">
                <span class="subtitle flex align-center-center">Insumos con actividad del {{ this.getPeriod() }}</span>
                <div class="flex-column align-space-between-none">
                    <!-- Sub-card 1 -->
                    <div class="align-center-center">
                        <mat-card class="indicators-totals my-2"
                            (click)="!this.isLoadingOrderIndicators ? loadOrders(this.provisionalControlOrderStates.ALL) : null"
                            [ngStyle]="{'cursor': this.isLoadingProvisionalControlIndicators ? 'default' : 'pointer'}">
                            <mat-card-content>
                                <div class="flex-row align-start-center">
                                    <span class="mat-display-1 number-font medium-number mb-0 mxs-2"
                                        *ngIf="!this.isLoadingOrderIndicators; else loadingIndicators">
                                        {{ counterTotalOrders }}
                                    </span>
                                    <span class="text-primary-darker subtitle-1">Total pedidos</span>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <!-- Sub-card 2 -->
                    <div class="align-center-center">
                        <mat-card class="shadow-lg my-2" style="white-space: nowrap; cursor: pointer"
                            (click)="!this.isLoadingProvisionalControlIndicators ? onClickCardIndicator(provisionalControlIndicatorsStates.CON_INSUMOS_NO_PEDIDOS) : null"
                            [ngStyle]="{'cursor': this.isLoadingProvisionalControlIndicators ? 'default' : 'pointer'}">
                            <mat-card-content>
                                <div class="flex-row align-start-center">
                                    <span class="mat-display-1 number-font small-number mb-0 label-1 mxs-2"
                                        *ngIf="!this.isLoadingProvisionalControlIndicators; else loadingIndicators"
                                        [style.color]="this.colorInformableIndicator">
                                        {{ counterCasesWithNotOrderedSupplies }}
                                    </span>
                                    <div class="flex-column align-start-center">
                                        <span class="text-primary-darker">Casos con insumos no pedidos</span>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <!-- Sub-card 3 -->
                    <div class="align-center-center">
                        <mat-card class="shadow-lg my-2" style="white-space: nowrap; cursor: pointer"
                            (click)="!this.isLoadingOrderIndicators ? this.loadOrders(provisionalControlOrderStates.WITH_NOT_AUTHORIZED_SUPPLIES) : null"
                            [ngStyle]="{'cursor': this.isLoadingProvisionalControlIndicators ? 'default' : 'pointer'}">
                            <mat-card-content>
                                <div class="flex-row align-start-center">
                                    <span class="mat-display-1 number-font small-number mb-0 label-1 mxs-2"
                                        *ngIf="!this.isLoadingOrderIndicators; else loadingIndicators">
                                        {{ counterOrdersWithNotAuthorizedSupplies }}
                                    </span>
                                    <span class="text-primary-darker">Pedidos con insumos no autorizados</span>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <!-- Sub-card 4 -->
                    <div class="align-center-center">
                        <mat-card class="shadow-lg my-2" style="white-space: nowrap; cursor: pointer"
                            (click)="!this.isLoadingOrderIndicators ? this.loadOrders(provisionalControlOrderStates.NOT_DELIVERED) : null"
                            [ngStyle]="{'cursor': this.isLoadingProvisionalControlIndicators ? 'default' : 'pointer'}">
                            <mat-card-content>
                                <div class="flex-row align-start-center">
                                    <span class="mat-display-1 number-font small-number mb-0 label-1 mxs-2"
                                        *ngIf="!this.isLoadingOrderIndicators; else loadingIndicators">
                                        {{ counterOrdersNotDelivered }}
                                    </span>
                                    <span class="text-primary-darker">Pedidos no entregados</span>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-row flex-100 border-table-cases">
            <div class="example-loading-shade"
                *ngIf="isLoadingGettingCases || isLoadingGettingOrders || isLoadingGettingProvisionFees">
                <app-spinner></app-spinner>
            </div>
            <div class="flex-100 flex-column">
                <div class="flex-column">
                    <div class="flex-row flex-100 flex-gap-1rem pt-1 px-1">
                        <span class="flex-10 mxs-1 subtitle-3 pb-2 text-ellipsis"
                            *ngIf="!!this.financier && !!this.metaDataCases?.financierId && !!this.dateTo">
                            Estado de casos con actividades previas al {{(this.dateTo | date: 'dd/MM/yyyy')}} de
                            {{this.getActualFinancierName(financiers) | titlecase}}
                        </span>
                        <span class="flex-20 mxs-1 subtitle-3 pb-2 text-ellipsis"
                            *ngIf="!!this.financier && !!this.metaDataCases?.financierId && !this.dateTo">
                            Estado de casos con actividades de {{this.getActualFinancierName(financiers) |
                            titlecase}}
                        </span>
                        <span class="flex-20 mxs-1 subtitle-3 pb-2 text-ellipsis"
                            *ngIf="(!this.financier || !this.metaDataCases?.financierId) && !!this.dateTo">
                            Estado de casos con actividades previas al {{(this.dateTo | date: 'dd/MM/yyyy')}}
                        </span>
                        <mat-form-field appearance="outline" class="flex-row flex-50 mls-1">
                            <mat-label>
                                Ingrese nombre, apellido, DNI o CIF del paciente
                            </mat-label>
                            <input matInput #inputSearch />
                        </mat-form-field>
                        <!-- Casos, Pedidos, Prácticas -->
                        <app-select-input [elements]="this.tableOptions" [name]="this.PROVISIONAL_OPTIONS"
                            [form]="this.filterForm" style="flex: 1 1 20%" [placeholder]="'Filtrar por indicador'"
                            [calculateId]="calculateIdTableOptions" [calculateName]="calculateNameTableOptions"
                            [defaultValue]="this.optionSelected.toString()" (selected)="onSelectTableOption()">
                        </app-select-input>
                        <!-- Todos, Abiertos, Cerrados, Rechazados -->
                         @if(this.optionSelected === ProvisionalControlTableOptions.CASES) {
                             <app-select-input [elements]="this.casesStates" [name]="this.CASES_STATES"
                                 [form]="this.filterForm"
                                 style="flex: 1 1 20%" [placeholder]="'Filtrar por indicador'"
                                 [calculateId]="calculateIdCaseState" [calculateName]="calculateNameCaseState"
                                 [defaultValue]="" (selected)="onFilterV2(false, this.globalVersion)">
                             </app-select-input>
                         }
                        <!-- Todos, Operables, Informables, Con insumos..., Informados -->
                         @if(this.optionSelected === ProvisionalControlTableOptions.CASES) {
                            <app-select-input [elements]="this.filteredProvisionalControlIndicators" [name]="this.PROVISIONAL_STATE"
                                [form]="this.filterForm" style="flex: 1 1 20%" [placeholder]="'Filtrar por indicador'"
                                [calculateId]="calculateIdIndicatorState" [calculateName]="calculateNameIndicatorState"
                                [defaultValue]="this.indicatorStateSelected?.id.toString()"
                                (selected)="onSelectIndicatorFilter($event)">
                            </app-select-input>
                        }
                        <!-- Orders -->
                        @if(this.optionSelected === ProvisionalControlTableOptions.ORDERS) {
                            <app-select-input [elements]="this.orderStates" [name]="this.ORDER_STATES"
                                [form]="this.filterForm" style="flex: 1 1 20%" [placeholder]="'Filtrar por indicador'"
                                [calculateId]="calculateIdIndicatorState" [calculateName]="calculateNameIndicatorState"
                                [defaultValue]="" (selected)="loadOrders($event)">
                            </app-select-input>
                        }
                        <!-- ProvisionFees -->
                        @if(this.optionSelected === ProvisionalControlTableOptions.PROVISIONFEES) {
                            <app-select-input [elements]="this.provisionFeeStates" [name]="this.PROVISIONFEE_STATES"
                                [form]="this.filterForm" style="flex: 1 1 20%" [placeholder]="'Filtrar por indicador'"
                                [calculateId]="calculateIdIndicatorState" [calculateName]="calculateNameIndicatorState"
                                [defaultValue]="" (selected)="loadProvisionFees($event)">
                            </app-select-input>
                        }
                    </div>
                    <!-- [ngClass.lt-sm]="{ 'cases-container': true }" -->
                    <div class="table-container mt-1">
                        <!-- Deleted because order by observable matSortDirection="asc" -->
                        <!-- [ngClass.lt-md]="{ 'cases-table': true }" -->
                        <table mat-table class="mat-elevation-z0" *ngIf="optionSelected == ProvisionalControlTableOptions.CASES" [dataSource]="dataSource"
                            matSort matSortActive="patient" (matSortChange)="onSortEvent($event)" matSortDisableClear>
                            <!-- financier Column -->
                            <ng-container matColumnDef="financier" sticky>
                                <th mat-header-cell *matHeaderCellDef class="bg-primary-lighter">Financiador / CIF</th>
                                <td mat-cell *matCellDef="let case">
                                    <div class="inner-cell">
                                        <div class="flex-column"> <span
                                                [matTooltip]="!!case.financier && !!case.financier.name ? case.financier.name : 'Sin datos'">
                                                {{ !!case.financier && !!case.financier.name ?
                                                this.generalService.getSummarizeString(case.financier.name,20) : 'Sin datos'
                                                }}
                                            </span>
                                            <span *ngIf="!!case.financierInternalCode || case.patient?.documentNumber"
                                                matTooltip="Código interno de financiador" class="text-secondary">
                                                #{{ !!case.financierInternalCode ? case.financierInternalCode :
                                                case.patient?.documentNumber}}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- Active Column -->
                            <ng-container matColumnDef="active">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let case">
                                    <div class="inner-cell active-column">
                                        <div class="flex-column"> <mat-icon class="text-warn" *ngIf="case.isDerivedTo"
                                                [matTooltip]="'Caso derivado a: ' + case.caseDerivation?.derivedTo?.name">
                                                redo
                                            </mat-icon>
                                            <mat-icon class="text-primary" *ngIf="case.isDerived"
                                                [matTooltip]="'Caso derivado de: ' + case.caseDerivation?.derivedFrom?.name">
                                                undo
                                            </mat-icon>
                                            <mat-icon
                                                [ngClass]="{'text-primary': !case.legacyId,'text-secondary': !!case.legacyId}"
                                                *ngIf="case.active" matTooltip="Caso activo">
                                                check_circle
                                            </mat-icon>
                                            <mat-icon class="text-warn mbs-1" *ngIf="!case.active"
                                                matTooltip="Caso inactivo">
                                                highlight_off
                                            </mat-icon>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- operator.name Column -->
                            <ng-container matColumnDef="operator">
                                <th mat-header-cell *matHeaderCellDef class="bg-primary-lighter">Operador</th>
                                <td mat-cell *matCellDef="let case">
                                    <div class="inner-cell">
                                        {{ case.operator?.name }}
                                    </div>
                                </td>
                            </ng-container>

                            <!-- patient.surname Column -->
                            <ng-container matColumnDef="patient">
                                <th mat-header-cell *matHeaderCellDef class="bg-primary-lighter" mat-sort-header="surname"
                                    sortActionDescription="Paciente">Paciente</th>
                                <td mat-cell *matCellDef="let case">
                                    <div class="inner-cell"
                                        style="text-align: start !important; justify-content: start !important; padding-left: 10px;">
                                        <span>
                                            {{ this.generalService.getFullName( case.patient.name,
                                            case.patient.surname )}}
                                        </span>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- patiten.documentNumber Column -->
                            <ng-container matColumnDef="patientDocument">
                                <th mat-header-cell *matHeaderCellDef class="bg-primary-lighter">DNI Paciente</th>
                                <td mat-cell *matCellDef="let case">
                                    <div class="inner-cell">
                                        <span>
                                            {{ case?.patient?.documentNumber ? case?.patient?.documentNumber : 'Sin datos'
                                            }}
                                        </span>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- DNI Column -->
                            <ng-container matColumnDef="companyType">
                                <th mat-header-cell *matHeaderCellDef class="bg-primary-lighter">Tipo de empresa</th>
                                <td mat-cell *matCellDef="let case">
                                    <div class="inner-cell">
                                        <span>
                                            {{ (case.companyType) ? case.companyType.description : 'Sin datos' }}
                                        </span>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- Case type Column -->
                            <ng-container matColumnDef="caseType">
                                <th mat-header-cell *matHeaderCellDef sortActionDescription="Tipo de caso">Tipo de caso
                                </th>
                                <td mat-cell *matCellDef="let case">
                                    <div class="inner-cell case-type-cell align-center-center"
                                        matTooltip="{{getCaseTypesList(case.caseTags, '  •  ')}}">
                                        <div>
                                            <span class="text-ellipsis">{{getCaseTypesList(case.caseTags, ', ')}}</span>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="complexity">
                                <th mat-header-cell *matHeaderCellDef class="bg-primary-lighter">Complejidad</th>
                                <td mat-cell *matCellDef="let case">
                                    <div class="inner-cell">
                                        <span>
                                            {{ !!case.complexities ? case.complexities.name : '-' }}
                                        </span>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- ExpiredAuthorizations Column -->
                            <ng-container matColumnDef="expiredAuthorizations">
                                <th mat-header-cell *matHeaderCellDef class="bg-primary-lighter">Autorizaciones</th>
                                <td mat-cell *matCellDef="let case">
                                    <div class="inner-cell">
                                        <mat-chip-listbox *ngIf="!case?.isLoadingStates">
                                            <mat-chip style="white-space: nowrap; cursor: pointer"
                                                [ngStyle]="{ 'background-color': this.getBackgroundColorAuthorizationState(case.closestAuthorizationState), 'color': this.getColorAuthorizationState(case.closestAuthorizationState) }"
                                                selected>
                                                <span class="mat-small">
                                                    {{ this.getAuthorizationState(case.closestAuthorizationState, case)
                                                    }}
                                                </span>
                                            </mat-chip>
                                        </mat-chip-listbox>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row matTooltip="Ver detalles del caso" [matTooltipShowDelay]="2000"
                                *matRowDef="let row; columns: displayedColumns" class="bg-on-hover"
                                (click)="onClickRow(row)" style="cursor: pointer;">
                            </tr>
                        </table>
                        <!-- [ngClass.lt-md]="{ 'cases-table': true }" -->
                        <!--ORDERS TABLE-->
                        <table mat-table class="mat-elevation-z0 flex-100"
                            *ngIf="optionSelected == ProvisionalControlTableOptions.ORDERS" [dataSource]="orders"
                            matSort matSortActive="patient" (matSortChange)="onSortEvent($event)" matSortDisableClear>
                            <!-- financier Column -->
                            <ng-container matColumnDef="orderFinancier" sticky>
                                <th mat-header-cell *matHeaderCellDef class="bg-primary-lighter">Financiador / CIF</th>
                                <td mat-cell *matCellDef="let order">
                                    <div class="inner-cell">
                                        <div class="flex-column"> <span
                                                [matTooltip]="!!order.casee?.financier && !!order.casee?.financier.name ? order.casee?.financier.name : 'Sin datos'">
                                                {{ !!order.casee?.financier && !!order.casee?.financier.name ?
                                                this.generalService.getSummarizeString(order.casee?.financier.name,20) :
                                                'Sin datos' }}
                                            </span>
                                            <span
                                                *ngIf="!!order.casee?.financierInternalCode || order.casee?.patient?.documentNumber"
                                                matTooltip="Código interno de financiador" class="text-secondary">
                                                #{{ !!order.casee?.financierInternalCode ?
                                                order.casee?.financierInternalCode :
                                                order.casee?.patient?.documentNumber}}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- Active Column -->
                            <ng-container matColumnDef="orderCaseActive">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let order">
                                    <div class="inner-cell active-column">
                                        <div class="flex-column"> <mat-icon class="text-warn"
                                                *ngIf="order.casee?.isDerivedTo"
                                                [matTooltip]="'Caso derivado a: ' + order.casee?.caseDerivation?.derivedTo?.name">
                                                redo
                                            </mat-icon>
                                            <mat-icon class="text-primary" *ngIf="order.casee?.isDerived"
                                                [matTooltip]="'Caso derivado de: ' + order.casee?.caseDerivation?.derivedFrom?.name">
                                                undo
                                            </mat-icon>
                                            <mat-icon
                                                [ngClass]="{'text-primary': !order.casee?.legacyId,'text-secondary': !!order.casee?.legacyId}"
                                                *ngIf="order.casee?.active" matTooltip="Caso activo">
                                                check_circle
                                            </mat-icon>
                                            <mat-icon class="text-warn mbs-1" *ngIf="!order.casee?.active"
                                                matTooltip="Caso inactivo">
                                                highlight_off
                                            </mat-icon>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- operator.name Column -->
                            <ng-container matColumnDef="orderOperator">
                                <th mat-header-cell *matHeaderCellDef class="bg-primary-lighter">Operador</th>
                                <td mat-cell *matCellDef="let order">
                                    <div class="inner-cell">
                                        {{ order.casee?.operator?.name }}
                                    </div>
                                </td>
                            </ng-container>

                            <!-- patient.surname Column -->
                            <ng-container matColumnDef="orderPatient">
                                <th mat-header-cell *matHeaderCellDef class="bg-primary-lighter" mat-sort-header="surname"
                                    sortActionDescription="Paciente">Paciente</th>
                                <td mat-cell *matCellDef="let order">
                                    <div class="inner-cell"
                                        style="text-align: start !important; justify-content: start !important; padding-left: 10px;">
                                        <span>
                                            {{ this.generalService.getFullName( order.casee?.patient.name,
                                            order.casee?.patient.surname )}}
                                        </span>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- patiten.documentNumber Column -->
                            <ng-container matColumnDef="orderPatientDocument">
                                <th mat-header-cell *matHeaderCellDef class="bg-primary-lighter">DNI Paciente</th>
                                <td mat-cell *matCellDef="let order">
                                    <div class="inner-cell">
                                        <span>
                                            {{ order.casee?.patient?.documentNumber ?
                                            order.casee?.patient?.documentNumber : 'Sin datos' }}
                                        </span>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- COMPANY TYPE Column -->
                            <ng-container matColumnDef="orderCompanyType">
                                <th mat-header-cell *matHeaderCellDef class="bg-primary-lighter">Tipo de empresa</th>
                                <td mat-cell *matCellDef="let order">
                                    <div class="inner-cell">
                                        <span>
                                            {{ (order.casee?.companyType) ? order.casee?.companyType.description : 'Sin datos' }}
                                        </span>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="orderId">
                                <th mat-header-cell *matHeaderCellDef class="bg-primary-lighter">Pedido número</th>
                                <td mat-cell *matCellDef="let order">
                                    <div class="inner-cell">
                                        <span>
                                            {{ (order?.id) ? order?.id : 'Sin datos' }}
                                        </span>
                                    </div>
                                </td>
                            </ng-container>


                            <tr mat-header-row *matHeaderRowDef="displayedColumnsOrders; sticky: true"></tr>
                            <tr mat-row matTooltip="Ver detalles del caso" [matTooltipShowDelay]="2000"
                                *matRowDef="let row; columns: displayedColumnsOrders" class="bg-on-hover"
                                (click)="onClickRow(row)" style="cursor: pointer;">
                            </tr>
                        </table>
                        <!-- [ngClass.lt-md]="{ 'cases-table': true }" -->
                        <!--PRACTICES TABLE-->
                        <table mat-table class="mat-elevation-z0"
                            *ngIf="this.optionSelected == ProvisionalControlTableOptions.PROVISIONFEES"
                            ngClass="flex-100" [dataSource]="provisionFees" matSort matSortActive="patient"
                            (matSortChange)="onSortEvent($event)" matSortDisableClear>

                            <!-- financier Column -->
                            <ng-container matColumnDef="practiceFinancier" sticky>
                                <th mat-header-cell *matHeaderCellDef class="bg-primary-lighter">Financiador / CIF</th>
                                <td mat-cell *matCellDef="let provisionFee">
                                    <div class="inner-cell">
                                        <div class="flex-column"> <span
                                                [matTooltip]="!!provisionFee.casee?.financier && !!provisionFee.casee?.financier.name ? provisionFee.casee?.financier.name : 'Sin datos'">
                                                {{ !!provisionFee.casee?.financier &&
                                                !!provisionFee.casee?.financier.name ?
                                                this.generalService.getSummarizeString(provisionFee.casee?.financier.name,20)
                                                : 'Sin datos' }}
                                            </span>
                                            <span
                                                *ngIf="!!provisionFee.casee?.financierInternalCode || provisionFee.casee?.patient?.documentNumber"
                                                matTooltip="Código interno de financiador" class="text-secondary">
                                                #{{ !!provisionFee.casee?.financierInternalCode ?
                                                provisionFee.casee?.financierInternalCode :
                                                provisionFee.casee?.patient?.documentNumber}}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- Active Column -->
                            <ng-container matColumnDef="practiceCaseActive">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let provisionFee">
                                    <div class="inner-cell active-column">
                                        <div class="flex-column"> <mat-icon class="text-warn"
                                                *ngIf="provisionFee.casee?.isDerivedTo"
                                                [matTooltip]="'Caso derivado a: ' + provisionFee.casee?.caseDerivation?.derivedTo?.name">
                                                redo
                                            </mat-icon>
                                            <mat-icon class="text-primary" *ngIf="provisionFee.casee?.isDerived"
                                                [matTooltip]="'Caso derivado de: ' + provisionFee.casee?.caseDerivation?.derivedFrom?.name">
                                                undo
                                            </mat-icon>
                                            <mat-icon
                                                [ngClass]="{'text-primary': !provisionFee.casee?.legacyId,'text-secondary': !!provisionFee.casee?.legacyId}"
                                                *ngIf="provisionFee.casee?.active" matTooltip="Caso activo">
                                                check_circle
                                            </mat-icon>
                                            <mat-icon class="text-warn mbs-1" *ngIf="!provisionFee.casee?.active"
                                                matTooltip="Caso inactivo">
                                                highlight_off
                                            </mat-icon>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- operator.name Column -->
                            <ng-container matColumnDef="practiceOperator">
                                <th mat-header-cell *matHeaderCellDef class="bg-primary-lighter">Operador</th>
                                <td mat-cell *matCellDef="let provisionFee">
                                    <div class="inner-cell">
                                        {{ provisionFee.casee?.operator?.name }}
                                    </div>
                                </td>
                            </ng-container>

                            <!-- patient.surname Column -->
                            <ng-container matColumnDef="practicePatient">
                                <th mat-header-cell *matHeaderCellDef class="bg-primary-lighter"
                                    mat-sort-header="surname" sortActionDescription="Paciente">Paciente</th>
                                <td mat-cell *matCellDef="let provisionFee">
                                    <div class="inner-cell"
                                        style="text-align: start !important; justify-content: start !important; padding-left: 10px;">
                                        <span>
                                            {{ this.generalService.getFullName( provisionFee.casee?.patient.name,
                                            provisionFee.casee?.patient.surname )}}
                                        </span>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- COMPANY TYPE Column -->
                            <ng-container matColumnDef="practiceCompanyType">
                                <th mat-header-cell *matHeaderCellDef class="bg-primary-lighter">Tipo de empresa</th>
                                <td mat-cell *matCellDef="let provisionFee">
                                    <div class="inner-cell">
                                        <span>
                                            {{ (provisionFee.casee?.companyType) ?
                                            provisionFee.casee?.companyType.description : 'Sin datos' }}
                                        </span>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- PRACTICE Column -->
                            <ng-container matColumnDef="practiceName">
                                <th mat-header-cell *matHeaderCellDef class="bg-primary-lighter">Práctica</th>
                                <td mat-cell *matCellDef="let provisionFee">
                                    <div class="inner-cell">
                                        <span>
                                            {{ (provisionFee?.practice?.name) ? provisionFee?.practice?.name : 'Sin datos' }}
                                        </span>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- VIGENCY Column -->
                            <ng-container matColumnDef="vigency">
                                <th mat-header-cell *matHeaderCellDef class="bg-primary-lighter">Vigencia</th>
                                <td mat-cell *matCellDef="let provisionFee">
                                    <div class="inner-cell">
                                        <span>
                                            {{ provisionFee.fromDate }}
                                            {{!!provisionFee.toDate ? ' al ' + provisionFee.toDate : ' en adelante.'}}
                                        </span>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- AUTHORIZATION Column -->
                            <ng-container matColumnDef="provisionFeeAuthorization">
                                <th mat-header-cell *matHeaderCellDef class="bg-primary-lighter">Autorizaciones</th>
                                <td mat-cell *matCellDef="let provisionFee">
                                    <div class="inner-cell">
                                        <mat-chip-listbox>
                                            <mat-chip
                                                [ngStyle]="{ 'background-color': this.getBackgroundColorAuthorizationState(provisionFee), 'color': this.getColorAuthorizationState(provisionFee) }"
                                                matTooltip="{{provisionFee.authorization
                                                        ? '#' + provisionFee.authorization.authorizationNumber + ' - ' + this.generalService.getAuthorizationState(provisionFee)
                                                        : this.generalService.getAuthorizationState(provisionFee)}}"
                                                matBadge="P" [matBadgeHidden]="provisionFee.arrangement != null"
                                                matBadgeColor="accent" matBadgeSize="small">
                                                <span class="mat-small">
                                                    {{this.generalService.getAuthorizationState(provisionFee) |
                                                    firstLetterWordsIntoString}}
                                                </span>
                                            </mat-chip>
                                        </mat-chip-listbox>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumnsProvisionFees; sticky: true"></tr>
                            <tr mat-row matTooltip="Ver detalles del caso" [matTooltipShowDelay]="2000"
                                *matRowDef="let row; columns: displayedColumnsProvisionFees" class="bg-on-hover"
                                (click)="onClickRow(row)" style="cursor: pointer;">
                            </tr>
                        </table>
                        <!-- Paginator -->
                        <mat-paginator class="flex-100" pageSize="100" [pageSizeOptions]="[5, 10, 25, 50, 100]"
                            (page)="onPaginatorEvent()" showFirstLastButtons firstPageLabel="Primera página"
                            lastPageLabel="Última página" nextPageLabel="Página siguiente"
                            previousPageLabel="Página anterior">
                        </mat-paginator>
                    </div>
                </div>

                <ng-template #noCasesTemplate>
                    <span class="flex-100 align-center-start text-secondary mts-4">
                        No se encontraron casos con los filtros establecidos
                    </span>
                </ng-template>
            </div>
        </div>

        <!-- Spinner -->
        <ng-template #loading>
            <div class="flex-100 mts-5 align-center-center">
                <app-spinner class="flex-100 align-center-start"></app-spinner>
            </div>
        </ng-template>

        <!-- Error message -->
        <ng-template #errorDiv>
            <div class="flex-100 align-center-center">
                <app-spinner-error></app-spinner-error>
                <span>Ha ocurrido un error inesperado, inténtelo nuevamente</span>
            </div>
        </ng-template>
    </div>
</div>
