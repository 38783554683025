import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormElementComponent } from './form-element/form-element.component';
import { EmailInputComponent } from './email-input/email-input.component';
import { ReactiveFormsModule, FormsModule as AngularFormsModule } from '@angular/forms';
import { PasswordInputComponent } from './password-input/password-input.component';
import { TextInputComponent } from './text-input/text-input.component';
import { TextAreaInputComponent } from './text-area-input/text-area-input.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { NumberInputComponent } from './number-input/number-input.component';
import { SelectInputComponent } from './select-input/select-input.component';
import { ToggleIconInputComponent } from './toggle-icon-input/toggle-icon-input.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { PipesModule } from '../../pipes/pipes.module';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { TimePickerInputComponent } from './time-picker-input/time-picker-input.component';
import { SelectSearchingInputComponent } from './select-searching-input/select-searching-input.component';
import { SelectSearchingDynamicInputComponent } from './select-searching-dynamic-input/select-searching-dynamic-input.component';
import { FileInputComponent } from './file-input/file-input.component';
import { TextSearchingInputComponent } from './text-searching-input/text-searching-input.component';
import { MultiSelectInputComponent } from './multi-select-input/multi-select-input.component';
import { CheckboxInputComponent } from './checkbox-input/checkbox-input.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
// FilePonds
import { FilePondModule, registerPlugin } from 'ngx-filepond';
import * as FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import * as FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import * as FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { AddressInputComponent } from './address-input/address-input.component';
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileEncode);
@NgModule({
    declarations: [
        FormElementComponent,
        EmailInputComponent,
        CheckboxInputComponent,
        PasswordInputComponent,
        TextInputComponent,
        TextAreaInputComponent,
        DatePickerComponent,
        NumberInputComponent,
        SelectInputComponent,
        SelectSearchingInputComponent,
        SelectSearchingDynamicInputComponent,
        ToggleIconInputComponent,
        //   DateTimePickerComponent,
        TimePickerComponent,
        TimePickerInputComponent,
        FileInputComponent,
        TextSearchingInputComponent,
        MultiSelectInputComponent,
        AddressInputComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgxMatSelectSearchModule,
        PipesModule,
        AngularFormsModule,
        MatDatepickerModule,
        MatIconModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatOptionModule,
        MatInputModule,
        MatChipsModule,
        MatTooltipModule,
        FilePondModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
    ],
    exports: [
        FormElementComponent,
        EmailInputComponent,
        CheckboxInputComponent,
        PasswordInputComponent,
        TextInputComponent,
        ReactiveFormsModule,
        TextAreaInputComponent,
        DatePickerComponent,
        NumberInputComponent,
        SelectInputComponent,
        SelectSearchingInputComponent,
        SelectSearchingDynamicInputComponent,
        TimePickerComponent,
        TimePickerInputComponent,
        FileInputComponent,
        TextSearchingInputComponent,
        MultiSelectInputComponent,
        MatTooltipModule,
        MatIconModule,
        MatAutocompleteModule,
        AddressInputComponent
    ]
})
export class FormsModule { }
