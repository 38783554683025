import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseState } from './base.state';
import { MarkerGoogleMaps } from 'src/app/shared/models/maps/markerGoogleMaps';

@Injectable({
    providedIn: 'root',
})

export class MapsSate extends BaseState {

    override store = {
        loadingGetMarkers$: new BehaviorSubject<boolean>(false),
        markers$: new BehaviorSubject<MarkerGoogleMaps[]>([])
    };

    constructor() {
        super();
    }

    isLoadingGetMarkers$() {
        return this.store.loadingGetMarkers$.asObservable();
    }

    setLoadingGetMarkers(isLoadingGetMarkers: boolean) {
        this.store.loadingGetMarkers$.next(isLoadingGetMarkers);
    }
    setMarkers(markers: MarkerGoogleMaps[]) {
        this.store.markers$.next(markers);
    }
    resetMarkers() {
        this.store.markers$.next([]);
    }
    mergeMarkers(markers: MarkerGoogleMaps[]): void {
        const currentState = this.store.markers$.getValue();
        // Verificar si currentState es iterable
        let newState: MarkerGoogleMaps[];
        if (Array.isArray(currentState)) {
          newState = [...currentState, ...markers];
        } else {
          newState = [...markers];
        }

        // console.log('entrando al mapa', newState);

        this.store.markers$.next(newState);
      }


    getMarkers$(): Observable<MarkerGoogleMaps[]> {
        return this.store.markers$.asObservable();
    }

    getMarkers(): MarkerGoogleMaps[] {
        return this.store.markers$.getValue();
    }

    addMarker(marker: Partial<MarkerGoogleMaps>) {
        console.log('addMarker()');
        this.add<Partial<MarkerGoogleMaps>>({ data: marker, storeRefAttribute: this.store.markers$ })
    }

    setVisibleMarker(id:number){
        const currentState = this.store.markers$.getValue();
        currentState.forEach(m=>{
            if(m.id == id){m.visible = !m.visible}})
        this.store.markers$.next(currentState);
    }

    setVisibleAllMarkers(){
        const currentState = this.store.markers$.getValue();
        currentState.forEach(m=>{
          m.visible = !m.visible})
        this.store.markers$.next(currentState);
    }

}
