import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { Pagination } from '../shared/models/pagination';
import { CategoriesofEvolutionsState } from '../core/states/categories-of-evolutions.state';
import { CategoriesOfEvolutionsQPS, CategoriesOfEvolutionsService, PatchCategoryOfEvolution, PostCategoryOfEvolution, CategoriesOfEvolutionsCountQPS } from '../core/services/categories-of-evolutions.service';
import { CategoryOfEvolution } from '../shared/models/categoryOfEvolution';

export interface MetaDataCategoriesOfEvolutions extends CategoriesOfEvolutionsQPS, Pagination {
}


@Injectable({
    providedIn: 'root',
})
export class CategoriesofEvolutionsFacade {

    constructor(
        private categoriesOfEvolutionsState: CategoriesofEvolutionsState,
        private categoriesOfEvolutionsService: CategoriesOfEvolutionsService
    ) { }

    // LOAD
    loadCategoriesOfEvolutions(qps?: CategoriesOfEvolutionsQPS): Observable<CategoryOfEvolution[]> {

        this.categoriesOfEvolutionsState.setLoadingGetCategoriesofEvolutions(true);

        const promise: Promise<any> = new Promise((resolve, reject) => {
            this.categoriesOfEvolutionsService.getCategoriesOfEvolutions(qps).pipe(
                tap(body => this.categoriesOfEvolutionsState.setCategoriesOfEvolutions(body.data)),
                tap(() => this.categoriesOfEvolutionsState.setLoadingGetCategoriesofEvolutions(false)),
                tap(() => this.categoriesOfEvolutionsState.setLoadingGettingPagination(false)),
                tap((body) => {
                    this.categoriesOfEvolutionsState.setMetaDataCategoriesOfEvolutions({ ...qps, ...body.pagination });
                }),
                tap((body) => console.log('request finished. Order: ', body.data, 'Pagination :', body.pagination)),
            ).subscribe({
                next: response => resolve(response.data),
                error: e => {
                    this.categoriesOfEvolutionsState.setLoadingGetCategoriesofEvolutions(false);
                    this.categoriesOfEvolutionsState.setLoadingGettingPagination(false);
                    reject(e)
                },
            })
        })
        return from(promise);
    }


    // ADD - CREATE
    addCategoryOfEvolution(category: PostCategoryOfEvolution): any | Observable<CategoryOfEvolution> {
        this.categoriesOfEvolutionsState.setLoadingCreatingCategoryOfEvolution(true);

        const promise: Promise<CategoryOfEvolution> = new Promise((res, rej) => {
            this.categoriesOfEvolutionsService.postCategoryOfEvolution(category).pipe(
                finalize(() => this.categoriesOfEvolutionsState.setLoadingCreatingCategoryOfEvolution(false))
            ).subscribe({
                next:
                    // Add al store
                    category => { this.categoriesOfEvolutionsState.addCaegoryOfEvolution(category); res(category) },
                error: e => rej(e)
            })
        })
        return from(promise);
    }


    // UPDATE - PATCH
    updateCategoryOfEvolution(category: PatchCategoryOfEvolution): Observable<CategoryOfEvolution> {
        this.categoriesOfEvolutionsState.setLoadingUpdatingCategoryofEvolution(true);

        const promise: Promise<CategoryOfEvolution> = new Promise((res, rej) => {
            this.categoriesOfEvolutionsService.patchCategoryOfEvolution(category).pipe(
                finalize(() => this.categoriesOfEvolutionsState.setLoadingUpdatingCategoryofEvolution(false))
            ).subscribe({
                next: newCategory => { this.categoriesOfEvolutionsState.updateCategoryOfEvolution(category, category.id); res(newCategory) },
                error: e => rej(e)
            })
        })
        return from(promise);
    }


    // COUNT
    loadCategoriesOfEvolutionsCount(qps: CategoriesOfEvolutionsCountQPS) {

        const promise: Promise<any> = new Promise((res, rej) => {

            this.categoriesOfEvolutionsService.getCategoriesOfEvolutionsCount(qps).pipe(

                tap(body => this.categoriesOfEvolutionsState.setCategoriesOfEvolutionsCount(body.data)),
            ).subscribe({
                next: response => res(response.data),
                error: e => { rej(e) },
            })
        })
        return from(promise);
    }


    // STATES FROM STORE
    // Categories of Evolutions
    isLoadingGetCategoriesofEvolutions$(): Observable<boolean> {
        return this.categoriesOfEvolutionsState.isLoadingGetCategoriesofEvolutions$();
    }

    isLoadingCreatingCategorieofEvolution$(): Observable<boolean> {
        return this.categoriesOfEvolutionsState.isLoadingGetCategoriesofEvolutions$();
    }

    isLoadingUpdatingCategorieofEvolution$(): Observable<boolean> {
        return this.categoriesOfEvolutionsState.isLoadingGetCategoriesofEvolutions$();
    }


    // Metadata
    getMetaDataCategoriesofEvolutions$(): Observable<MetaDataCategoriesOfEvolutions> {
        return this.categoriesOfEvolutionsState.getMetaDataCategoriesOfEvolutions$();
    }


    getCategoriesOfEvolutions$(): Observable<CategoryOfEvolution[]> {
        return this.categoriesOfEvolutionsState.getCategoriesOfEvolutions$();
    }

    // Count
    getCategoriesOfEvolutionsCount$(): Observable<number> {
        return this.categoriesOfEvolutionsState.getCategoriesOfEvolutionsCount$();
    }
}
