import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from './../environments/environment';
import { GeneralService } from './core/services/general.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MapsService } from './shared/services/maps.service';
import { firstValueFrom } from 'rxjs';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

    title = 'appweboperadores';
    env = environment;
    isLoading: boolean = false;
    error: boolean = false;

    @HostBinding('class') componentCssClass: any;

    constructor(private titleService: Title,
        public overlayContainer: OverlayContainer,
        private generalService: GeneralService,
        @Inject(MAT_DATE_FORMATS) private dateFormats: any,
        private dateAdapter: DateAdapter<any>,
        @Inject(MAT_DATE_LOCALE) private locale: string,
        private mapsService: MapsService
    ) {
        // Set theme to container
        this.overlayContainer.getContainerElement().classList.add(this.env.actualTheme);
        this.componentCssClass = this.env.actualTheme;
        // Check date formats
        console.log('Date Formats:', this.dateFormats);
        console.log('Date Locale:', this.locale);
        this.dateAdapter.setLocale(this.locale);
        // this.maps.loadApi(); // Loader API Googlem Maps
    }

    async ngOnInit() {
        this.addIcons();
        this.setTitle('Simfinix - Web Operadores')
        // Load Google Maps API
        try {
            await this.mapsService.loadApi();
            console.log('API Google Maps loaded');
        } catch (error) {
            console.error('Error API Google Maps', error);
        }
        // // Preload SVGs for Google Maps
        try {
            await this.preloadMapIcons();
            console.log('SVGs loaded');
        } catch (error) {
            console.error('Error preload SVGs:', error);
        }
    }

    async preloadMapIcons() {
        await this.mapsService.preloadMapIcons();
        // Aquí puedes usar los íconos cargados
    }

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }

    // Add icons gloabally
    addIcons() {
        this.generalService.addIcon('facebook', 'facebook.svg');
        this.generalService.addIcon('filters', 'filters.svg');
        this.generalService.addIcon('liquidations', 'liquidations.svg');
        this.generalService.addIcon('liquidations-2', 'liquidations-2.svg');
        this.generalService.addIcon('twitter', 'twitter.svg');
        this.generalService.addIcon('linkedin', 'linkedin.svg');
        this.generalService.addIcon('whatsapp', 'whatsapp.svg');
        this.generalService.addIcon('whatsappLight', 'whatsapp-light.svg');
        this.generalService.addIcon('doctor', 'doctor.svg');
        this.generalService.addIcon('pdf', 'pdf.svg');
        this.generalService.addIcon('zip', 'zip.svg');
        this.generalService.addIcon('csv', 'csv.svg');
        this.generalService.addIcon('patient', 'patient.svg');
        this.generalService.addIcon('agendada', 'agendada.svg');
        this.generalService.addIcon('realizada', 'realizada.svg');
        this.generalService.addIcon('en_curso', 'en_curso.svg');
        this.generalService.addIcon('no_realizada', 'no_realizada.svg');
        this.generalService.addIcon('masculine', 'masculine.svg');
        this.generalService.addIcon('femenine', 'femenine.svg');
        this.generalService.addIcon('gender', 'gender-fluid.svg');
        this.generalService.addIcon('arrow-right-pointed', 'arrow-right.svg');
        this.generalService.addIcon('hash', 'hash.svg');
        this.generalService.addIcon('person-injured', 'user-injured-solid.svg');
        this.generalService.addIcon('user-tie', 'user-tie-solid.svg');
        this.generalService.addIcon('evolucion-medica', 'heartbeat.svg');
        this.generalService.addIcon('calendar-indicator', 'calendar.svg');

        this.generalService.addIcon('calendar-enabled-primary', 'Calendar-enabled-primary.svg');
        this.generalService.addIcon('calendar-disabled-primary', 'Calendar-disabled-primary.svg');

        this.generalService.addIcon('no-calendar-indicator', 'no-calendar.svg');
        this.generalService.addIcon('done-calendar-indicator', 'done-calendar.svg');
        this.generalService.addIcon('to-do-list-indicator', 'to-do-list.svg');
        this.generalService.addIcon('request-indicator', 'request.svg');
        this.generalService.addIcon('portfolio-indicator', 'portfolio.svg');
        this.generalService.addIcon('id', 'id.svg');
        this.generalService.addIcon('id-card', 'id-card.svg');
        this.generalService.addIcon('id-card', 'id-card.svg');
        this.generalService.addIcon('health-insurance', 'health-insurance.svg');
        this.generalService.addIcon('clients', 'clientes.svg');
        this.generalService.addIcon('doctors', 'doctors.svg');
        this.generalService.addIcon('operator', 'operator.svg');
        this.generalService.addIcon('orders', 'orders.svg');
        this.generalService.addIcon('supplies', 'supplies.svg');
        this.generalService.addIcon('supplies-2', 'supplies-2.svg');
        this.generalService.addIcon('supplies-3', 'supplies-3.svg');
        this.generalService.addIcon('practices', 'practices.svg');
        this.generalService.addIcon('practices-2', 'practices-2.svg');
        this.generalService.addIcon('supplies_fly', 'supplies_fly.svg');
        this.generalService.addIcon('categories-of-evolutions', 'categories-of-evolutions.svg');
        this.generalService.addIcon('trato', 'trato.svg');
        this.generalService.addIcon('forms', 'forms.svg');
        this.generalService.addIcon('tags', 'medical-tag.svg');
        this.generalService.addIcon('evolutions-2', 'evolutions-2.svg');
        this.generalService.addIcon('hospital', 'hospital.svg');
        this.generalService.addIcon('user', 'user.svg');
        this.generalService.addIcon('mailLight', 'mail-light.svg');
        this.generalService.addIcon('Folders', 'Folders.svg');
        this.generalService.addIcon('NoteBlank', 'NoteBlank.svg');
        this.generalService.addIcon('UsersFour', 'UsersFour.svg');

        this.generalService.addIcon('PlusCircle-enabled-white', 'PlusCircle-enabled-white.svg');
        this.generalService.addIcon('PlusCircle-enabled-primary', 'PlusCircle-enabled-primary.svg');
        this.generalService.addIcon('PlusCircle-enabled-primary-lighter', 'PlusCircle-enabled-primary-lighter.svg');
        this.generalService.addIcon('PlusCircle-disabled-primary', 'PlusCircle-disabled-primary.svg');

        this.generalService.addIcon('ArrowCircleRight', 'ArrowCircleRight.svg');
        this.generalService.addIcon('checkCircle_enable', 'checkCircle_enable.svg');
        this.generalService.addIcon('checkCircle_disabled', 'checkCircle_disabled.svg');
        this.generalService.addIcon('ArrowCircleLeft', 'ArrowCircleLeft.svg');
        this.generalService.addIcon('userNotif', 'userNotif.svg');
        this.generalService.addIcon('users', 'Users.svg');
        this.generalService.addIcon('userCircle', 'UserCircle.svg');
        this.generalService.addIcon('WarningCircle', 'WarningCircle.svg');

        this.generalService.addIcon('ListPlus-enabled-primary', 'ListPlus-enabled-primary.svg');
        this.generalService.addIcon('ListPlus-enabled-primary-lighter', 'ListPlus-enabled-primary-lighter.svg');
        this.generalService.addIcon('ListPlus-disabled-primary', 'ListPlus-disabled-primary.svg');

        this.generalService.addIcon('ListPlus-withoutPlus-enabled-primary-lighter', 'ListPlus-withoutPlus-enabled-primary-lighter.svg');

        this.generalService.addIcon('exit-icon', 'exit.svg');
        this.generalService.addIcon('exit-disabled-icon', 'exit-disabled.svg');

        this.generalService.addIcon('billing-enabled-primary', 'Billing-enabled-primary.svg');
        this.generalService.addIcon('billing-disabled-primary', 'Billing-disabled-primary.svg');

        this.generalService.addIcon('ellipseGreen', 'ellipse-green.svg');
        this.generalService.addIcon('ellipseRed', 'ellipse-red.svg');

        this.generalService.addIcon('PencilSimple-enabled-primary', 'PencilSimple-enabled-primary.svg');
        this.generalService.addIcon('PencilSimple-enabled-primary-lighter', 'PencilSimple-enabled-primary-lighter.svg');
        this.generalService.addIcon('PencilSimple-disabled', 'PencilSimple-disabled.svg');

        this.generalService.addIcon('Trash-enabled-primary', 'Trash-enabled-primary.svg');
        this.generalService.addIcon('Trash-enabled-primary-lighter', 'Trash-enabled-primary-lighter.svg');
        this.generalService.addIcon('Trash-disabled', 'Trash-disabled.svg');

        this.generalService.addIcon('authorize-disabled', 'authorize-disabled.svg');
        this.generalService.addIcon('authorize-enabled-primary', 'authorize-enabled-primary.svg');

        this.generalService.addIcon('DotsThreeVertical-enabled-primary-lighter', 'DotsThreeVertical-enabled-primary-lighter.svg');

        this.generalService.addIcon('Info-enabled-primary-lighter', 'Info-enabled-primary-lighter.svg');

        this.generalService.addIcon('SelectAll-enabled-primary-lighter', 'SelectAll-enabled-primary-lighter.svg');

        this.generalService.addIcon('Lines-gray', 'Lines-gray.svg');

        this.generalService.addIcon('Refresh-enabled-primary-lighter', 'Refresh-enabled-primary-lighter.svg');

        this.generalService.addIcon('RefreshPrice-enabled-primary', 'RefreshPrice-enabled-primary.svg');
        this.generalService.addIcon('RefreshPrice-disabled', 'RefreshPrice-disabled.svg');

        this.generalService.addIcon('Union-enabled-primary', 'Union-enabled-primary.svg');

        this.generalService.addIcon('Filter-enabled-primary', 'Filter-enabled-primary.svg');

        this.generalService.addIcon('Home-default', 'Home-default.svg');
        this.generalService.addIcon('ChartBar', 'ChartBar-default.svg');
        this.generalService.addIcon('Consolidation-default', 'Consolidation-default.svg');
        this.generalService.addIcon('Admissions-default', 'Admissions-default.svg');
        this.generalService.addIcon('Attentions-default', 'Attentions-default.svg');
        this.generalService.addIcon('Cases-default', 'Cases-default.svg');
        this.generalService.addIcon('Tasks-default', 'Tasks-default.svg');
        this.generalService.addIcon('Admissions-dashboard-default', 'Admissions-dashboard-default.svg');

        this.generalService.addIcon('Providers-schedule-default', 'Providers-schedule-default.svg');
        this.generalService.addIcon('FindProvider-enabled-primary-27x27', 'FindProvider-enabled-primary-27x27.svg');

        this.generalService.addIcon('Settings-default', 'Settings-default.svg');
        this.generalService.addIcon('Provisional-control-default', 'Provisional-control-default.svg');

        this.generalService.addIcon('Delete-enabled-primary-22x22', 'Delete-enabled-primary-22x22.svg');
        this.generalService.addIcon('Delete-enabled-primary-16x16', 'Delete-enabled-primary-16x16.svg');
        this.generalService.addIcon('Delete-disabled-16x16', 'Delete-disabled-16x16.svg');
        this.generalService.addIcon('Delete-disabled-22x22', 'Delete-disabled-22x22.svg');

        this.generalService.addIcon('Edit-enabled-primary-22x22', 'Edit-enabled-primary-22x22.svg');
        this.generalService.addIcon('Edit-enabled-primary-16x16', 'Edit-enabled-primary-16x16.svg');
        this.generalService.addIcon('Edit-disabled-16x16', 'Edit-disabled-16x16.svg');
        this.generalService.addIcon('Edit-disabled-22x22', 'Edit-disabled-22x22.svg');

        this.generalService.addIcon('Add-attention-enabled-primary-22x22', 'Add-attention-enabled-primary-22x22.svg');
        this.generalService.addIcon('Add-attention-disabled-22x22', 'Add-attention-disabled-22x22.svg');
        this.generalService.addIcon('Add-attention-default-22x22', 'Add-attention-default-22x22.svg');

        this.generalService.addIcon('Copy-attention-enabled-primary-16x16', 'Copy-attention-enabled-primary-16x16.svg');
        this.generalService.addIcon('Copy-attention-disabled-16x16', 'Copy-attention-disabled-16x16.svg');

        this.generalService.addIcon('Watch-16x16', 'Watch-16x16.svg');

        this.generalService.addIcon('ReplaceAgreement-enabled-primary-22x22', 'ReplaceAgreement-enabled-primary-22x22.svg');
        this.generalService.addIcon('ReplaceAgreement-enabled-primary-16x16', 'ReplaceAgreement-enabled-primary-16x16.svg');
        this.generalService.addIcon('ReplaceAgreement-disabled-16x16', 'ReplaceAgreement-disabled-16x16.svg');
        this.generalService.addIcon('ReplaceAgreement-disabled-22x22', 'ReplaceAgreement-disabled-22x22.svg');

        this.generalService.addIcon('FindProvider-Default-27x27', 'FindProvider-Default-27x27.svg')
        this.generalService.addIcon('FindProvider-enabled-primary-27x27', 'FindProvider-enabled-primary-27x27.svg')
    }

    //  getTenant(tenantCode: string) {
    //     this.isLoading = true;
    //     this.authService.getTenant(tenantCode).pipe(delay(5000)).subscribe(tenant => { this.authService.updateCurrentTenant(tenant); this.error = false }, (e) => { this.isLoading = false; this.error = true }, () => this.isLoading = false)
    //  }
}
