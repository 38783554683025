import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Output, effect, input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MapsFacade } from 'src/app/abstraction/maps.facade';
import { TypeMapMarker } from 'src/app/core/enums/typeMapMarker';
import { MaterialModule } from 'src/app/material/material.module';
import { FormsModule } from 'src/app/shared/components/forms/forms.module';
import { MarkerGoogleMaps } from 'src/app/shared/models/maps/markerGoogleMaps';
import { Patient } from 'src/app/shared/models/patient';
import { Provider } from 'src/app/shared/models/provider';
import { Specialty } from 'src/app/shared/models/specialty';
import { MapsService } from 'src/app/shared/services/maps.service';

export interface onCheckMarker {
    id: number,
    type: TypeMapMarker,
    visible?: boolean,
}
@Component({
    selector: 'app-markers-list',
    standalone: true,
    imports: [MaterialModule, FormsModule],
    templateUrl: './markers-list.component.html',
    styleUrl: './markers-list.component.scss',
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})

export class MarkersListComponent {
    public readonly CHECK = 'check';
    selectForm: FormGroup;
    markers = input<Provider[]>();
    selectAll = input<boolean>(true);
    actualCenter = input<MarkerGoogleMaps>();
    selectAllElements: boolean = true;
    specialty = input<string>(null);
    @Output() onCheck = new EventEmitter<onCheckMarker>(null);
    @Output() locateMarker = new EventEmitter<onCheckMarker>(null);
    dataSourceMarkers: MatTableDataSource<Provider>;
    markerId: number
    displayedColumns: string[] = [
        'selected',
        'state',
        'name',
        'practice',
        // 'actions',
        'location'
    ];

    constructor(
        private formBuilder: FormBuilder,
        private mapsService: MapsService,
        private mapsFacade: MapsFacade
    ) {
        effect(() => {
            if (!!this.markers()) {
                const markersClean = this.markers().filter(item => {
                    // Eliminar elementos que sean null o undefined
                    return item !== null && item !== undefined && !!item.address.latitude && !!item.address.longitude
                });
                if (!!this.actualCenter()) {
                    const sortedMarkers = this.sortMarkersByDistance(markersClean, this.actualCenter());
                    this.dataSourceMarkers =new MatTableDataSource(sortedMarkers)
                } else {
                    this.dataSourceMarkers = new MatTableDataSource(markersClean);
                }
            }
            if (this.selectAll() == true) {
                this.selectForm.get('check').setValue(true)
            } else {
                this.selectForm.get('check').setValue(false)
            }
            // if(!!this.specialty()){
            //     // console.log(this.specialty());
            // }
        });
    }

    createFiltersForm() {
        return this.formBuilder.group({
            marker: new FormControl({ disabled: false }),
            [this.CHECK]: [true],
        });
    }

    // onPressCheck(value: number) {
    //     this.markerId = value;
    //     const check = this.selectForm.get('check').value
    //     if (!check) {
    //         const marker: onCheckMarker = {
    //             id: value,
    //             type: TypeMapMarker.PROVIDER,
    //             visible: false
    //         }
    //         this.onCheck.emit(marker)
    //     } else {
    //         const marker: onCheckMarker = {
    //             id: value,
    //             type: TypeMapMarker.PROVIDER,
    //             visible: true
    //         }
    //         this.onCheck.emit(marker)
    //     }
    // }

    onPressCheck(value:number){
        this.mapsFacade.setVisibleMarker(value)
    }

    onClickLocation(value: number, type: TypeMapMarker) {
        if (type = TypeMapMarker.PROVIDER) {
            const marker: onCheckMarker = {
                id: value,
                type: TypeMapMarker.PROVIDER,
            }
            this.locateMarker.emit(marker)
        }
    }

    ngOnInit(): void {
        this.selectForm = this.createFiltersForm();
    }
    // Función para calcular la distancia en línea recta entre dos puntos usando la fórmula de Haversine
    haversineDistance(lat1: number, lng1: number, lat2: number, lng2: number): number {
        const toRadians = (degrees: number) => degrees * (Math.PI / 180);

        const R = 6371; // Radio de la Tierra en kilómetros
        const dLat = toRadians(lat2 - lat1);
        const dLng = toRadians(lng2 - lng1);

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
            Math.sin(dLng / 2) * Math.sin(dLng / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c; // Distancia en kilómetros
    }

    // Función para ordenar los markers por distancia al centro
    sortMarkersByDistance(markers:Provider[], center: MarkerGoogleMaps): Provider[] {
        console.log('ordenando markers',center);

        return markers.sort((a, b) => {
            const distanceA = this.haversineDistance(center.lat, center.lng, a.address.latitude, a.address.longitude);
            const distanceB = this.haversineDistance(center.lat, center.lng, b.address.latitude, b.address.longitude);

            return distanceA - distanceB;
        });
    }


}
