<div class="flex-column flex-100 mbs-2" *ngIf="!!this.case">
    <div class="flex-100 example-loading-shade" *ngIf="this.isLoadingGettingOrders">
        <app-spinner class="spinner-center"></app-spinner>
        <div style="height: 50px;"></div>
    </div>
    <div class="mts-3 flex-column">
        <ng-container *ngIf="this.dataSource?.data?.length != 0 ; else noOrdersTemplate">
            <!-- class="case-style"  -->
            <table mat-table class="flex-100 table-bordered" [dataSource]="dataSource" multiTemplateDataRows>

                <!-- arrow Column -->
                <ng-container matColumnDef="arrow">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let data">
                        <div class="inner-cell">
                            <mat-icon class="text-primary-darker">
                                {{ data == expandedElement ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
                            </mat-icon>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <!-- Checkbox Column -->
                <ng-container matColumnDef="checkbox">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row" class="border-none-important">
                        <div>
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ?  selectOrder(row) : null" [checked]="this.selection.isSelected(row)">
                            </mat-checkbox>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <!-- orderDate Column -->
                <ng-container matColumnDef="orderDate">
                    <th  #supplyOrdersFocus  mat-header-cell *matHeaderCellDef>Fecha estimada</th>
                    <td mat-cell *matCellDef="let order">
                        <div class="inner-cell">
                            <span matTooltip="Fecha de entrega esperada">
                                {{ order.orderDate ? (order.orderDate | date: 'd/M/yy, H:mm ') : 'Sin definir' }}
                            </span>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <!-- deliveryDate Column -->
                <ng-container matColumnDef="deliveryDate">
                    <th mat-header-cell *matHeaderCellDef>Fecha de entrega</th>
                    <td mat-cell *matCellDef="let order" >
                        <div class="inner-cell">
                            <span matTooltip="Fecha de entrega ">
                                {{ order.deliveryDate ? (order.deliveryDate | date: 'd/M/yy, H:mm ') : 'No entregado aún' }}
                            </span>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <!-- quantity Column -->
                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef>Cantidad</th>
                    <td mat-cell *matCellDef="let order">
                        <div class="inner-cell">
                            <span>
                                {{ order.items ? getItemsCount(order) : '0 insumos' }}
                            </span>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <!-- id Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>Pedidos</th>
                    <td mat-cell *matCellDef="let order">
                        <span class="subtitle-3 text-primary-darker">
                            {{ !!order.id ? "Pedido " + order.id : 'No disponible' }}
                        </span>
                    </td>
                    <td mat-footer-cell *matFooterCellDef
                        [attr.collapsed]="(!this.isCollapsed) ? displayedColumns.length : displayedColumnsCollapsed.length">
                        <div class="flex-100 flex-row align-center-center">
                            <div>
                                <span class="subtitle-5 text-primary-darker font-weight-500">PEDIDOS:</span>
                                <span
                                    class="mls-1 subtitle-5 text-primary font-weight-600">{{this.orders.length}}</span>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <!-- provider Column -->
                <ng-container matColumnDef="provider">
                    <th mat-header-cell *matHeaderCellDef>Proveedor</th>
                    <td mat-cell *matCellDef="let order">
                        <!-- {{ order?.provider?.surname || order?.provider?.name ? this.generalService.getSummarizeString(order.provider.surname + ' ' +order.provider.name,20) : 'Sin nombre' }} -->
                        <div class="inner-cell">
                            <span
                                matTooltip="{{  !!this.entitiesFacade.getProvidersFullName(order?.provider) ? order.provider?.mobilePhoneNumber : 'Sin número de teléfono' }}">
                                {{ order?.provider?.surname || order?.provider?.name ? order.provider.surname + ', ' +
                                order.provider.name : 'Sin nombre' }}
                            </span>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <!-- state Column -->
                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef>Estado</th>
                    <td mat-cell *matCellDef="let order"  [ngClass]="{'marked-top': order.paintedType == _viewManagementEntities.SUPPLIES_NOT_DELIVERED}">
                        <div class="inner-cell inner-cell-auth">
                            <mat-chip-listbox>
                                <mat-chip
                                    [matTooltip]="this.matTooltipText(order) + ' - ' + ( order.state.name | uppercase )"
                                    class="white-space-nowrap"
                                    [ngStyle]="{'color': this.setOrderColor(order.state.id).color, 'background-color': this.setOrderColor(order.state.id).background_color}"
                                    selected>
                                    <span matTooltipClass="multiline-tooltip" class="mat-small">
                                        {{ order.state.name | titlecase | uppercase | firstLetterWordsIntoString}}
                                    </span>
                                </mat-chip>
                            </mat-chip-listbox>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <!-- Actions Column -->
                <ng-container matColumnDef="updateOrder">
                    <th mat-header-cell *matHeaderCellDef class="border-none-important"></th>
                    <td mat-cell *matCellDef="let order" class="border-none-important">
                        <div class="inner-cell">
                            <button mat-icon-button (click)="$event.stopPropagation();" matTooltip="Acciones">
                                <mat-icon class="cursor-pointer text-secondary" [matMenuTriggerFor]="menuActions"
                                    [matMenuTriggerData]="{ order:order }"
                                    [svgIcon]="'DotsThreeVertical-enabled-primary-lighter'"></mat-icon>
                            </button>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let data"
                        [attr.colspan]="!isCollapsed ? displayedColumns.length:displayedColumnsCollapsed.length"
                        class="padding-left-none-important">
                        <div class="example-element-detail"
                            [@detailExpand]="data == expandedElement ? 'expanded' : 'collapsed'">
                            <!-- Agreements -->
                            <div class="flex-100 flex-column">
                                <div class="shadow"></div>
                                <div class="flex-row align-space-between-center flex-100 bg-primary-lighter"
                                    style="padding: 4px 0;">
                                    <span class="subtitle-3 text-primary-darker flex-17">
                                        Insumos
                                    </span>
                                    <span class="subtitle-3 text-primary-darker flex-17">
                                        Cantidad
                                    </span>
                                    <span class="subtitle-3 text-primary-darker flex-17" *ngIf="!this.isCollapsed">
                                        Valor costo unitario
                                    </span>
                                    <span class="subtitle-3 text-primary-darker flex-17" *ngIf="this.isCollapsed">
                                        Costo
                                    </span>
                                    <span class="subtitle-3 text-primary-darker flex-17">
                                        Margen
                                    </span>
                                    <span class="subtitle-3 text-primary-darker flex-17" *ngIf="!this.isCollapsed">
                                        Valor venta unitario
                                    </span>
                                    <span class="subtitle-3 text-primary-darker flex-17" *ngIf="this.isCollapsed">
                                        Venta
                                    </span>
                                    <span class="subtitle-3 text-primary-darker flex-17" *ngIf="!this.isCollapsed">
                                        Autorizado
                                    </span>
                                </div>
                                <div class="flex-row align-space-between-center border-bottom"
                                    *ngFor="let item of data.items">
                                    <div class="flex-row flex-100 text-secondary align-space-between-center">
                                        <span class="flex-17"
                                            [matTooltip]="item.practice.name + ' - ' + item?.provisionFee?.practice?.code">{{item.practice.name
                                            }} </span>
                                        <div class="flex-17 ellipsis">
                                            <span>{{ item?.quantityOrdered ? (item.quantityOrdered + ' ') : '-'}}</span>
                                            <span matTooltip="{{item.unit ? item.unit + '/s' : ''}}">{{item.unit ?
                                                item.unit + '/s' : ''}}</span>
                                        </div>
                                        <span class="flex-17">{{ item?.provisionFee?.agreement?.providerFee != null ?
                                            '$' + item?.provisionFee?.agreement?.providerFee : '-' }}</span>
                                        <span class="flex-17" *ngIf="!item?.provisionFee?.fixedPrice">{{
                                            item?.provisionFee?.profitMargin ? item?.provisionFee?.profitMargin + '%' :
                                            '-' }}</span>
                                        <span class="flex-17" *ngIf="!item?.provisionFee?.fixedPrice">{{
                                            item?.provisionFee?.fee != null ? '$' + item?.provisionFee?.fee : '-'
                                            }}</span>
                                        <span class="flex-17" *ngIf="item?.provisionFee?.fixedPrice">-</span>
                                        <span class="flex-17" *ngIf="item?.provisionFee?.fixedPrice">Kairos</span>
                                        <div class="flex-17 align-center-center padding-2-none"
                                            *ngIf="!this.isCollapsed">
                                            <mat-chip-listbox>
                                                <mat-chip class="white-space-nowrap"
                                                    [ngStyle]="{ 'background-color': this.getBackgroundColorAuthorizationState(item?.provisionFee), 'color': this.getColorAuthorizationState(item?.provisionFee), 'cursor': !!item?.provisionFee?.authorization ? 'pointer' : 'default' }"
                                                    (click)="onClickAuthorization(item.provisionFee)"
                                                    matTooltip="{{ item?.provisionFee?.authorization
                                                             ? '#' + item?.provisionFee?.authorization?.authorizationNumber + ' - ' + this.generalService.getAuthorizationState(item.provisionFee)
                                                             : (this.generalService.getAuthorizationState(item.provisionFee) | uppercase) }}">
                                                    <span class="mat-small">
                                                        {{this.generalService.getAuthorizationState(item.provisionFee) |
                                                        firstLetterWordsIntoString}}
                                                    </span>
                                                </mat-chip>
                                            </mat-chip-listbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="progressBar">
                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="9" class="border-none-important">
                        <mat-progress-bar [mode]="'indeterminate'" progressBarId="attentionsLoading"
                            *ngIf="isLoadingCreatingOrder || (isLoadingCreatingProvision$ | async) || (isLoadingUpdatingProvision$ | async) || (isLoadingGettingAttentions$ | async)"></mat-progress-bar>
                        <mat-progress-bar [mode]="'determinate'" [value]="100" progressBarId="attentionsLoading"
                            *ngIf="!isLoadingCreatingOrder && !(isLoadingCreatingProvision$ | async) && !(isLoadingUpdatingProvision$ | async) && !(isLoadingGettingAttentions$ | async)"></mat-progress-bar>
                    </th>
                </ng-container>

                <tr mat-header-row
                    *matHeaderRowDef="(!this.isCollapsed) ? displayedColumns : displayedColumnsCollapsed"></tr>
                <tr mat-header-row *matHeaderRowDef="['progressBar']" class="height-4-important"></tr>
                <tr mat-row class="bg-on-hover parentShowOnHover example-element-row"
                    *matRowDef="let row; columns: (!this.isCollapsed) ? displayedColumns : displayedColumnsCollapsed"
                    [class.example-expanded-row]="expandedElement === row"
                    (click)="expandedElement = expandedElement === row ? null : row">
                </tr>
                <tr [ngClass]="{'autoArrangement': true}" mat-row *matRowDef="let row; columns: ['expandedDetail']"
                    class="parentShowOnHover example-detail-row"></tr>
                <tr mat-footer-row
                    *matFooterRowDef="(!this.isCollapsed) ? displayedColumns : displayedColumnsCollapsed"></tr>
            </table>
        </ng-container>
    </div>
    <ng-template #noOrdersTemplate>
        <div class="ms-4">
            <span *ngIf="!this.isLoadingGettingOrders" class="flex-row align-center-start text-secondary">
                El caso no tiene pedidos</span>
        </div>
    </ng-template>
</div>

<ng-template #modifyOrderTemplate>
    <form *ngIf="this.formModifyOrder" [formGroup]="this.formModifyOrder" (submit)="onClickConfirmDelivery()"
        class="flex-column">
        <ng-container>
            <table mat-table [dataSource]="dataSourceSupplies" class="order-delivery-table">
                <!-- supplyRequested Column -->
                <ng-container matColumnDef="supplyRequested">
                    <th mat-header-cell *matHeaderCellDef class="text-center">Insumo</th>
                    <td mat-cell *matCellDef="let order; let i = index;">
                        <span
                            class="subtitle-3 text-primary-darker">{{this.itemsFormArray['controls'][i].get(this.ITEM_PRACTICE).value.name
                            }}</span>
                    </td>
                </ng-container>
                <!-- quantityRequested Column -->
                <ng-container matColumnDef="quantityRequested">
                    <th mat-header-cell *matHeaderCellDef>Cantidad</th>
                    <!-- style="max-width: 60px !important; padding-right: 2% !important;" -->
                    <td mat-cell *matCellDef="let order, let i = index;" class="max-width-80-important">
                        <!-- <div fxFlex="80"> -->
                        <div *ngIf="this.deliveringOrder">
                            <span
                                class="text-input">{{this.itemsFormArray['controls'][i].get(this.ITEM_QUANTITY).value}}</span>
                            <span class="text-secondary text-input">
                                {{this.itemsFormArray['controls'][i].get(this.ITEM_UNIT).value+'/s'}}
                            </span>
                        </div>
                        <div *ngIf="!this.deliveringOrder">
                            <div class="padding-top-15-important">
                                <app-number-input [name]="this.ITEM_QUANTITY" [form]="getItemsFormArray(i)"
                                    [money]='false'
                                    [suffix]="this.itemsFormArray['controls'][i].get(this.ITEM_UNIT).value+'/s'"
                                    [suffixClass]="'text-secondary'" class="text-input" [readonly]="true">
                                </app-number-input>
                            </div>
                        </div>
                        <!-- </div> -->
                    </td>
                </ng-container>
                <!-- priceRequested Column -->
                <ng-container matColumnDef="priceRequested">
                    <!-- style="border-right: 1px solid;" -->
                    <th mat-header-cell *matHeaderCellDef>Costo unitario</th>
                    <!-- style="max-width: 60px !important; padding-right: 2% !important;" -->
                    <td mat-cell *matCellDef="let order; let i = index;" class="max-width-80-important">
                        <span *ngIf="this.deliveringOrder" class="text-input">
                            {{this.itemsFormArray['controls'][i].get(this.ITEM_PRICE).value | currency:
                            'ARS':'symbol':'1.2'}}
                        </span>
                        <div class="padding-top-15-important" *ngIf="!this.deliveringOrder">
                            <app-number-input [name]="this.ITEM_PRICE" [form]="getItemsFormArray(i)" [money]='true'
                                class="text-input" [readonly]="true">
                            </app-number-input>
                        </div>
                    </td>
                </ng-container>
                <!-- quantityDelivery Column -->
                <ng-container matColumnDef="quantityDelivery">
                    <th mat-header-cell *matHeaderCellDef>Cantidad</th>
                    <!-- style="max-width: 75px !important; padding-right: 2% !important;" -->
                    <td mat-cell *matCellDef="let order; let i = index;" class="max-width-100-important">
                        <!-- <div fxFlex="80"> -->
                        <div class="padding-top-15-important">
                            <app-number-input [name]="this.ITEM_QUANTITY_DELIVERY" [form]="getItemsFormArray(i)"
                                [money]='false'
                                [suffix]="this.itemsFormArray['controls'][i].get(this.ITEM_UNIT).value+'/s'"
                                [suffixClass]="'text-secondary'" class="text-input">
                            </app-number-input>
                        </div>
                        <!-- </div> -->
                    </td>
                </ng-container>
                <!-- priceDelivered Column -->
                <ng-container matColumnDef="priceDelivery">
                    <th mat-header-cell *matHeaderCellDef>Costo unitario</th>
                    <td mat-cell *matCellDef="let order; let i = index;" class="max-width-80-important">
                        <div class="padding-top-15-important">
                            <app-number-input [name]="this.ITEM_PRICE_DELIVERY" (change)="this.onChangeCost(i)"
                                [form]="getItemsFormArray(i)" [money]='true' class="text-input">
                            </app-number-input>
                        </div>
                    </td>
                </ng-container>
                <!-- priceDelivery Margin -->
                <ng-container matColumnDef="marginDelivery">
                    <th mat-header-cell *matHeaderCellDef>Margen</th>
                    <td mat-cell *matCellDef="let order; let i = index;">
                        <div class="flex flex-70 padding-top-15-important">
                            <app-number-input [name]="this.ITEM_MARGIN" [form]="getItemsFormArray(i)" [money]='false'
                                class="text-input" (change)="this.calculateTotalValue(i)"
                                (keydown.enter)="$event.preventDefault()" [suffix]="'%'">
                            </app-number-input>
                        </div>
                    </td>
                </ng-container>
                <!-- priceDelivery Fee -->
                <ng-container matColumnDef="feeDelivery">
                    <th mat-header-cell *matHeaderCellDef>Valor venta unitario</th>
                    <!-- style="max-width: 75px !important; padding-right: 2% !important;" -->
                    <td mat-cell *matCellDef="let order; let i = index;" class="max-width-80-important">
                        <!-- <div fxFlex="70"> -->
                        <div class="padding-top-15-important">
                            <app-number-input [name]="this.ITEM_FEE" [form]="getItemsFormArray(i)"
                                (change)="this.calculateMargin(i)" (keydown.enter)="$event.preventDefault()"
                                [money]='true' class="text-input">
                            </app-number-input>
                        </div>
                        <!-- </div> -->
                    </td>
                </ng-container>
                <!-- priceDelivery Kairos -->
                <ng-container matColumnDef="fixedPriceDelivery">
                    <th mat-header-cell *matHeaderCellDef>Kairos</th>
                    <!-- style="max-width: 75px !important; padding-right: 2% !important;" -->
                    <td mat-cell *matCellDef="let order; let i = index;">
                        <ng-container *ngIf="this.itemsFormArray.at(i).value.visibleCheckKairos">
                            <div class="padding-left-20-important">
                                <app-checkbox-input [name]="this.ITEM_KAIROS" [form]="getItemsFormArray(i)"
                                    class="text-input" (change)="onChangeCheckKairos( i, $event )"
                                    (keydown.enter)="$event.preventDefault()">
                                </app-checkbox-input>
                            </div>
                        </ng-container>
                    </td>
                </ng-container>
                <!-- Master Head 1 -->
                <ng-container matColumnDef="requestedSupplies">
                    <th mat-header-cell *matHeaderCellDef colspan="3" class="text-center">
                        <span>Solicitado</span>
                    </th>
                </ng-container>
                <!-- Master Head 2 -->
                <ng-container matColumnDef="deliverySupplies">
                    <th mat-header-cell *matHeaderCellDef colspan="2" class="text-center vertical-left-line">
                        <span>Entregado</span>
                    </th>
                </ng-container>

                <!-- Master Head 3 -->
                <ng-container matColumnDef="deliverySuppliesValues">
                    <th mat-header-cell *matHeaderCellDef colspan="3" class="text-center vertical-left-line">
                        <span>Valores de venta</span>
                    </th>
                </ng-container>

                <!-- Footer column -->
                <!-- style="padding-top: 15px; padding-right: 14px; padding-left: 20px;" -->
                <!-- <ng-container matColumnDef="footerLeft">
          <td mat-footer-cell *matFooterCellDef colspan="3">
            <div class="flex-column center-center pts-3">
              <div class="flex-column center-start">
                <span class="subtitle-3 text-primary-darker mbs-0">Fecha pautada</span>
                <app-date-picker [name]="this.ORDER_DATE" [form]="this.formModifyOrder" [placeholder]="'Fecha pautada'">
                </app-date-picker>
              </div>
            </div>
          </td>
        </ng-container> -->
                <!-- Footer column -->
                <!-- style="padding-top: 15px; padding-left: 14px" -->
                <!-- class="vertical-left-line" -->
                <!-- <ng-container matColumnDef="footerRight">
            <td mat-footer-cell *matFooterCellDef colspan="5">
              <div class="flex-column center-center pts-3">
                <div class="flex-column center-start">
                  <span class="subtitle-3 text-primary-darker mbs-0">Fecha entregada</span>
                  <app-date-picker *ngIf="this.deliveringOrder" [name]="this.ORDER_DATE_DELIVERY" [form]="this.formModifyOrder" [placeholder]="'Fecha entregada'">
                  </app-date-picker>
                </div>
              </div>
            </td>
        </ng-container> -->

                <ng-container *ngIf="this.deliveringOrder">
                    <tr mat-header-row
                        *matHeaderRowDef="['requestedSupplies','deliverySupplies', 'deliverySuppliesValues']"
                        class="height-1-5rem"></tr>
                </ng-container>
                <tr mat-header-row
                    *matHeaderRowDef="this.deliveringOrder ? displayedColumnsSuppliesDeliveringOrder : displayedColumnsSupplies"
                    class="height-1-5rem"></tr>
                <tr mat-row class="bg-on-hover parentShowOnHover"
                    *matRowDef="let row; columns: this.deliveringOrder ? displayedColumnsSuppliesDeliveringOrder : displayedColumnsSupplies;">
                    <!-- <tr mat-footer-row *matFooterRowDef="this.deliveringOrder ? ['footerLeft','footerRight'] : ['footerLeft']"></tr> -->
            </table>

            <!-- Dates ordered and delivered -->
            <div class="flex-row align-space-around-center mts-3">
                <div class="flex-column align-center-start">
                    <span class="subtitle-3 text-primary-darker mbs-0">Fecha pautada</span>
                    <app-date-picker [name]="this.ORDER_DATE" [form]="this.formModifyOrder"
                        [placeholder]="'Fecha pautada'">
                    </app-date-picker>
                </div>

                <div class="flex-column align-center-start">
                    <span class="subtitle-3 text-primary-darker mbs-0">Fecha entregada</span>
                    <app-date-picker *ngIf="this.deliveringOrder" [name]="this.ORDER_DATE_DELIVERY"
                        [form]="this.formModifyOrder" [placeholder]="'Fecha entregada'">
                    </app-date-picker>
                </div>
            </div>
        </ng-container>

        <div class="mys-3 flex-row space-between gap-2rem">
            <div class="flex flex-column flex-50">
                <span class="subtitle-3 text-primary-darker">Observaciones del pedido</span>
                <app-text-area-input class="flex-100" [hint]="'Opcional'" [name]="this.ORDER_OBSERVATIONS"
                    [form]="this.formModifyOrder" [maxLength]="120">
                </app-text-area-input>
            </div>
            <div class="flex-45" *ngIf="this.deliveringOrder">
                <div class="flex-column flex">
                    <span class="subtitle-3 text-primary-darker">Cargar remito</span>
                    <div class="flex-column mt-2">
                        <file-pond #myPond fxFlex="95" [options]="pondOptions" (oninit)="pondHandleInit()"
                            (onaddfile)="pondHandleAddFile($event)" (onremovefile)="pondHandleRemoveFile($event)"
                            (onaddfilestart)="pondHandleProcessFile($event)" allowImageResize="true"
                            allowImagePreview="true">
                        </file-pond>
                        <span class="text-secondary-darker" style="font-size: 12px; text-align: center;">Extensiones de
                            archivo permitidas: .pdf/.png</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-row align-start-center gap-2rem">
            <!-- Billable - Pasar a facturable -->
            <app-checkbox-input
                *ngIf="this.deliveringOrder && (this.actualOrder.state.id != this.ORDERS_STATES.ENTREGADO)"
                class="mbs-2" [labelPosition]="'after'" [form]="this.formModifyOrder" [label]="'Pasar a facturable'"
                [name]="this.IS_BILLABLE" (change)="onChangeCheckIsBillable( $event )"
                (keydown.enter)="$event.preventDefault()">
            </app-checkbox-input>
        </div>

        <!-- Error if check billable and exists Kairos items  -->
        <div class="flex-row align-start-center" *ngIf="this.isBillable && this.existsCheckItemsWithKairos()">
            <mat-icon class="text-warn">error_outline</mat-icon>
            <mat-error class="mls-3">
                Existen insumos que tienen asignado valor Kairos, asigne el valor correspondiente y vuelva a intentar
            </mat-error>
        </div>

        <div class="mys-2 " *ngIf="this.actualOrder?.state?.id != this.ORDERS_STATES.CANCELADO"
            class="flex-row center-center">
            <mat-checkbox *ngIf="!this.deliveringOrder" class="mrs-5 pr-2"
                [matTooltip]="'Una vez que el pedido fue confirmado no se puede editar'" color="primary"
                [formGroup]="this.formModifyOrder" formControlName="{{this.ORDER_CONFIRMED}}"
                class="text-secondary-darker">Confirmar Pedido (Quedará 'En Curso')
            </mat-checkbox>
        </div>
        <!-- Buttons -->
        <div class="mts-3 flex-row align-space-around-start ap-15px">
            <button mat-stroked-button [color]="'primary'" type="button" (click)="onClickCloseDialog()"
                [disabled]="(isLoadingUpdatingOrder$ | async) || this.loadingAddFile">
                CANCELAR
            </button>
            <button mat-flat-button [color]="'primary'" type="submit"
                [disabled]="(isLoadingUpdatingOrder$ | async) || !this.formModifyOrder.valid || this.loadingAddFile || !this.editingOrder || (this.isBillable && this.existsCheckItemsWithKairos())">
                <!--|| (this.actualOrder.state.id == this.ORDERS_STATES.ENTREGADO)-->
                <span *ngIf="!(isLoadingUpdatingOrder$ | async) else loadingIcon">
                    {{this.deliveringOrder && (this.actualOrder.state.id != this.ORDERS_STATES.ENTREGADO)
                    ? "CONFIRMAR ENTREGA"
                    : this.deliveringOrder && (this.actualOrder.state.id === this.ORDERS_STATES.ENTREGADO)
                    ? "CONFIRMAR"
                    : "MODIFICAR PEDIDO"}}
                </span>
                <ng-template #loadingIcon>
                    <mat-icon>
                        <mat-spinner color="accent" diameter="20">
                        </mat-spinner>
                    </mat-icon>
                </ng-template>
            </button>
        </div>
    </form>
</ng-template>

<mat-menu #menuActions="matMenu">
    <ng-template matMenuContent let-order="order">
        <button mat-menu-item (click)="this.openModifyOrderDialog(order, false)"
            *ngIf="order.state.id == this.ORDERS_STATES.ENTREGADO || order.state.id == this.ORDERS_STATES.CANCELADO">
            Ver detalle </button>
        <button mat-menu-item
            *ngIf="order.state.id == this.ORDERS_STATES.EN_CURSO || order.state.id == this.ORDERS_STATES.CREADO || order.state.id == this.ORDERS_STATES.EN_CURSO"
            (click)="this.openModifyOrderDialog(order, true)">
            {{order.state.id == this.ORDERS_STATES.EN_CURSO ? 'Entregar' : 'Confirmar'}}
        </button>
        <button mat-menu-item (click)="onClickGenerateDeliveryNote(order)">
            Generar remito
        </button>
        <button mat-menu-item *ngIf="order.state.id == this.ORDERS_STATES.ENTREGADO"
            [disabled]="order.state.id == this.ORDERS_STATES.CANCELADO"
            (click)="this.openModifyOrderDialog(order, true)">
            Editar
        </button>
        <button mat-menu-item
            *ngIf="order.state.id == this.ORDERS_STATES.EN_CURSO || order.state.id == this.ORDERS_STATES.CREADO"
            (click)="onClickDeleteOrder(order)">
            Cancelar
        </button>
        <button mat-menu-item *ngIf="order.state.id == this.ORDERS_STATES.ENTREGADO" (click)="this.revertState(order)">
            Revertir estado
        </button>
        <button mat-menu-item *ngIf="order.state.id == this.ORDERS_STATES.ENTREGADO"
            (click)="this.showDeliveryNote(order)">
            Ver remito
        </button>
    </ng-template>
</mat-menu>

<ng-template #popUpMessage>
    <div class="flex-column center-none">
        <!-- Header -->
        <div class="flex-column gap-1_5em">
            <div class="flex-row center">
                <mat-icon svgIcon="WarningCircle" style="height: 55px; width: 55px;"></mat-icon>
            </div>
            <span class="subtitle-2 active-case-text-margin" style="text-align: center;">
                {{ this.message }}
            </span>
        </div>

        <!-- Table -->
        <div *ngIf="this.arrayTable?.length > 0" class="mt-2 flex-column center" class="flex-50">
            <section class="table-container" tabindex="0">
                <table mat-table [dataSource]="this.arrayTable" class="pop-up-table">
                    <!-- Supplyname -->
                    <ng-container matColumnDef="supplyName">
                        <td mat-cell *matCellDef="let item">
                            {{ item.supplyName }}
                        </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <!-- Quantity -->
                    <ng-container matColumnDef="quantity">
                        <td mat-cell *matCellDef="let item">
                            {{ item.quantity }}
                        </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <!-- Provider -->
                    <ng-container matColumnDef="provider">
                        <td mat-cell *matCellDef="let item">
                            {{ item.provider }}
                        </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsPopUp;"></tr>
                </table>
            </section>
        </div>

        <!-- Bottom buttons -->
        <div class="flex-column center-none m-2 border-data">
            <div class="flex-row align-space-evenly center">
                <!--  -->
                <button mat-stroked-button (click)="onClickCancel()" [color]="'primary'">
                    {{txtCancelBtn}}
                </button>
                <button mat-flat-button (click)="onClickUpdateOrder()" *ngIf="this.showConfirmBtn" [color]="'primary'">
                    {{txtConfirmBtn}}
                </button>
            </div>
        </div>
    </div>
<ng-template>
