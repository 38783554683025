import { CommonModule } from '@angular/common';
import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter, effect, input } from '@angular/core';
import { MaterialModule } from 'src/app/material/material.module';
import { FormElementComponent } from '../form-element/form-element.component';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AddressData } from '../../../models/location'
import { Address } from 'src/app/shared/models/address';
import { log } from 'console';
import { Logger } from 'sass';

@Component({
    selector: 'app-address-input',
    templateUrl: './address-input.component.html',
    styleUrl: './address-input.component.scss'
})
export class AddressInputComponent extends FormElementComponent implements OnInit {
    city = input<string>();
    province = input<string>();
    address = input<Address | AddressData>();
    @Input() maxLength!: number;
    @Input() clearable = false;
    @Input() selectAllOnClick: boolean = false;
    @Input() getCheckState: boolean = null;
    citySelected:string=null
    provinceSelected:string=null
    addressSelected:Address | AddressData
    street:string;
    streetNumber:string;
    fullAddress:string;

    @Output() changes = new EventEmitter();
    @Output() selected = new EventEmitter<AddressData>();
    @Output() addressWithoutNumber = new EventEmitter<boolean>();
    @ViewChild('textInput') textInput!: ElementRef;
    @ViewChild('addressinput', { static: false }) addressinputRef: ElementRef<HTMLInputElement>;
    formAddress: FormGroup;
    public readonly ADDRESS_STREET = 'streetAddress';
    public readonly ADDRESS_WITHOUT_NUMBER = 'withoutNumber';
    private autocomplete!: google.maps.places.Autocomplete;
    private _isAddressValid: boolean = true;
    private _enabledForm: boolean = false;
    private _selectedAddress: AddressData;
    private selectedValidAddress: string | null = null;

    set enabledForm(value: boolean) {
        this._enabledForm = value;
        if (this.formAddress) {
            if (value === true) {
                this.formAddress.enable();
                // Si ya sabés que la dirección seleccionada NO trae número,
                // habilitás el checkbox. Si trae número, lo dejás disable:
                if (this.selectedAddress?.streetNumber === "") {
                    this.formAddress.get(this.ADDRESS_WITHOUT_NUMBER)?.enable();
                } else {
                    this.formAddress.get(this.ADDRESS_WITHOUT_NUMBER)?.disable();
                }
            } else {
                this.formAddress.disable();
            }
        }
    }


    get enabledForm() {
        return this._enabledForm
    }

    set isAddressValid(value: boolean) {
        this._isAddressValid = value
        const control = this.formAddress.get(this.ADDRESS_STREET);
        if (value) {
            control?.setErrors(null);
        } else {
            control?.setErrors({ invalid: true });
        }
    }

    get isAddressValid() {
        return this._isAddressValid
    }

    set selectedAddress(value: AddressData) {
        this._selectedAddress = value;

        const withoutNumberControl = this.formAddress.get(this.ADDRESS_WITHOUT_NUMBER);

        // Reiniciamos el valor del checkbox
        withoutNumberControl?.setValue(null);

        if (value.streetNumber === "") {
          // Sin número: habilitamos y forzamos el validador requiredTrue
          withoutNumberControl?.enable();
          withoutNumberControl?.setValidators(Validators.requiredTrue);
          withoutNumberControl?.markAsTouched();
          withoutNumberControl?.updateValueAndValidity();
        } else {
          // Con número: eliminamos validadores y errores
          withoutNumberControl?.enable();
          withoutNumberControl?.clearValidators();

          // Limpiar expresamente los errores y resetear touched/pristine
          withoutNumberControl?.setErrors(null);
          withoutNumberControl?.markAsPristine();
          withoutNumberControl?.markAsUntouched();

          // También reiniciamos el valor a null
          withoutNumberControl?.setValue(false);

          // Actualizamos la validez para que se reflecte la limpieza
          withoutNumberControl?.updateValueAndValidity();

          // Finalmente, deshabilitamos el control (ya que no se necesita para direcciones con número)
          withoutNumberControl?.disable();
        }

        this.selected.emit(value);
      }


    get selectedAddress() {
        return this._selectedAddress;
    }
    get patientStreet() {
        return this.formAddress.get(this.ADDRESS_STREET).value;
    }

    constructor(private fb: FormBuilder) {
        super();

        effect(() => {
            //APLICAMOS RESTRICCIONES A LA BÚSQUEDA
            //HABILITAMOS EL FORM SOLO SI LA CIUDAD A SIDO SELECCIONADA (SOLO ADMICIONES)
            if (!!this.city() && !!this.province()) {
                this.enabledForm = true;
                this.citySelected = this.city()
                this.provinceSelected = this.province()
                this.setCityBounds(this.citySelected, this.provinceSelected);
                this.formAddress.get(this.ADDRESS_STREET)?.setValue('');

            }
            if(!!this.address){
                this.addressSelected = this.address()
                this.street = this.addressSelected.street;
                this.streetNumber = this.addressSelected?.streetNumber || '';
                this.fullAddress = `${this.street} ${this.streetNumber}`;
                this.formAddress.get(this.ADDRESS_STREET).patchValue(this.fullAddress);
            }
        });
    }

    override ngOnInit(): void {
        this.formAddress = this.fb.group({
            [this.ADDRESS_STREET]: [{ value: '', disabled: !this.enabledForm }, Validators.required],
            [this.ADDRESS_WITHOUT_NUMBER]: [{ value: !!this.getCheckState ? this.getCheckState : null, disabled: true }]
        });

        // Suscribirse a los cambios en el input de dirección para validarlo
        this.formAddress.get(this.ADDRESS_STREET)?.valueChanges.subscribe((value) => {
            const control = this.formAddress.get(this.ADDRESS_STREET);
            if (control?.dirty) {
                // Si el campo está sucio y el valor no coincide con el valor original seleccionado, marca el error
                if (value !== this.selectedValidAddress) {
                    control.setErrors({ invalidAddress: true });
                } else {
                    control.setErrors(null);
                }
            }
        });

        this.formAddress.get(this.ADDRESS_WITHOUT_NUMBER)?.valueChanges.subscribe(value => {
            if (value == true || value == false) {
                this.addressWithoutNumber.emit(value)
            }
        });

    }


    ngAfterViewInit(): void {
        // Inicializamos el Autocomplete con las opciones ajustadas
        this.initializeAutocomplete();

        // Listener para manejar la selección del lugar
        this.autocomplete.addListener('place_changed', () => {
          const place = this.autocomplete.getPlace();

          // Validamos que el lugar tenga los componentes necesarios
          const street = place.address_components?.find((c) => c.types.includes('route'))?.long_name;
          const streetNumber = place.address_components?.find((c) => c.types.includes('street_number'))?.long_name;

          if (!street) {
            console.error('Error: La dirección seleccionada no tiene una calle válida:', place);
            return;
          }

          const fullAddress = streetNumber ? `${street} ${streetNumber}` : street;

          // Actualizamos el formulario con la dirección válida
          this.formAddress.get(this.ADDRESS_STREET)?.setValue(fullAddress);
          this.formAddress.get(this.ADDRESS_STREET)?.setErrors(null);

          // Guardamos en `selectedAddress` respetando el proceso anterior
          this.selectedAddress = {
            street,
            streetNumber: streetNumber || '',
            city: place.address_components?.find((c) => c.types.includes('locality'))?.long_name || '',
            province: place.address_components?.find((c) => c.types.includes('administrative_area_level_1'))?.long_name || '',
            country: place.address_components?.find((c) => c.types.includes('country'))?.long_name || '',
            latitude: place.geometry?.location.lat() || null,
            longitude: place.geometry?.location.lng() || null,
          };

          console.log('Dirección válida seleccionada:', this.selectedAddress);
        });
      }

      private initializeAutocomplete(): void {
        const options = {
          types: ['address'], // Limita los resultados a direcciones
          componentRestrictions: { country: 'ar' }, // Restricción a Argentina
          fields: ['address_components', 'geometry'], // Limita los campos devueltos
          strictBounds: true, // Habilita límites estrictos
        };

        // Configuramos Autocomplete
        this.autocomplete = new google.maps.places.Autocomplete(this.addressinputRef.nativeElement, options);
      }

    selectAll() {
        if (this.selectAllOnClick) {
            const input = this.textInput.nativeElement;
            input.select();
        }
    }

    getError() {
        const control = this.form.get(this.name);
        if (control && control.errors) {
            return this.objectValues(control.errors)[0];
        }
        return null;
    }

    hasError(errorType: string): boolean {
        const control = this.form.get(this.name);
        return control && control.hasError(errorType) && (control.dirty || control.touched);
    }

    // Función para mapear los componentes de la dirección
    private getAddressComponents(addressComponents: google.maps.GeocoderAddressComponent[]): AddressData {
        let street = '';
        let streetNumber = '';
        let city = '';
        let province = '';
        let country = '';

        addressComponents.forEach(component => {
            const componentType = component.types[0];

            switch (componentType) {
                case 'route':
                    street = component.long_name; // Calle
                    break;
                case 'street_number':
                    streetNumber = component.long_name; // Número de la calle
                    break;
                case 'locality':
                    city = component.long_name.replace('Ciudad de ', "").trim(); //Elimina Ciudad de y guarda el resto.
                    break;
                case 'administrative_area_level_1':
                    province = component.long_name.replace('Provincia de ', '').trim(); // Elimina 'Provincia de' y guarda el resto
                    break;
                case 'country':
                    country = component.long_name; // País
                    break;
            }
        });

        return {
            street,
            streetNumber,
            city,
            province,
            country
        };
    }

    // Función para obtener las coordenadas de una ciudad y provincia
    private async setCityBounds(city: string, province: string): Promise<void> {
        const geocoder = new google.maps.Geocoder();
        const address = `${city}, ${province}, Argentina`;

        try {
            const { results } = await geocoder.geocode({ address });
            if (results && results.length > 0) {
                const location = results[0].geometry.location;
                // Calcula un área más amplia alrededor del punto central de la ciudad
                const bounds = new google.maps.LatLngBounds();

                // Expande los límites para incluir un área alrededor de la ciudad
                const offset = 0.05; // Este valor define el tamaño de la caja en latitud y longitud. Ajusta según el tamaño de la ciudad.

                bounds.extend(new google.maps.LatLng(location.lat() - offset, location.lng() - offset));
                bounds.extend(new google.maps.LatLng(location.lat() + offset, location.lng() + offset));

                // Ajusta los límites del Autocomplete con los límites calculados
                this.autocomplete.setBounds(bounds);
                this.autocomplete.setOptions({ strictBounds: true });  // Mantén los límites estrictos
            } else {
                console.error('No se encontraron resultados para la dirección:', address);
            }
        } catch (error) {
            console.error('Error al geocodificar la dirección:', error);
        }
    }
}
