import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Case } from 'src/app/shared/models/case';
import { HttpHeaders, HttpParams, HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { APIS } from '../enums/apis';
import { Address } from 'src/app/shared/models/address';
import { RestUtilitiesService } from './rest-utilities.service';
import { CaseState } from 'src/app/shared/models/caseState';
import { CaseTransitionReason } from 'src/app/shared/models/caseTransitionReason';
import { Diagnosis } from 'src/app/shared/models/diagnosis';
import { EvolutionPdfQPS } from 'src/app/abstraction/cases.facade';
import { Pagination } from 'src/app/shared/models/pagination';
import { Item } from 'src/app/shared/models/item';
import { OrderState } from 'src/app/shared/models/orderState';
import { Order } from 'src/app/shared/models/order';
import { HttpBaseResponse } from 'src/app/shared/models/httpBaseResponse';
import { VatCategory } from 'src/app/shared/models/vatCategory';
import { PatientsQPS } from './patients.service';
import { Patient } from 'src/app/shared/models/patient';
import { CaseArrangement } from 'src/app/shared/models/caseArrangement';
import { Practice } from '../../shared/models/practice';
import { Tag } from '../../shared/models/tags';
import { Complexity } from 'src/app/shared/models/complexity';
import { CompanyType } from '../../shared/models/companyType';
import { Location } from '../../shared/models/location';
import { ProvisionFeesIndicatorsQPS, ProvisionalControlIndicatorsQPS } from 'src/app/shared/models/provisionalControlIndicators';
import { Arrangement } from '../../shared/models/arrangement';
import { ArrangementItem } from 'src/app/shared/models/arrangementItem';
import * as moment from 'moment';
import { PostProvisionBody } from './provisions.service';
import { CaseDateFilters, CaseQPS } from '../../abstraction/cases.facade';
import { Agreement } from 'src/app/shared/models/agreement';
import { ProvisionFee } from 'src/app/shared/models/provisionFee';
import { UpdatingCase } from 'src/app/shared/models/submodels/updatingCase';

export interface CompaniesTypeQPS {
    financierId: number
}

export interface CasesWithPages {
    data: Case[],
    pagination: Pagination;
}

export interface ReportQPS {
    fromDate: Date,
    toDate: Date,
}
export interface CasesQPS {
    patientId?: number;
    patientsIds?: number[];
    financierId?: number;
    attentionsStatesIds?: number[];
    attentionsToDate?: Date;
    page?: number,
    size?: number,
    order?: string,
    sort?: string,
    searchCif?: string;
    searchPatient?: string;
    active?: boolean;
}

export interface ReasonsQPS {
    closed?: number;
}

export interface TaxZonesQPS {
    all: boolean;
}

export interface TaxZone {
    id: number,
    description: string
}

export interface PostCaseBody {
    financierOperator?: string,
    financierInternalCode?: string,
    financierId: number,
    affiliationNumber?: string,
    operatorId?: number,

    patient?: PostPatientBody,

    enabledFinancier?: boolean,
    enabledProvider?: boolean,

    diagnoses?: {
        id: number,
        observation?: string
    }[]

    tags?: Tag[],
    complexityCase?: Complexity,
    taxZone: TaxZone,
    companyType: CompanyType,
    emailDate?: Date,
    financierEmail?: string,
    provisions?: PostProvisionBody[],
    supplies?: PostProvisionBody[],
    observation?: string,
    arrangementId?: number,
    arrangement?: Arrangement
}

export interface PostPatientContact {
    email?: string,
    phoneNumber?: string,
    reference?: string
    mainContact?: boolean,
}

export interface PostAddressBody {
    street?: string,
    streetNumber: string,
    floor: string,
    flat: string,
    flatExtra: string,
    location: { id: number },
    latitude?: number,
    longitude?:number
}

export interface PatchAddressBody {
    street?: string,
    streetNumber: string,
    floor: string,
    flat: string,
    flatExtra: string,
    location: Location,
    withoutNumber?:Boolean
}
export interface PostPatientBody {
    email?: string,
    name?: string,
    surname?: string,
    typeDocument?: {
        id: number
    },
    documentNumber?: string;
    birthDate?: Date,
    address?: PostAddressBody
    gender?: string;
    contacts?: PostPatientContact[]
}

// Used to update patient in Case and Entities at the same time
export interface PatchPatientBody {
    id: number,
    name?: string,
    surname?: string,
    typeDocument?: {
        id: number
    },
    documentNumber?: string;
    email?: string,
    birthDate?: Date,
    address?: PatchAddressBody,
    gender?: string;
    contacts?: PostPatientContact[]
}

export interface OrdersQPS {
    page?: number;
    size?: number;
    fromDate?: Date;
    toDate?: Date;
}

export interface PostOrderBody {
    items: Item[];
    orderDate: Date;
    providerId: number;
    observations?: string;
}

export interface PostDerivedProviderBody {
    providerId: number;
}

export interface PatchOrderBody {
    items?: Item[];
    observations?: string;
    state?: OrderState;
    deliveryDate?: Date;
    agreement?: Agreement;
    edition?: boolean;
    stateReversed?: boolean;
    reasonForRevertingOrderStatus?: string;
}

export interface DeliveryNotePDFBody {
    provisionFees: ProvisionFee[],
    patientId: number,
    image?: string
}

export interface CaseArrangementResponse {
    arrangements: CaseArrangement[],
}

export interface PostAddCaseArrangementBody {
    arrangementId: number;
    // fromDate: Date;
}


@Injectable({
    providedIn: 'root',
})
export class CasesService {

    constructor(
        private http: HttpClient,
        private restUtilitiesService: RestUtilitiesService,
    ) { }

    getCase(caseId: number, qps: CaseQPS): Observable<Case> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        const queryParams: HttpParams = qps ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(qps)) : null;
        return this.http
            .get<any>(`${environment.apiCaseService}${APIS.CASES}/${caseId}`, {
                headers: queryHeaders,
                observe: 'response',
                params: queryParams
            })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    getActiveCases(all: boolean = true): Observable<number> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        const queryParams: HttpParams = new HttpParams().append('all', all.toString());

        return this.http
            .get<any>(`${environment.apiCaseService}${APIS.CASES}/indicators/active-cases`, {
                headers: queryHeaders,
                observe: 'response',
                params: queryParams
            })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    getCasesWithoutAttentions(all: boolean): Observable<number> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        const queryParams: HttpParams = new HttpParams().append('all', all.toString());
        return this.http
            .get<any>(`${environment.apiCaseService}${APIS.CASES}/indicators/no-attention-cases`, {
                headers: queryHeaders,
                observe: 'response',
                params: queryParams
            })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    getCasesWithoutPendingAttentions(all: boolean): Observable<number> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        const queryParams: HttpParams = new HttpParams().append('all', all.toString());
        return this.http
            .get<any>(`${environment.apiCaseService}${APIS.CASES}/indicators/non-pending-attention-cases`, {
                headers: queryHeaders,
                observe: 'response',
                params: queryParams
            })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    //TODO: Mover a nomenclator service
    getDiagnoses(): Observable<Diagnosis[]> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http
            .get<any>(`${environment.apiNomenclatorService}${APIS.DIAGNOSES}`, {
                headers: queryHeaders,
                observe: 'response',
            })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    getCases(qps: CasesQPS, version?): Observable<CasesWithPages> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        const queryParams: HttpParams = qps ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(qps)) : null;
        const url = version == 'v2' ? `${environment.apiCaseService}${APIS.CASES}/v2` : `${environment.apiCaseService}${APIS.CASES}/`
        return this.http
            .get<any>(url, {
                headers: queryHeaders,
                observe: 'response',
                params: queryParams
            })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body;
                }),
            );
    }

    getCasesWithoutPagination(qps?: CasesQPS): Observable<Case[]> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        const queryParams: HttpParams = qps ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(qps)) : null;
        return this.http
            .get<any>(`${environment.apiCaseService}${APIS.CASES}`, {
                headers: queryHeaders,
                observe: 'response',
                params: queryParams
            })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    postCase(acase: PostCaseBody): Observable<Case> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http
            .post<any>(`${environment.apiCaseService}${APIS.CASES}`, JSON.stringify(acase), {
                headers: queryHeaders,
                observe: 'response',
            })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    getTransitionReasons(qps?: ReasonsQPS): Observable<CaseTransitionReason[]> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        const queryParams: HttpParams = qps ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(qps)) : null;
        return this.http
            .get<any>(`${environment.apiCaseService}${APIS.REASONS}`, {
                headers: queryHeaders,
                observe: 'response',
                params: queryParams,
            })
            .pipe<any[]>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    getDiagnosisString(acase: Case): string {
        let diagnosisReturn;
        for (let i = 0; !!acase.diagnoses && i < acase.diagnoses.length && !diagnosisReturn; i++) {
            if (!!acase.diagnoses[i].name) {
                diagnosisReturn = acase.diagnoses[i].name
            }
        }
        return !!diagnosisReturn ? diagnosisReturn : 'Sin diagnóstico'
    }

    getAddressString(address: Address): string {
        let addressReturn = '';
        if (!!address.street || !!address.streetNumber) {
            if (!!address.street && !!address.streetNumber) {
                addressReturn += `${address.street} ${address.streetNumber} `
            } else if (!!address.street) {
                addressReturn += `${address.street} `
            }
        }
        return addressReturn ? addressReturn : 'Sin dirección asociada'
    }

    // Done
    postCaseState(caseClosingReason: CaseState, caseId: number, force?: boolean) {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        const queryParams: HttpParams = force ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs({ force })) : null;
        // const { id, ...restWithoutId } = caseClosingReason;
        return this.http
            .post<any>(
                `${environment.apiCaseService}${APIS.CASES}/${caseId}/states`,
                JSON.stringify(caseClosingReason),
                {
                    headers: queryHeaders,
                    observe: 'response',
                    params: queryParams,
                },
            )
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    patchCase(casee: UpdatingCase, caseId: number) {

        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http
            .patch<any>(
                `${environment.apiCaseService}${APIS.CASES}/${caseId}`,
                JSON.stringify(casee),
                {
                    headers: queryHeaders,
                    observe: 'response',
                },
            )
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    getCaseOperationReport(caseId: number, qps?: ReportQPS): Observable<any> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        const queryParams: HttpParams = qps ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(qps)) : null;
        return this.http
            .get<any>(`${environment.apiCaseService}${APIS.CASES}/${caseId}/report`, {
                headers: queryHeaders,
                observe: 'response',
                params: queryParams,
            })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    //**Orders */

    postOrder(entidad: PostOrderBody, caseId: number): Observable<Order> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http
            .post<any>(`${environment.apiCaseService}${APIS.CASES}/${caseId}/orders`, JSON.stringify(entidad), {
                headers: queryHeaders,
                observe: 'response',
            })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    patchOrder(entity: PatchOrderBody, entityId, caseId: number): Observable<Order> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http
            .patch<any>(
                `${environment.apiCaseService}${APIS.CASES}/${caseId}/orders/${entityId}`,
                JSON.stringify(entity),
                {
                    headers: queryHeaders,
                    observe: 'response',
                },
            )
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    getOrders(caseId: number, qps?: OrdersQPS,): Observable<HttpBaseResponse<Order[]>> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        const qpsProcessed: { param: string, value: string }[] = this.restUtilitiesService.formatQPs(qps);
        const queryParams: HttpParams = this.restUtilitiesService.createAndAppendQps(qpsProcessed);
        return this.http
            .get<any>(`${environment.apiCaseService}${APIS.CASES}/${caseId}/orders`, {
                headers: queryHeaders,
                observe: 'response',
                params: queryParams,
            })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body;
                }),
            );
    }

    getInvoicePhotoByOrderId(orderId: number, caseId: number): Observable<any> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http
            .get<any>(`${environment.apiCaseService}${APIS.CASES}/${caseId}/orders/${orderId}/media`, {
                headers: queryHeaders,
                observe: 'response',
            })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    postDerivation(body: PostDerivedProviderBody, caseId: number): Observable<Case> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http
            .post<any>(`${environment.apiCaseService}${APIS.CASES}/${caseId}/derivation`, JSON.stringify(body), {
                headers: queryHeaders,
                observe: 'response',
            })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    getVatCategories(): Observable<VatCategory[]> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http
            //CAMBIAR URL
            // .get<any>('http://localhost:3000/vatCategory', {
            .get<any>(`${environment.apiCaseService}${APIS.VAT_CATEGORIES}`, {
                headers: queryHeaders,
                observe: 'response',
            })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    /* PATIENTS */

    getPatients(qps?: PatientsQPS): Observable<Patient[]> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        const qpsProcessed: { param: string, value: string }[] = this.restUtilitiesService.formatQPs(qps);
        const queryParams: HttpParams = this.restUtilitiesService.createAndAppendQps(qpsProcessed);
        return this.http
            .get<any>(`${environment.apiCaseService}${APIS.PATIENTS}`, {
                headers: queryHeaders,
                observe: 'response',
                params: queryParams,
            })
            /**
             * !Ojo que falta el .data
             */
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                    // return response.body;
                }),
            );
    }
    getCaseArrangements(caseId: number): Observable<CaseArrangement[]> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http
            .get<any>(`${environment.apiCaseService}${APIS.CASES}/${caseId}/arrangements`, {
                headers: queryHeaders,
                observe: 'response',
            })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data.caseArrangement.arrangements;
                }),
            );
    }

    postCaseArrangement(caseId: number, body: PostAddCaseArrangementBody): Observable<CaseArrangement> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http
            .post<any>(`${environment.apiCaseService}${APIS.CASES}/${caseId}/arrangements`, JSON.stringify(body), {
                headers: queryHeaders,
                observe: 'response',
            })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data.arrangement;
                }),
            );
    }

    deleteCaseArrangement(caseArrangementId: number): Observable<CaseArrangement> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http
            .delete<any>(`${environment.apiCaseService}${APIS.USER}/caseArrangements/${caseArrangementId}`, {
                headers: queryHeaders,
                observe: 'response',
            })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    sortArrangementitem(arrangementItems: ArrangementItem[]): ArrangementItem[] {

        // Only active items
        arrangementItems = arrangementItems.filter(item => item.active);

        arrangementItems.sort((a, b) => {
            if (a && a.fromDate) {
                const aFromDate = moment(a.fromDate);
                const bFromDate = moment(b.fromDate);
                return aFromDate.isSame(bFromDate) ? 0 : (aFromDate.isAfter(bFromDate) ? 1 : -1);
            }
            return -1;
        });
        // Set fromDate and toDate without UTC
        arrangementItems = arrangementItems.map(item => ({
            ...item,
            fromDate: moment(moment(item.fromDate).utc().format('YYYY-MM-DD')).toDate(),
            toDate: moment(moment(item.toDate).utc().format('YYYY-MM-DD')).toDate()
        }))

        // Replace todaDate with 1 day previous to next item (fromDate) - reemplaza el toDate del actual restándole uno al fromDate del siguiente
        const countPractices = arrangementItems.reduce((a, b) => ({
            ...a,
            [b.practiceId]: (a[b.practiceId] || 0) + 1
        }), {})
        let arrangementItemsFiltered = null;
        let finalArrangement = [];
        for (let practiceId in countPractices) {
            arrangementItemsFiltered = arrangementItems.filter(item => item.practiceId.toString() === practiceId)
            arrangementItemsFiltered.forEach((item, index) => {
                let next = index + 1;
                if (!!arrangementItemsFiltered[next]) {
                    item.toDate = moment(arrangementItemsFiltered[next].fromDate).subtract(1, 'day').toDate()
                } else {
                    item.toDate = null
                }
            })
            finalArrangement = [...finalArrangement, ...arrangementItemsFiltered]
        }
        return finalArrangement
    }

    // TODO:
    getPreviousItemForPractice() {
    }

    /**
     * @param practicesArragement It must ordered by practice and date -> REMEMBER, previous call sortArrangementitem()
     * @param practiceId Looking for this practice
     * @param date Chek what fee/price apply
     */
    getActualItemForPractice(practicesArragement: ArrangementItem[], practiceId: number, date: Date): ArrangementItem | null {

        let practices = practicesArragement.filter(practice => practice.practiceId == practiceId);
        let index = practices.findIndex(practice => practice.fromDate > date);
        return (index != -1 && !!practices[index - 1]) ? practices[index - 1] :
            index == 0 ? null : practices.length > 0 ? practices[practices.length - 1] : null;
    }

    // TODO:
    getNextItemForPractice() {
    }

    // COMPANIES TYPE
    getCompaniesType(qps): Observable<HttpBaseResponse<CompanyType[]>> {

        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        const qpsProcessed: { param: string, value: string }[] = this.restUtilitiesService.formatQPs(qps);
        const queryParams: HttpParams = this.restUtilitiesService.createAndAppendQps(qpsProcessed);
        return this.http
            .get<any>(`${environment.apiNomenclatorService}${APIS.COMPANIES_TYPE}`, {
                headers: queryHeaders,
                observe: 'response',
                params: queryParams,
            })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body;
                }),
            );
    }

    getTaxZones(qps: TaxZonesQPS): Observable<TaxZone[]> {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        const qpsProcessed: { param: string, value: string }[] = this.restUtilitiesService.formatQPs(qps);
        const queryParams: HttpParams = this.restUtilitiesService.createAndAppendQps(qpsProcessed);
        return this.http
            .get<any>(`${environment.apiCaseService}${APIS.TAX_ZONES}`, {
                headers: queryHeaders,
                observe: 'response',
                params: queryParams,
            })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    patchPatientCaseAndEntities(patientIdCase: number, patient: PatchPatientBody) {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http
            .patch<any>(
                `${environment.apiCaseService}${APIS.CASE_PATIENTS}/${patientIdCase}`,
                JSON.stringify(patient),
                {
                    headers: queryHeaders,
                    observe: 'response',
                },
            )
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    getProvisionalControlIndicatorValues(provisionalControlIndicatorsQPS: ProvisionalControlIndicatorsQPS, version?: string) {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        const queryParams: HttpParams = provisionalControlIndicatorsQPS ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(provisionalControlIndicatorsQPS)) : null;
        const url = version == 'v2' ? `${environment.apiCaseService}${APIS.CASES}/count/v2` : `${environment.apiCaseService}${APIS.CASES}/count`
        return this.http
            .get<any>(url, {
                headers: queryHeaders,
                observe: 'response',
                params: queryParams
            })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body;
                }),
            );
    }

    getProvisionFeesIndicators(provisionFeesIndicatorsQPS?: ProvisionFeesIndicatorsQPS, version?: string) {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        const queryParams: HttpParams = provisionFeesIndicatorsQPS ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(provisionFeesIndicatorsQPS)) : null;
        const url = version == 'v2' ? `${environment.apiCaseService}${APIS.CASES}/provision-fees/count/v2` : `${environment.apiCaseService}${APIS.CASES}/provision-fees/count`
        return this.http
            .get<any>(url, {
                headers: queryHeaders,
                observe: 'response',
                params: queryParams
            })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    getProvisionalControlProvisionFees(provisionFeesQps) {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        const queryParams: HttpParams = provisionFeesQps ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(provisionFeesQps)) : null;
        return this.http
            .get<any>(`${environment.apiCaseService}${APIS.CASES}/provision-fees/provisional-control-provisionfees`, {
                headers: queryHeaders,
                observe: 'response',
                params: queryParams
            })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body;
                }),
            );
    }

    getOrdersIndicators(qps, version?) {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        const queryParams: HttpParams = qps ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(qps)) : null;
        const url = version == 'v2' ? `${environment.apiCaseService}${APIS.CASES}/order-indicators/v2` : `${environment.apiCaseService}${APIS.CASES}/order-indicators`
        return this.http
            .get<any>(url, {
                headers: queryHeaders,
                observe: 'response',
                params: queryParams
            })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    getProvisionalControlOrders(qps) {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        const queryParams: HttpParams = qps ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(qps)) : null;
        return this.http
            .get<any>(`${environment.apiCaseService}${APIS.CASES}/provisional-control-orders`, {
                headers: queryHeaders,
                observe: 'response',
                params: queryParams
            })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body;
                }),
            );
    }

    getArrangementItemsByPracticeBetweenDates(arrangement: Arrangement, practice: Practice, fromDate: Date, toDate: Date) {

        let practiceArrangementForProvision: ArrangementItem[] = arrangement?.practiceArrangements?.filter(pa => pa.practiceId == practice?.id);
        if (moment(toDate).isValid()) {
            practiceArrangementForProvision = practiceArrangementForProvision?.filter(parr =>

                moment(fromDate).isBetween(parr.fromDate, parr.toDate, undefined, "[]")
                || moment(toDate).isBetween(parr.fromDate, parr.toDate, undefined, "[]")
                || moment(fromDate).isSameOrBefore(parr.fromDate) && ((moment(toDate).isSameOrAfter(parr.toDate)))
                || moment(fromDate).isSameOrBefore(parr.fromDate) && moment(toDate).isSameOrAfter(parr.fromDate) && !parr.toDate
                || moment(fromDate).isSameOrAfter(parr.fromDate) && !parr.toDate
            )
        } else {
            practiceArrangementForProvision = practiceArrangementForProvision.filter(parr =>

                moment(fromDate).isBetween(parr.fromDate, parr.toDate, undefined, "[]")
                || moment(parr.fromDate).isSameOrAfter(fromDate)
                || (moment(fromDate).isSameOrAfter(fromDate) && !parr.toDate))
        }
        return practiceArrangementForProvision;
    }

    getEvolutionsPDF(evolutionsPdfQPS: EvolutionPdfQPS) {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        const queryParams: HttpParams = evolutionsPdfQPS ? this.restUtilitiesService.createAndAppendQps(this.restUtilitiesService.formatQPs(evolutionsPdfQPS)) : null;
        return this.http
            .patch<any>(`${environment.apiCaseService}${APIS.CASES}/evolutionPDF`,
                JSON.stringify(evolutionsPdfQPS),
                {
                    headers: queryHeaders,
                    observe: 'response'
                })
            .pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    generateOrderDeliveryNotePDF(deliveryNoteBody: DeliveryNotePDFBody) {
        const queryHeaders = new HttpHeaders().append('Content-Type', 'application/json');
        return this.http.post(`${environment.apiCaseService}${APIS.CASES}/supplies/order-delivery-note-PDF`,
            deliveryNoteBody,
            {
                headers: queryHeaders,
                observe: 'response',
            }).pipe<any>(
                map<HttpResponse<any>, any>((response) => {
                    return response.body.data;
                }),
            );
    }

    getServiceToTest() {
        return this.http
            .get<any>(`https://reqres.in/api/users`, {
            })
    }
}
