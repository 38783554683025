<mat-card class="flex-100">
    <mat-card-header>
        <mat-card-title>
            Listado de atenciones
        </mat-card-title>
    </mat-card-header>
    <mat-card-content class="p-4">
        <div class="card-shadow p-2 mb-2">
            <form *ngIf="filtersShown && this.filterForm && this.operators" [formGroup]="filterForm"
                (submit)="onFilter()">
                <div class="flex-100 flex-row gap-3em align-center-center">
                    <!-- <mat-icon class="mt-1" color="primary">filter_alt</mat-icon> -->
                    <!--                     <div class="flex-row  flex-30 space-between-center">
                        <button class="mb-5"  type="button" mat-icon-button (click)="onClickNextBeforeDay(false)">
                            <mat-icon color="primary">keyboard_arrow_left</mat-icon>
                        </button>
                        <app-date-picker [name]="this.DATE_FROM" [form]="filterForm"
                            placeholder="Fecha de la atención"></app-date-picker>
                        <button class="mb-5" type="button" mat-icon-button (click)="onClickNextBeforeDay(true)">
                            <mat-icon color="primary">keyboard_arrow_right</mat-icon>
                        </button>
                    </div> -->

                    <!-- Hasta  -->
                    <div class="flex-column flex-20">
                        <span class="subtitle-4 text-primary-darker">
                            <b>Hasta</b> ( {{this.getPeriod()}} )
                        </span>
                        <div>
                            <app-date-picker #datePicker [name]="DATE_TO" [form]="filterForm">
                            </app-date-picker>
                          </div>
                    </div>
                    <div>
                        <span class="subtitle-4 text-primary-darker">
                            <b>Operador</b>
                        </span>
                        <app-select-searching-input class="flex-25" noOptionText="-- Todos --"
                            [defaultValue]="this.metaDataAttentions?.operatorId ? this.metaDataAttentions?.operatorId:'0'"
                            [name]="this.OPERATOR" [nameSearching]="this.OPERATOR_SEARCHING" [form]="this.filterForm"
                            [elements]="this.operators" placeholderNoValue="Sin resultados"
                            placeholderSearching="Buscar operador..."></app-select-searching-input>
                        </div>
                    <div>
                        <span class="subtitle-4 text-primary-darker">
                            <b>Indicador</b>
                        </span>
                        <app-select-input class="flex-25" [name]="this.INDICATOR"
                            [form]="this.filterForm" [elements]="this.indicatorsOptions"
                            [defaultValue]="this.selectedIndicator"
                            (selected)="this.onSelectIndicator($event)"></app-select-input>
                    </div>
                </div>
                <div class="flex-100 flex-row gap-3em align-center-center">
                    <div class="flex-column">
                        <span class="subtitle-4 text-primary-darker">
                            <b>Financiador</b>
                        </span>
                        <app-financier-selector  [name]="this.FINANCIER"
                            [nameSearching]="this.FINANCIER_SEARCHING" [form]="this.filterForm"></app-financier-selector>
                    </div>
                    <app-checkbox-input class="flex-20" [form]="filterForm" label="Incluir casos cerrados"
                        [name]="this.ALL_CASES"></app-checkbox-input>
                </div>
                <div class="flex-row gap-3em align-center-center">
                    <button id="btn_attentionsComponentBuscar" mat-flat-button type="submit" color="primary"
                        [disabled]="!filterForm.valid">BUSCAR</button>
                </div>
            </form>
        </div>
        <div class="card-shadow flex-100 p-2">
            <div class="flex-100 flex-row gap-3em align-center-center  separator separator-center"
                *ngIf="this.filtersShown">
                <span>{{this.getPeriod()}}</span>
            </div>
            <div class="flex-100 my-2">
                <mat-form-field class="flex flex-100" appearance="outline">
                    <!-- <mat-icon matPrefix class="text-primary-lighter">search</mat-icon> -->
                    <mat-label>Ingrese paciente, DNI, prestador, práctica, estado o CIF</mat-label>
                    <input [(ngModel)]="inputValue" matInput placeholder="Búsqueda rápida" #inputSearch />
                </mat-form-field>
            </div>
            <div class="mb-3 flex-column" *ngIf="!isLoadingGettingAttentions ; else loadingAttentions">
                <div class="flex-column" *ngIf="!isLoadingGettingAttentions ; else loadingAttentions">
                    <table mat-table class="flex-100"
                        *ngIf="!!this.dataSourceAttentions && this.dataSourceAttentions.data.length > 0; else  noAttentions"
                        [dataSource]="dataSourceAttentions">
                        <!-- caseId Column -->
                        <ng-container matColumnDef="caseId">
                            <th mat-header-cell *matHeaderCellDef>Caso</th>
                            <td mat-cell *matCellDef="let data"><a>{{ data.caseId }}</a></td>
                        </ng-container>
                        <!-- financier Column -->
                        <ng-container matColumnDef="financier">
                            <th mat-header-cell *matHeaderCellDef>Financiador / CIF</th>
                            <td mat-cell *matCellDef="let data">
                                <div class="inner-cell flex-column">
                                    <span
                                        matTooltip="{{ !!data.financier && !!data.financier.name ? data.financier.name : 'Sin datos' }}">{{
                                        !!data.financier && !!data.financier.name ?
                                        this.generalService.getSummarizeString(data.financier.name,20) : 'Sin datos'
                                        }}</span>
                                    <span *ngIf="!!data.financierInternalCode || data.patient?.documentNumber"
                                        matTooltip="Código interno de financiador" class="text-secondary">#{{
                                        !!data.financierInternalCode ? data.financierInternalCode :
                                        data.patient?.documentNumber}}</span>
                                </div>
                            </td>
                        </ng-container>
                        <!-- patient Column -->
                        <ng-container matColumnDef="patient">
                            <th mat-header-cell *matHeaderCellDef>Paciente</th>
                            <td mat-cell *matCellDef="let data">
                                <div class="inner-cell">
                                    <div *ngIf="!isExpanded" matTooltip="{{data.patient?.name}}">
                                        {{ (data.patient.surname + ' ' + data.patient.name).toString().length > 15 ?
                                        ((data.patient.surname + ' ' + data.patient.name | slice: 0:13) + '...') :
                                        data.patient.surname + ' ' + data.patient.name }}
                                    </div>
                                    <div *ngIf="isExpanded">{{ data.patient.surname + ' ' + data.patient.name }}</div>
                                </div>
                            </td>
                        </ng-container>
                        <!-- patient.documentNumber Column -->
                        <ng-container matColumnDef="patientDocument">
                            <th mat-header-cell *matHeaderCellDef>DNI Paciente</th>
                            <td mat-cell *matCellDef="let data"><span>{{ data?.patient?.documentNumber ?
                                    data?.patient?.documentNumber : 'Sin datos' }}</span></td>
                        </ng-container>
                        <!-- operator Column -->
                        <ng-container matColumnDef="operator">
                            <th mat-header-cell *matHeaderCellDef>Operador/a</th>
                            <td mat-cell *matCellDef="let data">
                                <div class="inner-cell">{{ data.operator.name }}</div>
                            </td>
                        </ng-container>
                        <!-- provider Column -->
                        <ng-container matColumnDef="provider">
                            <th mat-header-cell *matHeaderCellDef>Prestador</th>
                            <td mat-cell *matCellDef="let data">
                                <div class="inner-cell">
                                    <span *ngIf="!isExpanded && this.entitiesFacade.getProvidersFullName(data.provider)"
                                        matTooltip="{{this.entitiesFacade.getProvidersFullName(data.provider)}}">{{
                                        this.generalService.getSummarizeString(this.entitiesFacade.getProvidersFullName(data.provider),
                                        15) }}</span>
                                    <div *ngIf="isExpanded && this.entitiesFacade.getProvidersFullName(data.provider)">
                                        {{this.entitiesFacade.getProvidersFullName(data.provider)}}</div>
                                    <div *ngIf="!(this.entitiesFacade.getProvidersFullName(data.provider))">Sin datos
                                    </div>
                                </div>
                            </td>
                        </ng-container>
                        <!-- state Column -->
                        <ng-container matColumnDef="state">
                            <th mat-header-cell *matHeaderCellDef>Estado</th>
                            <td mat-cell *matCellDef="let attention" matTooltip="{{this.matTooltipText(attention)}}"
                                matTooltipClass="multiline-tooltip">
                                <div class="inner-cell inner-cell-auth">
                                    <mat-chip-listbox *ngIf="!attention?.isLoadingStates">
                                        <mat-chip
                                            style="max-width:max-content; min-width:max-content; width:max-content !important"
                                            [ngStyle]="{'color':this.ATTENTIONS_COLORS[attention.state.id], 'background-color':this.ATTENTIONS_BACKGROUND_COLORS[attention.state.id]}"
                                            selected [disabled]="attention?.isLoadingStates"
                                            (click)="this.stopPropagation($event); !attention?.isLoadingStates ? this.onClickStateAttention(attention):null">
                                            <span class="mat-small">{{ attention.state.name | firstLetterWordsIntoString
                                                }}</span>
                                        </mat-chip>
                                    </mat-chip-listbox>
                                </div>
                                <div><mat-spinner diameter="20" *ngIf="attention?.isLoadingStates"></mat-spinner></div>
                            </td>
                        </ng-container>
                        <!-- alertPending Column -->
                        <ng-container matColumnDef="alertPending">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let attention"><span *ngIf="!!attention.alertPending"><mat-icon
                                        color="warn"
                                        matTooltip="La atención posee tareas sin tratar">warning</mat-icon></span></td>
                        </ng-container>
                        <!-- id Column -->
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef>#</th>
                            <td mat-cell *matCellDef="let attention">{{ attention.id }}</td>
                        </ng-container>
                        <!-- practice Column -->
                        <ng-container matColumnDef="practice">
                            <th mat-header-cell *matHeaderCellDef>Especialidad</th>
                            <td mat-cell *matCellDef="let attention">
                                <div class="inner-cell">{{ attention.practice.name }}</div>
                            </td>
                        </ng-container>
                        <!-- date Column -->
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef>Fecha</th>
                            <td mat-cell *matCellDef="let attention">{{ attention.fromDate | date: 'dd/MM/yyyy'}}</td>
                        </ng-container>
                        <!-- evolucionar Column -->
                        <ng-container matColumnDef="evolution">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let attention">
                                <div class="inner-cell">
                                    <button class="showOnHover" mat-icon-button
                                        *ngIf="this.attentionService.isEvolvable(attention)"
                                        (click)="stopPropagation($event); openEvolutionDialog(attention)">
                                        <mat-icon class="text-secondary"
                                            matTooltip="Evolucionar">playlist_add</mat-icon>
                                    </button>
                                </div>
                            </td>
                        </ng-container>
                        <!-- signature Column -->
                        <ng-container matColumnDef="signature">
                            <th mat-header-cell *matHeaderCellDef>Firmada</th>
                            <td mat-cell *matCellDef="let attention">
                                <div class="inner-cell">
                                    <mat-icon
                                        *ngIf="attention.signed!=null && attention.signed!=undefined && attention.signed"
                                        class="text-primary">verified</mat-icon>
                                    <mat-icon
                                        *ngIf="attention.signed!=null && attention.signed!=undefined && !attention.signed"
                                        class="text-warn">highlight_off</mat-icon>
                                </div>
                            </td>
                        </ng-container>
                        <!-- alerts Column -->
                        <ng-container matColumnDef="alerts">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let attention" style="padding-left: 0px !important;">
                                <div class="inner-cell">
                                    <mat-icon matTooltip="Paciente y prestador nuevos" color="primary"
                                        *ngIf="attention.alertNewPatient && attention.alertNewProvider">supervisor_account</mat-icon>
                                    <mat-icon matTooltip="Prestador nuevo" [style.width.px]="24" [style.height.px]="24"
                                        *ngIf="!attention.alertNewPatient && attention.alertNewProvider" color="primary"
                                        svgIcon="doctor"></mat-icon>
                                    <mat-icon matTooltip="Paciente nuevo" [style.width.px]="24" [style.height.px]="24"
                                        color="primary" *ngIf="attention.alertNewPatient && !attention.alertNewProvider"
                                        svgIcon="patient"></mat-icon>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onClickRow(row)"
                            class="bg-on-hover parentShowOnHover"></tr>
                    </table>
                </div>
            </div>
            <mat-paginator class="fllex-100" [pageSize]="this.pageSize" [pageIndex]="this.pageIndex"
                [pageSizeOptions]="[5, 10, 25, 100]" (page)="onPaginatorEvent()" showFirstLastButtons></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>

<ng-template #loadingAttentions>
    <app-spinner class="flex-100 align-center-start" *ngIf="!this.error; else errorDiv"></app-spinner>
</ng-template>

<ng-template #errorDiv>
    <div class="flex-100 align-center-center">
        <app-spinner-error [msgError]="'Ha ocurrido un error inesperado, inténtelo nuevamente'"></app-spinner-error>
        <span></span>
    </div>
</ng-template>

<ng-template #noAttentions>
    <div *ngIf="!!this.dataSourceAttentions && this.dataSourceAttentions.data.length == 0; else loadingAttentions"
        class="my-5 text-secondary-darker align-center-stretch">
        <b>No se encontraron atenciones para los filtros establecidos</b>
    </div>
</ng-template>

<mat-menu #menuAttentions="matMenu">
    <button mat-menu-item [disabled]="!this.metaDataAttentions?.all" (click)="onChangeMyAttentionFilter(true)">
        <mat-icon>person</mat-icon>
        <span>Mis atenciones</span>
    </button>
    <button mat-menu-item [disabled]="this.metaDataAttentions?.all" (click)="onChangeMyAttentionFilter(false)">
        <mat-icon>group</mat-icon>
        <span>Todas las atenciones</span>
    </button>
</mat-menu>
