import { AbstractControl, ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';
import { GeneralService } from '../services/general.service';

export class CustomValidators {

   static required(message: string): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
         return control && !control.value
            ? {
               required: message,
            }
            : null;
      };
   }

   static number(message: string): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
         return control && !/^\d+$/.test(control.value)
            ? {
               number: message,
            }
            : null;
      };
   }

   static minLength(minLength: number, message: string): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
         if (typeof control.value !== 'number') {
            return control && control.value && control.value.trim().length < minLength
               ? {
                  minLength: message,
               }
               : null;
         } else {
            return control && control.value && control.value.toString().length < minLength
               ? {
                  minLength: message,
               }
               : null;
         }
      };
   }

   static minValue(minValue: number, message: string): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
         if (typeof control.value !== 'number') {
            return {
               minValue: message,
            }
         } else {
            return control && control.value && control.value < minValue
               ? {
                  minValue: message,
               }
               : null;
         }
      };
   }

   static maxValue(maxValue: number, message: string): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
         if (typeof control.value !== 'number') {
            return {
               minValue: message,
            }
         } else {
            return control && control.value && control.value > maxValue
               ? {
                  minValue: message,
               }
               : null;
         }
      };
   }

   static maxLength(maxLength: number, message: string): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
         if (typeof control.value !== 'number') {
            return control && control?.value?.trim().length > maxLength
               ? {
                  maxLength: message,
               }
               : null;
         } else {
            return control && control.value.toString().length > maxLength
               ? {
                  maxLength: message,
               }
               : null;
         }
      };
   }

   static email(message: string): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
         return control && !!control.value &&
            !(new RegExp(
               // tslint:disable-next-line: max-line-length
               /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            ).test(control.value.trim()))
            ? {
               email: message,
            }
            : null
      };
   }

   static cuilCuit(message: string): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
         let value = null;
         if (!!control?.value) {
            value = control.value.toString();
            // console.log(GeneralService.cuilValidator(value));
         }


         return control && !!value && !(GeneralService.cuilValidator(value))
            ? {
               cuit: message,
            }
            : null
      };
   }

   static dateLessOrEqualThan(message: string, date: Date): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
         return control &&
            moment(control.value).isSameOrBefore(moment(date))
            ? null
            : {
               date: message,
            };
      };
   }

   static password(message: string): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
         return control &&
            new RegExp(/^^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=*().,_]).*$/, 'i').test(
               control.value.trim(),
            )
            ? null
            : {
               password: message,
            };
      };
   }

   static phoneNumberInvalidValidator(
      prefixName: string,
      suffixName: string,
      message: string,
   ): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
         const prefix = control.get(prefixName);
         const suffix = control.get(suffixName);

         return prefix && suffix && prefix.value.trim().length + suffix.value.trim().length !== 10
            ? {
               phoneNumberInvalid: message,
            }
            : null;
      };
   }
   static CVFromToDate(
      message: string,
      errName,
      fromName: string,
      toName: string
   ): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
         const fromCtrl = control.get(fromName);
         const toCtrl = control.get(toName);
         return fromCtrl && toCtrl && !!fromCtrl.value && !!toCtrl.value && (new Date(toCtrl.value) <= new Date(fromCtrl.value))
            ? {
               [errName]: message,
            }
            : null;
      };
   }

   static requiredDependingOnControlValue(
      message: string,
      errName,
      requiredName: string,
      dependentName: string,
      dependentValue: any
   ): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
         const requiredCtrl = control.get(requiredName);
         const dependentCtrl = control.get(dependentName);
         return requiredCtrl && dependentCtrl && !requiredCtrl.value && !!dependentCtrl.value && dependentCtrl.value == dependentValue
            ? {
               [errName]: message,
            }
            : null;
      };
   }

   /**
    * !METHOD IN PROGRESS
    * @param message
    * @param errName
    * @param valueRequiredName
    * @param valueRequired
    * @param dependentElements
    */
   private static requiredDependingOnControlValues(
      message: string,
      errName,
      requiredName: string,
      dependentElements: { dependentName: string, dependentValue?: any }[]
   ): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
         const requiredCtrl = control.get(requiredName);
         let errorFlag = false;
         if (!requiredCtrl || requiredCtrl.value) {
            return null;
         }
         for (let i = 0; i < dependentElements.length && !errorFlag; i++) {
            const element = dependentElements[i];
            const dependentCtrl = control.get(element.dependentName);
            errorFlag = !!dependentCtrl &&
               // dependentCtrl.value != null &&
               // dependentCtrl.value != undefined &&
               // (!!element.dependentValue ? dependentCtrl.value == element.dependentValue : !!dependentCtrl.value) ?
               (element.dependentValue != null && element.dependentValue != undefined ? dependentCtrl.value == element.dependentValue : dependentCtrl.value != null && dependentCtrl.value != undefined && dependentCtrl.value != '') ?
               true : false;
         }
         return errorFlag
            ? {
               [errName]: message,
            }
            : null;
      };
   }

   /**
    * !METHOD IN PROGRESS
    * @param message
    * @param errName
    * @param valueRequiredName
    * @param valueRequired
    * @param dependentElements
    */

   private static valueRequiredDependingOnControlValues(
      message: string,
      errName,
      valueRequiredName: string,
      valueRequired: any,
      dependentElements: { dependentName: string, dependentValue?: any }[]
   ): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
         const requiredCtrl = control.get(valueRequiredName);
         let isOnCondition = true;
         for (let i = 0; i < dependentElements.length && isOnCondition; i++) {
            const element = dependentElements[i];
            const dependentCtrl = control.get(element.dependentName);
            isOnCondition = !!dependentCtrl &&
               // dependentCtrl.value != null &&
               // dependentCtrl.value != undefined &&
               (element.dependentValue != null && element.dependentValue != undefined ? dependentCtrl.value == element.dependentValue : dependentCtrl.value != null && dependentCtrl.value != undefined && dependentCtrl.value != '') ?
               true : false;
         }

         return isOnCondition && !requiredCtrl && requiredCtrl.value != valueRequired
            ? {
               [errName]: message,
            }
            : null;
      };
   }

   static requiredOr(
      message: string,
      errName,
      requiredNames: string[],
   ): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
         let ctrlHasValue = false;
         for (let i = 0; i < requiredNames.length && !ctrlHasValue; i++) {
            const requiredName = requiredNames[i];
            const requiredCtrl = control.get(requiredName);
            ctrlHasValue = !!requiredCtrl.value;
         }
         return !ctrlHasValue ? {
            [errName]: message,
         }
            : null;
      };
   }

   static minValueValidator(minValue: number): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } => {
         const valid = control.value >= minValue;
         return valid ? null : {
            'minValue': {
               'minimum': minValue,
               'entered': control.value
            }
         };
      };
   }

   static regexValidator( reg:RegExp, message:string ): ValidatorFn {
      return (control: AbstractControl): ValidationErrors | null => {
        if (control.value && !control.value.toString().match(reg)) {
          return {
            regexValidator: message,
         };
        }
        return null;
      };
   }

   // fromdate <= todate
   static fromToDate(fromDateField: string, toDateField: string, errorName: string = 'fromToDate'): ValidatorFn {
      return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
         const fromDate = formGroup.get(fromDateField).value;
         const toDate = formGroup.get(toDateField).value;
         // Ausing the fromDate and toDate are numbers. In not convert them first after null check
         if ((fromDate !== null && toDate !== null) && fromDate > toDate) {
            return { [errorName]: true };
         }
         return null;
      };
   }

   // “fecha desde” seleccionada debe ser menor que la “fecha hasta” existente
   static dateLowerThan( date: Date ): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null =>
          moment(control.value).format('YYYY-MM-DD') < moment(date).format('YYYY-MM-DD') ? null : { matDatepickerMax: `La fecha debe ser menor a ${moment(date).format('DD-MM-YYYY')}` };
   }

   // “fecha desde” seleccionada debe ser mayor que la “fecha desde” previa o actual
   static dateGreaterThan( date: Date ): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null =>
          moment(control.value).format('YYYY-MM-DD') > moment(date).format('YYYY-MM-DD') ? null : { matDatepickerMin: `La fecha debe ser mayor a ${moment(date).format('DD-MM-YYYY')}` };
   }

   // “fecha desde” seleccionada debe ser menor IGUAL que la “fecha hasta” existente
   static dateLowerEqualThan( date: Date ): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null =>
          moment(control.value).isSameOrBefore(moment(date)) ? null : { matDatepickerMax: `La fecha debe ser menor igual a ${moment(date).format('DD-MM-YYYY')}` };
   }

   // “fecha desde” seleccionada debe ser mayor IGUAL que la “fecha desde” previa o actual
   static dateGreaterEqualThan( date: Date ): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null =>
          moment(control.value).isSameOrAfter(moment(date)) ? null : { matDatepickerMin: `La fecha debe ser mayor igual a ${moment(date).format('DD-MM-YYYY')}` };
   }

   static hourFormat(message:string):ValidatorFn {
      return (control:AbstractControl) =>
      control.value?.replace(/\D/g, '').length != 4   // Minimum length must be 4 digits
         || parseInt(control.value) > 2359           // Max time is 23:59
         || parseInt(control.value) < 0              // Min time is 00:00
         || parseInt(control.value.replace(/\D/g, '').substring(2,)) > 59 // Minutes can't be over 59
         ? {error: message} : null
   }
}
