import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MarkerGoogleMaps } from '../models/maps/markerGoogleMaps';
import { MapsSate } from 'src/app/core/states/maps.state';
import { forkJoin, map, Observable } from 'rxjs';
import { IconMapMarker } from 'src/app/core/enums/iconMapMarker';
import { GeneralService } from 'src/app/core/services/general.service';
import { Patient } from '../models/patient';
import { TypeMapMarker } from 'src/app/core/enums/typeMapMarker';
import { Provider } from '../models/provider';
import { LocationPOI } from '../models/maps/locationPOI';
import { AlertService } from 'src/app/core/services/alert.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class MapsService {

    constructor(
        private http: HttpClient,
        private mapsState: MapsSate,
        private generalService: GeneralService,
        private alertService: AlertService,
    ) {
        //this.preLoadMapIcons();
    }

    private svgMapIcons: { [key: string]: any } = {};
    private apiLoaded = false;
    private maxRetries = 3; // Número máximo de intentos
    private retryDelay = 2000; // Tiempo de espera entre intentos (en ms)


    public async loadApi(retryCount = 0): Promise<void> {
        if (this.apiLoaded) {
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}&libraries=places`;
            script.async = true;
            script.defer = true;

            script.onload = () => {
                this.apiLoaded = true;
                resolve();
            };
            script.onerror = async () => {
                console.error(`Intento ${retryCount + 1} fallido al cargar la API de Google Maps`);
                if (retryCount < this.maxRetries) {
                    setTimeout(() => {
                        this.loadApi(retryCount + 1).then(resolve).catch(reject);
                    }, this.retryDelay);
                } else {
                    reject(new Error('No se pudo cargar la API de Google Maps después de varios intentos.'));
                }
            };
            document.head.appendChild(script);
        });
    }

    private geoCodeCache: Map<string, { lat: number, lng: number }> = new Map();

    public async geoCodeAddress(address: string): Promise<{ lat: number, lng: number }> {
        if (this.geoCodeCache.has(address)) {
            return this.geoCodeCache.get(address)!;
        }

        try {
            const coords = await new Promise<{ lat: number, lng: number }>((resolve, reject) => {
                const geocoder = new google.maps.Geocoder();
                geocoder.geocode({ 'address': address }, (results, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        const location = results[0].geometry.location;
                        resolve({ lat: location.lat(), lng: location.lng() });
                    } else {
                        reject(new Error(`Geocode was not successful for the following reason: ${status}`));
                    }
                });
            });

            this.geoCodeCache.set(address, coords);
            return coords;
        } catch (error) {
            throw error;
        }
    }


    public async deleteMarkersByType(type: TypeMapMarker) {
        const getMarkers = this.mapsState.getMarkers();
        const filteredMarkers = getMarkers?.filter(marker => marker.type != type)
        this.mapsState.setMarkers(filteredMarkers);
    }

    public async deleteMarkersByTypes(types: TypeMapMarker[]): Promise<void> {
        const getMarkers = this.mapsState.getMarkers();
        const filteredMarkers = getMarkers?.filter(marker => !types.includes(marker.type));
        if (filteredMarkers.length == 0) {
            this.mapsState.setMarkers(null);
        } else {
            this.mapsState.setMarkers(filteredMarkers);
        }
    }

    private getMarkerIcon(marker: MarkerGoogleMaps): SVGElement | null {
        const svgIcon = this.getSvgMapIcon(marker.icon);
        if (!svgIcon) {
            console.error(`No se pudo obtener el ícono SVG para ${marker.icon}`);
            return null;
        }

        // Clonar el SVG antes de usarlo, para evitar reutilizar el mismo nodo
        const clonedSvgIcon = svgIcon.cloneNode(true) as SVGElement;
        clonedSvgIcon.style.width = marker.svgIconSize || '50px';
        clonedSvgIcon.style.height = marker.svgIconSize || '50px';
        return clonedSvgIcon;
    }

    public getMarkers$(): Observable<MarkerGoogleMaps[]> {
        return this.mapsState.getMarkers$();
    }

    public getMarkers(): MarkerGoogleMaps[] {
        return this.mapsState.getMarkers();
    }

    public castPatientFromCaseToMarkerGoogleMaps(patient: Patient, type?: TypeMapMarker.PATIENT_ACTIVE | TypeMapMarker.PATIENT_INACTIVE | TypeMapMarker.GENERAL | TypeMapMarker.CASE): MarkerGoogleMaps {
        return {
            type,
            name: `${patient.surname}, ${patient.name}`,
            phone: patient.phoneNumber,
            address: patient?.address?.withoutNumber ? `${patient.address?.street} S/N, ${patient.address?.location?.name}, ${patient.address?.location?.province?.name}` : `${patient.address?.street} ${patient.address?.streetNumber}, ${patient.address?.location?.name}, ${patient.address?.location?.province?.name}`,
            email: patient.email,
            lat: !!patient.address.latitude ? patient.address.latitude : null,
            lng: !!patient.address.longitude ? patient.address.longitude : null,
            // icon: IconMapMarker.PATIENT,
            // svgIcon: this.getMarkerIcon({ type, svgIconSize: '50px', icon: IconMapMarker.PATIENT }),
            id:patient?.id,
            visible:true,
            // title?: string,
            // info?: string,
            // lat?: number,
            // lng?: number,
            // svgIconSize?: string,
        }
    }

    public castPatientToMarkerGoogleMaps(patient: Patient, type?: TypeMapMarker.PATIENT_ACTIVE | TypeMapMarker.PATIENT_INACTIVE | TypeMapMarker.GENERAL): MarkerGoogleMaps {
        return {
            type,
            name: `${patient.surname}, ${patient.name}`,
            phone: patient.phoneNumber,
            address: patient?.address?.withoutNumber ? `${patient.address?.street} S/N, ${patient.address?.location?.name}, ${patient.address?.location?.province?.name}` : `${patient.address?.street} ${patient.address?.streetNumber}, ${patient.address?.location?.name}, ${patient.address?.location?.province?.name}`,
            email: patient.email,
            lat: !!patient.address.latitude ? patient.address.latitude : null,
            lng: !!patient.address.longitude ? patient.address.longitude : null,
            caseId: !!patient.caseId ? patient.caseId : null,
            // title?: string,
            // info?: string,
            // lat?: number,
            // lng?: number,
            icon: type == TypeMapMarker.PATIENT_ACTIVE ? IconMapMarker.PATIENT_ACTIVE : IconMapMarker.PATIENT_INACTIVE,
            svgIcon: this.getMarkerIcon({ type, svgIconSize: '50px', icon: type == TypeMapMarker.PATIENT_ACTIVE ? IconMapMarker.PATIENT_ACTIVE : IconMapMarker.PATIENT_INACTIVE }),
            id:patient?.id,
            visible:true,
            // svgIconSize?: string,
        }
    }

    //Convert cuit to dni
    private getDni(value: number): number {
        const string = value.toString();
        const length = string.length;
        const res = string.slice(2, length - 1);
        return parseInt(res);
    }

    public castProviderToMarkerGoogleMaps(provider: Provider): MarkerGoogleMaps {
        const type = TypeMapMarker.PROVIDER;
        return {
            type: TypeMapMarker.PROVIDER,
            name: `${provider.surname}, ${provider.name}`,
            phone: provider?.mobilePhoneNumber ? provider.mobilePhoneNumber : provider?.phoneNumber ? provider?.phoneNumber : null,
            address: provider?.address?.withoutNumber ? `${provider.address?.street} S/N, ${provider.address?.location?.name}, ${provider.address?.location?.province?.name}` : `${provider.address?.street} ${provider.address?.streetNumber}, ${provider.address?.location?.name}, ${provider.address?.location?.province?.name}`,
            email: provider.email,
            dni: this.getDni(provider.cuit),
            specialties: provider.specialties,
            lat: !!provider.address.latitude ? provider.address.latitude : null,
            lng: !!provider.address.longitude ? provider.address.longitude : null,
            // title?: string,
            // info?: string,
            // lat?: number,
            // lng?: number,
            icon: IconMapMarker.PROVIDER,
            svgIcon: this.getMarkerIcon({ type, svgIconSize: '50px', icon: IconMapMarker.PROVIDER }),
            id:provider?.id,
            visible:true,
            // svgIconSize?: string,
        }
    }

    public castAddressPatientToAddressMap(patient: Patient): LocationPOI | null {

        let street = patient.address.street ? `${patient.address.street} ` : '';
        let streetNumber = patient?.address?.withoutNumber ? 'S/N' :  `${patient.address.streetNumber} `;
        let location = patient.address.location ? `,${patient.address.location} ` : '';
        let province = patient.address.province ? `,${patient.address.province} ` : '';

        const addressString = `${street}${streetNumber}${location}${province}`;

        return { address: addressString };
    }


    public castAddressProviderToAddressMap(provider: Provider): LocationPOI | null {
        return { address: this.castProviderToMarkerGoogleMaps(provider).address }
    }

    isApiLoaded(): boolean {
        return this.apiLoaded
    }

    public preloadMapIcons(): Promise<void> {
        const timestamp = new Date().getTime(); // New name always
        const iconUrls = [
            { key: 'providerMap', url: `assets/icons/providerMap.svg?t=${timestamp}` },
            { key: 'patientMap', url: `assets/icons/patientMap.svg?t=${timestamp}` },
            { key: 'patientMapInactive', url: `assets/icons/patientMapInactive.svg?t=${timestamp}`},
            // { key: 'patientMapActive', url: 'assets/icons/patientMapActive.svg' },
            // { key: 'patientMapInactive', url: 'assets/icons/patientMapInactive.svg' },
            // Add more icons
        ];
        const iconPromises = iconUrls.map(icon =>
            new Promise<void>((resolve, reject) => {
                fetch(icon.url)
                    .then(response => response.text())
                    .then(svgString => {
                        const parser = new DOMParser();
                        const svgElement = parser.parseFromString(svgString, 'image/svg+xml').documentElement;
                        this.svgMapIcons[icon.key] = svgElement;
                        resolve();
                    })
                    .catch(error => reject(error));
            })
        );
        return Promise.all(iconPromises).then(() => { });
    }

    // Obtener un ícono precargado por su clave
    public getSvgMapIcon(iconKey: string): SVGElement | null {
        return this.svgMapIcons[iconKey] || null;
    }
}
